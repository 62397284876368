import React, { useState, useEffect, useRef } from 'react';
import { Nav, Tab, Form, InputGroup, Media, Col } from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// npm install @iconify/react @iconify-icons/carbon
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import pageDoc from '@iconify-icons/foundation/page-doc';
import searchOutlined from '@iconify-icons/ant-design/search-outlined';
import overflowMenuHorizontal from '@iconify-icons/carbon/overflow-menu-horizontal';
import './coluna_esquerda.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { parseISO, format } from 'date-fns';
import Swal from 'sweetalert2';
import DommusLoading from "./../../../../../components/DommusLoading/DommusLoading";
import guService from "../../../../../services/GuService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function Coluna_esquerda(props) {

  const initialTicketState = {
    id: null,
    solicitante: '',
    atendente: '',
    processo: '',
    opv: '',
    assunto: '',
    descricao: '',
    motivo: '',
    prioridade: '',
    organizacao: '',
    responsavel_execucao: '',
    data_cadastro: '',
    data_limite: '',
    comentario: '',
    arquivado: '',
    idpai: '',
    atendimento: '',
    categoria: '',
    origem: '',
    perfil_usuario: '',
    situacao: '',
    tags: [],
    tipo: '',
    acompanhamento: [],
    aprovacao: [],
    autorizacao: [],
    usuario: '',
    arquivos: '',
  }

  const [key, setKey] = useState('dados_chamado');
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const anchorRef = useRef(null);
  const [ticket, setTicket] = useState(initialTicketState);
  const [arquivos, setArquivos] = useState("");
  const [situacao, setSituacao] = useState([]);
  const [tipo, setTipo] = useState([]);
  const [categoria, setCategoria] = useState([]);
  const [motivo, setMotivo] = useState([]);
  const [origem, setOrigem] = useState([]);
  const [origemvalue, setOrigemvalue] = useState([]);
  const [listArquivos, setListArquivos] = useState([]);
  const [autorizacao, setAutorizacao] = useState([{ value: 123, label: 'Usuário 123' }]);
  const [aprovacao, setAprovacao] = useState([{ value: 1, label: 'Usuário 1' }]);
  const [acompanhamento, setAcompanhamento] = useState([{ value: 999, label: 'Usuário 999' }, { value: 900, label: 'Usuário 900' }]);
  const [tag, setTag] = useState([]);
  const [motivoDisabled, setMotivoDisabled] = useState(true);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setTicket({ ...ticket, [name]: value });

    if (name === 'categoria') {
    }
  };

  const handleSelectChange = (newValue, actionMeta) => {
    setTicket({ ...ticket, [actionMeta.name]: newValue.map((key, i) => (key.value)) });
  };

  const handleSelectChange2 = (newValue, actionMeta) => {
    setTicket({ ...ticket, [actionMeta.name]: newValue.value });
  };

  const handleSelectChange3 = (newValue, actionMeta) => {
    {
      newValue ?
        setTicket({ ...ticket, [actionMeta.name]: newValue.map((key, i) => (key.value)) })
        :
        setTicket({ ...ticket, [actionMeta.name]: [] })
    }
  };


  const handleFile = (files) => {
    setArquivos([...arquivos, ...files]);
  }

  async function EditTicket(id) {
    if (id) {
      const response = [];
      const body = await response.data;
      setTicket(
        {
          id: body.ticket.id,
          solicitante: body.ticket.solicitante,
          atendente: body.ticket.atendente,
          processo: body.ticket.processo,
          opv: body.ticket.opv,
          assunto: body.ticket.assunto,
          descricao: body.ticket.descricao,
          motivo: body.ticket.motivo,
          prioridade: body.ticket.prioridade,
          organizacao: body.ticket.organizacao,
          responsavel_execucao: body.ticket.responsavel_execucao,
          data_cadastro: format(parseISO(body.ticket.data_cadastro), "yyyy-MM-dd"),
          data_limite: format(parseISO(body.ticket.data_limite), "yyyy-MM-dd"),
          comentario: body.ticket.comentario,
          atendimento: body.ticket.atendimento,
          categoria: body.ticket.id_categoria_ticket,
          origem: body.ticket.id_origem_ticket,
          situacao: body.ticket.id_situacao,
          tipo: body.ticket.id_tipo_ticket,
          acompanhamento: response.data.ids_acompanhamento,
          aprovacao: response.data.ids_aprovacao,
          autorizacao: response.data.ids_autorizacao,
          tags: response.data.tagsTicket
        });

      setListArquivos(response.data.anexo);
    }
    setIsLoggedIn(false);
  }

  useEffect(() => {
    EditTicket(props.id);
  }, [props.id]);

  const saveTicket = () => {
    let formData = new FormData();
    formData.append("id", ticket.id);
    formData.append("solicitante", ticket.solicitante);
    formData.append("processo", ticket.processo);
    formData.append("opv", ticket.opv);
    formData.append("assunto", ticket.assunto);
    formData.append("descricao", ticket.descricao);
    formData.append("motivo", ticket.motivo);
    formData.append("prioridade", ticket.prioridade);
    formData.append("organizacao", ticket.organizacao);
    formData.append("responsavel_execucao", ticket.responsavel_execucao);
    formData.append("data_cadastro", ticket.data_cadastro);
    formData.append("data_limite", ticket.data_limite);
    formData.append("comentario", ticket.comentario);
    formData.append("idpai", ticket.idpai);
    formData.append("atendimento", ticket.atendente);
    formData.append("id_categoria_ticket", ticket.categoria);
    formData.append("id_origem_ticket", ticket.origem);
    formData.append("perfil_usuario", ticket.perfil_usuario);
    formData.append("id_situacao", ticket.situacao);
    formData.append("tags", ticket.tags);
    formData.append("id_tipo_ticket", ticket.tipo);
    formData.append("ids_acompanhamento", ticket.acompanhamento);
    formData.append("ids_aprovacao", ticket.aprovacao);
    formData.append("ids_autorizacao", ticket.autorizacao);
    formData.append("usuario", guService.getLocalState('userDommusCrm'));

    for (let i = 0; i < arquivos.length; i++) {
      formData.append(`arquivos[${i}]`, arquivos[i]);
    }

  }

  const mensagem = (msg, acao) => {
    Swal.fire({
      title: msg.success ? `Chamado ${acao} com sucesso` : 'Ocorreu um erro ao salvar, tente novamente.',
      icon: msg.success ? 'success' : 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
    })
  }

  // let formDatafile = new FormData();
  // for (let i = 0; i < arquivos.length; i++) {
  //   formDatafile.append('nome_arquivo', arquivos[i].name);
  //   formDatafile.append('extensao', arquivos[i].name.split(".")[1]);
  //   formDatafile.append('conteudo', arquivos[i]);
  //   ccd.post('dommus-drive/upload', formDatafile)
  //     .then(response => {
  //       console.log(response.data);
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // };
  // }


  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div id='esquerda'>
      {isLoggedIn ? <DommusLoading /> :
        <Tab.Container
          id="menu_esquerda"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <div className="header">

            <Nav variant="pills" className="menu_esquerda">
              <Col>
                <Nav.Item>
                  <Nav.Link eventKey="dados_chamado">Chamado</Nav.Link>
                </Nav.Item>
              </Col>
              <Col>
                <Nav.Item>
                  <Nav.Link eventKey="qualificacao">Qualificação</Nav.Link>
                </Nav.Item>
              </Col>
              <Col>
                <Nav.Item>
                  <Nav.Link
                    className='menu'
                    ref={anchorRef}
                    aria-controls={open === null ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    style={{ outline: 'unset' }}
                  >
                    <Icon icon={overflowMenuHorizontal} color="#868aa8" width="2rem" height="2rem" />
                  </Nav.Link>
                </Nav.Item>
                <Popper style={{ zIndex: 1000 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' === null ? 'center top' : 'center bottom' }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                            <MenuItem onClick={handleClose}>Encaminhar Chamado</MenuItem>
                            <MenuItem onClick={handleClose}>Opção Um</MenuItem>
                            <MenuItem onClick={handleClose}>Opção Dois</MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>

              </Col>
            </Nav>

          </div>
          <Tab.Content>
            <Tab.Pane eventKey="dados_chamado">
              <Form>
                <Form.Group>
                  <div className="inputLabel">Solicitante:</div>
                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-label="Solicitante"
                      name="solicitante"
                      value={ticket.solicitante === null ? '' : ticket.solicitante}
                      onChange={handleInputChange.bind(this)}
                      disabled
                    />
                    <InputGroup.Append>
                      <Link to="./"><Icon icon={searchOutlined} color="var(--dommus-color)" width="1.5rem" height="1.5rem" /></Link>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <div className="inputLabel">OPV / Processo:</div>
                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-label="OPV / Processos"
                      name="opv"
                      value={ticket.opv === null ? '' : ticket.opv}
                      onChange={handleInputChange.bind(this)}
                      disabled
                    />
                    <InputGroup.Append>
                      <Link to="./"><Icon icon={searchOutlined} color="var(--dommus-color)" width="1.5rem" height="1.5rem" /></Link>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <div className="inputLabel">Organização:</div>
                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-label="Organização"
                      name="organizacao"
                      value={ticket.organizacao === null ? '' : ticket.organizacao}
                      onChange={handleInputChange.bind(this)}
                    />
                    <InputGroup.Append>
                      <Link to="./"><Icon icon={searchOutlined} color="var(--dommus-color)" width="1.5rem" height="1.5rem" /></Link>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <div className="inputLabel">Data do chamado:</div>
                  <Form.Control className="dataChamado" type="date" id="date-input"
                    name="data_cadastro"
                    value={ticket.data_cadastro === null ? '' : ticket.data_cadastro}
                    onChange={handleInputChange.bind(this)}
                  />
                </Form.Group>
                <Form.Group>
                  <div className="inputLabel">Status</div>
                  <Form.Control as="select" className="selectSituacao" value={ticket.situacao === null ? "6" : ticket.situacao} disabled
                    name="situacao"
                    onChange={handleInputChange.bind(this)}>

                    {props.id === null ? <option value="pendente" className="selectPendente">PENDENTE</option> :
                      situacao.map(({ label, value }) => (<option key={value} value={value}>{label}</option>))
                    }
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <div className="inputLabel">Assunto:</div>
                  <Form.Control as="textarea" rows={3}
                    name="assunto"
                    value={ticket.assunto === null ? '' : ticket.assunto}
                    onChange={handleInputChange.bind(this)}
                  />
                </Form.Group>
                <Form.Group>
                  <div className="inputLabel">Descrição</div>
                  <Form.Control as="textarea" rows={3}
                    name="descricao"
                    value={ticket.descricao === null ? '' : ticket.descricao}
                    onChange={handleInputChange.bind(this)}
                  />
                </Form.Group>
                <Form.Group>
                  <div className="inputLabel">Tipo</div>
                  <Form.Control as="select" className="selectTipo" value={ticket.tipo === null ? "selecionar" : ticket.tipo} name="tipo" onChange={handleInputChange.bind(this)}>
                    <option value="selecionar" > Selecionar </option>
                    {tipo.map(({ label, value }) => (<option key={value} value={value}>{label}</option>))}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <div className="inputLabel">Categoria</div>
                  <Form.Control as="select" className="selectCategoria" value={ticket.categoria === null ? "selecionar" : ticket.categoria} name="categoria" onChange={handleInputChange.bind(this)}>
                    <option value="selecionar" > Selecionar </option>
                    {categoria.map(({ label, value }) => (<option key={value} value={value}>{label}</option>))}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <div className="inputLabel">Motivo {motivoDisabled}</div>
                  <Form.Control as="select" className="selectMotivo"
                    disabled={motivoDisabled}
                    value={ticket.motivo === null ? "selecionar" : ticket.motivo}
                    name="motivo"
                    onChange={handleInputChange.bind(this)}>
                    <option value="selecionar"> Selecionar </option>
                    {motivo.map(({ label, value }) => (<option key={value} value={value}>{label}</option>))}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <div className="inputLabel">Origem / Canal</div>
                  <Select
                    defaultValue={ticket.origem}
                    name="origem"
                    options={origem}
                    className="selectOrigem"
                    classNamePrefix="Selecionar"
                    onChange={(...args) => {
                      handleSelectChange2(args[0], args[1]);
                    }}
                  />
                  {/* <Form.Control as="select" className="selectOrigem" value={ticket.origem === null ? 'selecionar' : ticket.origem} name="origem" onChange={handleInputChange.bind(this)}>
                  <option disabled value="selecionar"> Selecionar </option>
                  {origem.map(({ label, value }) => (<option key={value} value={value}>{label}</option>))}
                </Form.Control> */}
                </Form.Group>
                <Form.Group>
                  <div className="inputLabel">Arquivos</div>
                  <Form.Control id="fileArquivo" name="arquivo[]" type="file" multiple onChange={(e) => handleFile(e.target.files)} />
                </Form.Group>
                <ul className="list-unstyled">
                  {listArquivos.map((url, i) => (
                    <Media as="li">
                      <Icon icon={pageDoc} color="var(--dommus-color)" width="1.5rem" height="1.5rem" />
                      <Media.Body>
                        <a href={url} target="_blank">
                          <Form.Label>Documento {i}</Form.Label>
                        </a>
                      </Media.Body>
                    </Media>
                  ))}
                </ul>
              </Form>
            </Tab.Pane>
            <Tab.Pane eventKey="qualificacao">
              <Form>
                <Form.Group>
                  <div className="inputLabel">Prioridade {ticket.prioridade}</div>
                  <Select
                    defaultValue={[ticket.autorizacao]}
                    isMulti
                    name="autorizacao"
                    options={autorizacao}
                    className="basic-multi-select"
                    classNamePrefix="Selecionar"

                  />
                  <Form.Control as="select" className="selectPrioridade" value={ticket.prioridade === null ? 'selecionar' : ticket.prioridade} name="prioridade" onChange={handleInputChange.bind(this)}>
                    <option value="selecionar"> Selecionar </option>
                    <option value="U" className="selectUrgencia">Urgência</option>
                    <option value="A" className="selectAlta">Alta</option>
                    <option value="M" className="selectMedia">Média</option>
                    <option value="B" className="selectBaixa">Baixa</option>
                    <option value="P" className="selectMuitoBaixa">Muito Baixa</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <div className="inputLabel">Data Limite</div>
                  <Form.Control className="dataLimite" type="date" id="date-input"
                    name="data_limite"
                    value={ticket.data_limite === null ? '' : ticket.data_limite}
                    onChange={handleInputChange.bind(this)}
                  />
                </Form.Group>
                <Form.Group>
                  <div className="inputLabel">Responsável Execução:</div>
                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-label="Responsável Execução"
                      name="responsavel_execucao"
                      value={ticket.responsavel_execucao === null ? '' : ticket.responsavel_execucao}
                      onChange={handleInputChange.bind(this)}
                    />
                    <InputGroup.Append>
                      <Link to=""><Icon icon={searchOutlined} color="var(--dommus-color)" width="1.5rem" height="1.5rem" /></Link>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <div className="inputLabel">Responsável Autorização:</div>
                  {/* {console.log(autorizacao)} */}

                  <Select
                    defaultValue={ticket.autorizacao && ticket.autorizacao.map(id => autorizacao.find(u => u.value === id))}
                    isMulti
                    name="autorizacao"
                    options={autorizacao}
                    className="basic-multi-select"
                    classNamePrefix="Selecionar"
                    onChange={(...args) => {
                      handleSelectChange3(args[0], args[1]);
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <div className="inputLabel">Responsável Aprovação:</div>
                  <Select
                    defaultValue={(ticket.aprovacao && ticket.aprovacao.map(id => aprovacao.find(u => u.value === id)))}
                    isMulti
                    name="aprovacao"
                    options={aprovacao}
                    className="basic-multi-select"
                    classNamePrefix="Selecionar"
                    onChange={(...args) => {
                      handleSelectChange3(args[0], args[1]);
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <div className="inputLabel">Acompanhamento/Seguidores: </div>
                  <Select
                    defaultValue={[ticket.acompanhamento && ticket.acompanhamento.map(id => acompanhamento.find(u => u.value === id))]}
                    isMulti
                    name="acompanhamento"
                    options={acompanhamento}
                    className="basic-multi-select"
                    classNamePrefix="Selecionar"
                    onChange={(...args) => {
                      handleSelectChange3(args[0], args[1]);
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <div className="inputLabel">Tag</div>
                  {props.id ?
                    <CreatableSelect
                      value={ticket.tags && ticket.tags.map(tagName => tag.find(u => u.value === tagName))}
                      isMulti
                      name="tags"
                      options={tag}
                      className="basic-multi-select"
                      classNamePrefix="Selecionar ou Novo"
                      onChange={(...args) => {
                        handleSelectChange3(args[0], args[1]);
                      }}
                    />
                    :
                    <CreatableSelect
                      defaultValue={ticket.tags}
                      isMulti
                      name="tags"
                      options={tag}
                      className="basic-multi-select"
                      classNamePrefix="Selecionar ou Novo"
                      onChange={(...args) => {
                        handleSelectChange3(args[0], args[1]);
                      }}
                    />}
                </Form.Group>
              </Form>
            </Tab.Pane>
            <button onClick={saveTicket} className="btn btn-primary" style={{ marginLeft: '75%' }}>Salvar</button>
          </Tab.Content>
        </Tab.Container>
      }
    </div>
  );
}