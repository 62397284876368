import React, { Component } from 'react';
import { Nav, Tab, Col } from 'react-bootstrap';
import './chamado.css';
import Messages from './Chat/messages';
import Input from './Chat/input';
import Coluna_direita from './ColunaDireita/coluna_direita';
import Coluna_esquerda from './ColunaEsquerda/coluna_esquerda';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorClosed, faSync } from "@fortawesome/free-solid-svg-icons";
import DommusLoading from "./../../../../components/DommusLoading/DommusLoading";
// npm install --save-dev  @iconify-icons/bx

// import { Icon } from '@iconify/react';
// import bxMenu from '@iconify-icons/bx/bx-menu';

class Chamado extends Component {
  onSendMessage = (message) => {
    this.setState({ isLoggedIn: true })
    const messages = this.state.messages
    const id = this.props.id

    const fd = new FormData();
    fd.append('mensagem', message);
    fd.append('id_ticket', id);
    fd.append('usuario', localStorage.getItem('userDommusCrm'));


  }

  constructor(props) {
    super(props);
    this.state = {
      leftOpen: true,
      rightOpen: true,
      isLoggedIn: true,
      messages: [{
        text: "Uma mensagem do usuário",
        member: {
          id: 1,
          color: "var(--dommus-color)",
          username: "Nome do usuário"
        }
      }],
      member: {}
    }
  }

  async messagens(id) {
    if (id) {
      const response = [];
      const data = await response.data;
      const messages = this.state.messages

      for (let i = 0; i < data.length; i++) {
        messages.push({
          text: data[i].mensagem,
          member: {
            username: "Nome do Atendente",
            color: "#4CAF50"
          }
        })
      }   
      this.setState({ messages: messages, isLoggedIn: false })
    }
    this.setState({isLoggedIn: false })  
  }

  componentDidMount(){
    this.messagens(this.props.id);
  }


  toggleSidebar = (event) => {
    let key = `${event.currentTarget.parentNode.id}Open`;
    this.setState({ [key]: !this.state[key] });
  }

  render() {
    let leftOpen = this.state.leftOpen ? 'open' : 'closed';
    let rightOpen = this.state.rightOpen ? 'open' : 'closed';
    let isLoggedIn = this.state.isLoggedIn;
    return (
      <div id='chamado'>
        <div id='left' className={leftOpen} >
          {/* <div className='icon'
            onClick={this.toggleSidebar} >
            <Icon icon={bxMenu} style={{ color: leftOpen === 'closed' ? 'white' : '#212530' }} />
          </div> */}
          <div className={`sidebar ${leftOpen}`} >
            <div className='content'>
              <Coluna_esquerda id={this.props.id}/>
            </div>
          </div>
        </div>

        <div id='main'>
          <Tab.Container id="left-tabs" defaultActiveKey="mensagens">
            <div className='content'>
              <div className="header">
                <Nav variant="pills" className="chat">
                  <Col>
                    <Nav.Item>
                      <Nav.Link eventKey="mensagens">Mensagens</Nav.Link>
                    </Nav.Item>
                  </Col>
                  <Col>
                    <Nav.Item>
                      <Nav.Link eventKey="notas_internas">Notas Internas</Nav.Link>
                    </Nav.Item>
                  </Col>
                </Nav>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="mensagens">
                  {isLoggedIn ? <DommusLoading/> :
                    <div className="listaMensagens">
                    <Messages
                      messages={this.state.messages}
                      currentMember={this.state.member}
                    />
                    <Input
                      onSendMessage={this.onSendMessage}
                    />
                    </div>
                }
                </Tab.Pane>
                <Tab.Pane eventKey="notas_internas">
                 
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>

        <div id='right' className={rightOpen} >
          {/* <div className='icon'
            onClick={this.toggleSidebar} >
            <Icon icon={bxMenu} style={{ color: rightOpen === 'closed' ? 'white' : '#212530' }} />
          </div> */}
          <div className={`sidebar ${rightOpen}`} >
            <Coluna_direita />
          </div>
        </div>

      </div>
    );
  }
}

export default Chamado;