import React, { useContext, useEffect, useMemo, useState } from "react";
import "./index.css";
import { DetalhamentoItem } from "./DetalhamentoItem";
import { FormProvider, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faArrowRight,
    faHammer,
} from "@fortawesome/free-solid-svg-icons";
import { AssistenciaTecnicaContext } from "../../contexts/AssistenciaTecnicaContext";
import { trackPromise } from "react-promise-tracker";
import ticketService from "../../services/TicketService";
import guService from "../../services/GuService";
import helper from "../../helpers/format";
import Swal from "sweetalert2";

export function NovaAssistenciaTecnica({idMotivo, setRefresh, close}) {
    const { listaDetalhamentos, carregarDetalhamentos, detalhamentos, mapDetalhamentosSelecionados } =
        useContext(AssistenciaTecnicaContext);
    const [indexSelecionado, setIndexSelecionado] = useState(0);
    const [todasRespondidas, setTodasRespondidas] = useState(false);
    const [botaoSolicitarAssistenciaHabilitado, setBotaoSolicitarAssistenciaHabilitado] = useState(false);
    const methods = useForm();
    const watchPerguntas = methods.watch();

    useEffect(() => {
        if(!idMotivo){
            Swal.fire({
                titleText: "Ooops...",
                text:"Motivo não informado para abertura assistência técnica",
                icon: "error",
            });
            close();
        }
        carregarDetalhamentos(idMotivo);
        methods.reset();

        return() => {
            methods.reset();
        }
    }, []);

    const checkTodasPerguntasRespondidas = () => {
        let detalhamentosValues = methods.getValues();
        var checkRespondidas = true;        

        for (var [detalhamentoKey, detalhamentoValue] of Object.entries(
            detalhamentosValues
        )) {
            let detalhamentoId = parseInt(detalhamentoKey.slice(2));
            for (var [perguntaKey, perguntaValor] of Object.entries(
                detalhamentoValue
            )) {
                let idPergunta = Number(perguntaKey.split('-')[1]) ?? 0;
                let pergunta = mapDetalhamentosSelecionados[detalhamentoId]?.perguntas.find((pergunta) => pergunta.id_assistencia_pergunta === idPergunta);
                
                if(detalhamentos.includes(detalhamentoId) && !pergunta){
                    checkRespondidas = false;
                }else{
                    if (detalhamentos.includes(detalhamentoId) && pergunta?.obrigatoriedade === 'S' &&
                        (perguntaValor === "" || !perguntaValor.length)
                    ) {
                        checkRespondidas = false;
                    }
                }
            }
        }
        setTodasRespondidas(checkRespondidas);
    };

    useEffect(() => {
        checkTodasPerguntasRespondidas();
    }, [watchPerguntas]);

    useEffect(() => {
        let valores = methods.getValues();
        for (var [detalhamentoKey, detalhamentoValue] of Object.entries(
            valores
        )) {
            for (var [perguntaKey, perguntaValue] of Object.entries(
                detalhamentoValue
            )) {
                if (
                    !detalhamentos.includes(parseInt(detalhamentoKey.slice(2)))
                ) {
                    methods.setValue(`${detalhamentoKey}.${perguntaKey}`, "");
                }
            }
        }
    }, [detalhamentos]);

    const handleProximo = () => {
        setIndexSelecionado((current) => current + 1);
    };

    const handleRetroceder = () => {
        if (indexSelecionado !== 0) {
            setIndexSelecionado((current) => current - 1);
        }
    };

    const removePrefixes = (obj, prefix) => {
        const newObj = {};
        for (const key in obj) {
            if (key.startsWith(prefix)) {
                const newKey = key.slice(prefix.length);
                newObj[newKey] = Array.isArray(obj[key]) ? obj[key].map((item)=>parseInt(item) || item) : parseInt(obj[key]) || obj[key];
            } else {
                newObj[key] = Array.isArray(obj[key]) ? obj[key].map((item)=>parseInt(item) || item) : parseInt(obj[key]) || obj[key];
            }
        }
        return newObj;
    };

    const handleOnSubmit = (data) => {
        let dataFiltered = {};

        for (var [detalhamentoKey, detalhamentoValue] of Object.entries(data)) {
            let detalhamentoId = parseInt(detalhamentoKey.slice(2));
            if (detalhamentos.includes(detalhamentoId)) {
                dataFiltered[detalhamentoId] = removePrefixes(
                    detalhamentoValue,
                    "p-"
                );
            }
        }

        let dados = {
            assunto: "Solicitação de Assistência Técnica",
            id_processo: guService.getLocalState("Processo"),
            id_usuario_solicitante: guService.getLocalState("idUsuario"),
            id_ticket_status: 1,
            id_ticket_motivo: idMotivo,
            detalhamentos: dataFiltered
        };

        trackPromise(
            ticketService.store(dados).then(
                (response) => {
                    const protocolo = response.data.protocolo.split("-");
                    Swal.fire({
                        titleText: "Solicitação de Assistência técnica",
                        text: `Sua solicitação foi aberta com sucesso! N° Protocolo ${
                            protocolo[0] + "-" + helper.zeroPad(protocolo[1], 5)
                        }`,
                        icon: "success",
                    });
                    setRefresh(true);
                    methods.reset();
                    close();
                },
                (error) => {
                    Swal.fire({
                        titleText: "Ooops...",
                        text:
                            "Ocorreu um erro ao realizar a sua solicitação: " +
                            error,
                        icon: "error",
                    });
                }
            )
        );
    };

    useEffect(()=>{
        if(botaoSolicitarAssistenciaHabilitado){
            setBotaoSolicitarAssistenciaHabilitado(todasRespondidas)
        }else{
            setBotaoSolicitarAssistenciaHabilitado(todasRespondidas && indexSelecionado + 1 === detalhamentos.length);
        }
    },[todasRespondidas, indexSelecionado, detalhamentos, botaoSolicitarAssistenciaHabilitado])

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit(handleOnSubmit)}
                className="nova-assistencia-section"
            >
                {listaDetalhamentos.map((detalhamento, index) => {
                    let idIndex = detalhamentos[indexSelecionado];
                    let isHidden =
                        detalhamento.id_assistencia_detalhamento !== idIndex;
                    return (
                        <DetalhamentoItem
                            key={index}
                            detalhamento={detalhamento}
                            isHidden={isHidden}
                        />
                    );
                })}
                {listaDetalhamentos?.length ?
                    <footer>
                        <button
                            type="button"
                            className="btn btn-anterior"
                            onClick={() => {
                                handleRetroceder();
                            }}
                            hidden={indexSelecionado === 0}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                            <span>Anterior</span>
                        </button>
                        {indexSelecionado + 1 !== detalhamentos.length && (
                            <button
                                type="button"
                                className="btn btn-proximo"
                                onClick={() => {
                                    handleProximo();
                                }}
                            >
                                <span>Próximo</span>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                        )}
                        {botaoSolicitarAssistenciaHabilitado && (
                            <button type="submit" className="btn btn-finalizar">
                                <span>Abrir Solicitação</span>
                                <FontAwesomeIcon icon={faHammer} />
                            </button>
                        )}
                    </footer>
                : <></>
                }
            </form>
        </FormProvider>
    );
}
