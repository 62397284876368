/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  useTheme,
  withStyles,
  createTheme,
} from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import { Icon } from "@iconify/react";
import loadbarDoc from "@iconify-icons/gg/loadbar-doc";
import checkCircleOutlined from "@iconify-icons/ant-design/check-circle-outlined";
import xCircle from "@iconify-icons/bi/x-circle";
import warningIcon from "@iconify/icons-carbon/warning";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import { ptBR } from "@material-ui/core/locale";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import processoService from "../../../services/ProcessoService";
import guAPI from "../../../services/GuService";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { successToast } from "../../../components/DommusToast";
import ReactLoading from "react-loading";
import "./index.css";
import {Alert} from 'react-bootstrap';
import DommusLoadingArea from "../../../components/DommusLoading/DommusLoadingArea";
import DommusLoadingAreaWithoutPromisse from "../../../components/DommusLoading/DommusLoadingAreaWithoutPromisse";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
  },
}));

function createData(
  objeto,
  vencimento,
  valor,
  status,
  geral,
  parcela,
  codigoBarra
) {
  return { objeto, vencimento, valor, status, geral, parcela, codigoBarra };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#887d7d",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#DDDDDD",
    },
  },
}))(TableRow);

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme(ptBR);
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <ReactTooltip />

      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

export default function CustomPaginationActionsTable({ cor, radioValue, setFluxoPagamento=null, setExibirBotaoPagarParcelasAtrasadas }) {
  const [iconPago, setIconPago] = useState(true);
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const [erro, setErro] = useState(null);
  const [loadingBoleto, setLoadingBoleto] = useState(false);
  const [exibeSemGrupo, setExibeSemGrupo] = useState(false);
  const [carregando, setCarregando] = useState(false)

  useEffect(() => {
    montaFluxo();
  }, [radioValue]);

  function filtraTipo(objeto, tipo) {
    let arr = [];
    if (tipo === "TDS") {
      arr = objeto;
    } else {
      objeto.forEach((item) => {
        let chaves = JSON.parse(item.chaves_externas);
        Object.keys(chaves).forEach(function (key) {
          if (chaves[key] == tipo) {
            arr.push(item);
          }
        });
      });
    }
    return arr;
  }

  function montaFluxo() {
    setCarregando(true)
    processoService
      .buscarFluxoPagamento(guAPI.getLocalState("Processo"))
      .then((response) => {
        const novoErro = response.data.erro ? `${response.data.erro}${response.data?.motivo && ` O motivo é: ${response.data.motivo}` || ''}` : null;
        setErro(novoErro);
        const linhas = Array.isArray(response.data.fluxo) ? response.data.fluxo : Object.values(response.data.fluxo);
        const novoExibirBotaoPagarAtrasadas = response.data.nomeSistemaFinanceiro?.toUpperCase() === 'UAU' && response.data?.geraBoletosAgrupados;
        const statusParcelasNaoGeramBoleto = novoExibirBotaoPagarAtrasadas ? ['quitada', 'atrasada'] : ['quitada']
        if(setFluxoPagamento) {
          setFluxoPagamento(linhas)
        }
        const novoExibeSemGrupo = response.data.exibir_sem_grupo;
        setExibeSemGrupo(novoExibeSemGrupo);
        let listaLinhas = [];
        filtraTipo(linhas, radioValue).forEach((item) => {
          listaLinhas.push(
            createData(
              <div>PARCELA {novoExibirBotaoPagarAtrasadas ? item.produto : item.tipo}</div>,
              moment(item.vencimento).format("DD/MM/YYYY"),
              parseFloat(
                item.valor_corrigido != "" &&
                  item.valor_corrigido != null &&
                  item.valor_corrigido != undefined
                  ? item.valor_corrigido
                  : item.valor
              ).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
              <div
                data-tooltip={
                  item.statusParcela === "atrasada"
                    ? "Atrasada"
                    : item.statusParcela === "avencer"
                    ? "À vencer"
                    : "Paga"
                }
              >
                {item.statusParcela !== "atrasada" &&
                item.statusParcela !== "avencer" ? (
                  <Icon
                    data-tip={"Paga"}
                    icon={checkCircleOutlined}
                    width="20px"
                    height="20px"
                    color="green"
                  />
                ) : item.statusParcela == "atrasada" ? (
                  <Icon
                    icon={xCircle}
                    width="17px"
                    height="17px"
                    color="red"
                    data-tip={"Atrasada"}
                  />
                ) : (
                  <Icon
                    icon={warningIcon}
                    data-tip={"A vencer"}
                    width="20px"
                    height="20px"
                    color="orange"
                  />
                )}{" "}
              </div>,
              item.contador_geral,
              item.contador_parcela,
              !statusParcelasNaoGeramBoleto.includes(item.statusParcela) && !([9,10,11].includes(Number(item.id_tipo_parcela))) ? (
                <div data-tooltip="Emitir 2° via do boleto">
                  <FontAwesomeIcon
                    icon={faBarcode}
                    style={{ fontSize: 20, cursor: "pointer" }}
                    onClick={() => {
                      buscaBoleto(guAPI.getLocalState("Processo"), item.id, item.chaves_externas, item.padraoCobranca || null);
                    }}
                  />
                </div>
              ) : (
                <></>
              )
            )
          );
        });
        setRows(listaLinhas);
        if(setExibirBotaoPagarParcelasAtrasadas) {
          setExibirBotaoPagarParcelasAtrasadas(novoExibirBotaoPagarAtrasadas)
        }
        setCarregando(false)
      }).catch((error)=>{
        setCarregando(false)
      })
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function funcionalidadeNaoIntegrada() {
    Swal.fire({
      titleText: "Funcionalidade não integrada",
      text: "",
      icon: "info",
    });
  }
  function buscaBoleto(processo, idParcela, chavesExternas, padraoCobranca = null) {
    setLoadingBoleto(true);
    processoService.buscarBoleto(processo, idParcela, chavesExternas, padraoCobranca).then((res) => {
      const retorno = res.data;
      setLoadingBoleto(false);
      if (retorno.length > 0) {
        Swal.fire({
          titleText: `2ª via de boleto`,
          text: "Seu boleto está disponível para download",
          icon: "info",
          showCloseButton: false,
          showCancelButton: true,
          showDenyButton: true,
          focusConfirm: false,
          confirmButtonText: "Baixar boleto",
          denyButtonText: "Copiar código",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success mr-3",
            cancelButton: "btn btn-secondary mr-3",
            denyButton: "btn btn-primary mr-3",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(retorno[0].urlReport, "_blank");
          } else if (result.isDenied) {
            navigator.clipboard.writeText(retorno[0].digitableNumber);
            successToast.fire({
              text: "Link copiado para a área de transferência.",
            });
          }
        });
      } else {
        Swal.fire({
          titleText: "Boleto não disponível, entre em contato conosco.",
          text: "",
          icon: "warning",
        });
        setLoadingBoleto(false);
      }
    });
  }


  return (
    (carregando && <div className="py-2"><DommusLoadingAreaWithoutPromisse/></div> || (erro && <Alert variant={'warning'}>{erro}</Alert>)) ||
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ backgroundColor: cor }} align="center">
              #
            </StyledTableCell>
            <StyledTableCell style={{ backgroundColor: cor }}>
              {" "}
              Parcela{" "}
            </StyledTableCell>
            <StyledTableCell style={{ backgroundColor: cor }} align="center">
              Vencimento
            </StyledTableCell>
            <StyledTableCell style={{ backgroundColor: cor }} align="center">
              Valor
            </StyledTableCell>
            {
            !(exibeSemGrupo) && <StyledTableCell style={{ backgroundColor: cor }} align="center">
              Grupo
            </StyledTableCell>
            }

            <StyledTableCell style={{ backgroundColor: cor }} align="center">
              2ª Via
            </StyledTableCell>
            <StyledTableCell style={{ backgroundColor: cor }} align="center">
              Status
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row, index) => (
            <StyledTableRow key={index}>
              <TableCell
                component="th"
                style={{ width: 20 }}
                align="center"
                scope="row"
              >
                {row.geral}
              </TableCell>
              <TableCell style={{ width: 180 }}>{row.objeto}</TableCell>
              <TableCell style={{ width: 100 }} align="center">
                {row.vencimento}
              </TableCell>
              <TableCell style={{ width: 100 }} align="center">
                {row.valor}
              </TableCell>
              {
              !(exibeSemGrupo) && <TableCell style={{ width: 100 }} align="center">
                {row.parcela}
              </TableCell>
              }

              <TableCell style={{ width: 100 }} align="center">
                {loadingBoleto ? (
                  <ReactLoading
                    type="bubbles"
                    color="#000"
                    height={40}
                    width={50}
                  />
                ) : (
                  row.codigoBarra
                )}
              </TableCell>
              <TableCell style={{ width: 100 }} align="center">
                {row.status}
              </TableCell>
            </StyledTableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              labelRowsPerPage="Linhas por página"
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "linhas por pagina" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
