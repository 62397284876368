import Swal from "sweetalert2/src/sweetalert2";
import "./index.css";

const errorToast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: false,
  icon: "error",
  background: "#fff",
  customClass: { content: "alert-toast", icon: "alert-toast" },

  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const successToast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: false,
  icon: "success",
  background: "#fff",
  customClass: { content: "alert-toast", icon: "alert-toast" },
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export { errorToast, successToast };
