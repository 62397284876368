import axios from "axios";
import guService from "./GuService";

const crm = axios.create({
  baseURL:
    process.env.REACT_APP_CRM_API + "/" + guService.getLocalState("slug") + "/",
  headers: {
    Authorization: guService.getLocalState("token"),
  },
});

export default crm;
