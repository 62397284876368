import React, { createContext, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import ticketService from "../services/TicketService";

export const AssistenciaTecnicaContext = createContext({});

export function AssistenciaTecnicaProvider({ children }) {
    const [listaDetalhamentos, setListaDetalhamentos] = useState([]);
    const [mapDetalhamentosSelecionados, setMapDetalhamentosSelecionados] = useState([]);
    const [listaDetalhamentosCondicionais, setListaDetalhamentosCondicionais] = useState([]);
    const [detalhamentos, setDetalhamentos] = useState([]);
    const [listaDetalhamentosFixos, setListaDetalhamentosFixos] = useState([]);

    const carregarDetalhamentos = (idMotivo) => {
        trackPromise(ticketService.getDetalhamentoPorMotivo(idMotivo)).then(
            (response) => {
                if (response?.data.length) {

                    let fixos = response.data.filter((detalhamento) => {
                        return (
                            detalhamento.depende_resposta === "N" ||
                            !detalhamento.depende_resposta
                        );
                    });
                    setListaDetalhamentos(response.data);
                    let idsFixos = fixos.map((detalhamento)=>detalhamento.id_assistencia_detalhamento);
                    setListaDetalhamentosFixos(fixos);
                    setDetalhamentos(idsFixos);
                }
            }
        );
    };

    useEffect(()=>{
        if(listaDetalhamentosCondicionais.length){
            let uniqueList = listaDetalhamentosCondicionais.filter((value, index, array)=>{
                return array.indexOf(value) === index;
            })

            let referecinciaDetalhamento = uniqueList.filter((index) => {
                return !listaDetalhamentosFixos.find((item) => item.id_assistencia_detalhamento === index)
            });

            if (referecinciaDetalhamento.length) {
                let detalhamentosEncontrados = listaDetalhamentos.filter((item) => {
                    return referecinciaDetalhamento.includes(item?.id_assistencia_detalhamento);
                })

                let temp = [...listaDetalhamentosFixos, ...detalhamentosEncontrados];
                let arrayOrdenado = temp.sort((a, b) => a.ordem - b.ordem).map((item) => item.id_assistencia_detalhamento);

                const novoMapa = {};
                temp.forEach((campo) => {
                    novoMapa[campo.id_assistencia_detalhamento] = campo;
                });

                setDetalhamentos(arrayOrdenado);
                setMapDetalhamentosSelecionados(novoMapa);
            }
        }
    },[listaDetalhamentosCondicionais])

    const alterarListaDetalhamento = (listaAdicionar, listaRemover) => {
        let temp = listaDetalhamentosCondicionais;
        if(listaRemover.length){   
            listaRemover.forEach((item)=>{
                temp.splice(temp.indexOf(item), 1);
            })    
        }
        if(listaAdicionar.length){
            setListaDetalhamentosCondicionais([...temp, ...listaAdicionar]);
        }else if(listaRemover.length){
            setListaDetalhamentosCondicionais([...temp]);
        }
    };

    return (
        <AssistenciaTecnicaContext.Provider
            value={{
                carregarDetalhamentos,
                listaDetalhamentos,
                detalhamentos,
                listaDetalhamentosFixos,
                alterarListaDetalhamento,
                mapDetalhamentosSelecionados
            }}
        >
            {children}
        </AssistenciaTecnicaContext.Provider>
    );
}
