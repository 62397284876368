import axios from "axios";
import guService from "./GuService";

const portal = axios.create({
  baseURL: process.env.REACT_APP_CRM_API + "/",
  headers: {
    Authorization:
      "6f10a06d416e9c0670f0cd07660c097433f639c1cb4236727aade778b121b6ea",
  },
});

export default portal;
