import moment from "moment";
import React from "react";
import DommusTabelaPaginada from "../../../components/DommusTabelaPaginada"
import { Icon } from "@iconify/react";
import arrowRightCircle from "@iconify-icons/bi/arrow-right-circle";
import { Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

export function EventosGaleria({cor, eventosGaleria, setOpenModalEvento, setEvento}) {
  moment.locale('pt-br');

  const thead = (
    <tr>
      <th style={{ borderWidth: '0px', backgroundColor: cor || '#887D7D', width: '10%' }} className="text-center">Código</th>
      <th style={{ borderWidth: '0px', backgroundColor: cor || '#887D7D', width: '40%' }} className="text-center">Descrição</th>
      <th style={{ borderWidth: '0px', backgroundColor: cor || '#887D7D' }} className="text-center">Data de Inicio</th>
      <th style={{ borderWidth: '0px', backgroundColor: cor || '#887D7D' }} className="text-center">Data do Finalização</th>
      <th style={{ borderWidth: '0px', backgroundColor: cor || '#887D7D', width: '10%' }} className="text-center">Acessar</th>
    </tr>
  );
  const rows = eventosGaleria.length ? eventosGaleria.map(evento => {
    return (
      <>
        <ReactTooltip/>
        <tr key={evento.id_evento}>
          <td>{evento.id_evento}</td>
          <td>{evento.descricao}</td>
          <td>{new Date(evento.data_inicio).toLocaleString('pt-br').replace(' ', ' as ').slice(0, -3)}</td>
          <td>{new Date(evento.data_fim).toLocaleString('pt-br').replace(' ', ' as ').slice(0, -3)}</td>
          <td data-tip="Visualizar Evento" className="text-center">
            <Icon
              onClick={() => {
                setEvento(evento);
                setOpenModalEvento(true);
              }}
              icon={arrowRightCircle}
              width="30px"
              height="30px"
              color="gray"
              style={{cursor: 'pointer'}}
            />
          </td>
        </tr>
      </>
    )
  }) : [
    <tr className="text-center">
      <td colSpan={5}>
        <Alert variant="warning">
          <FontAwesomeIcon icon={faExclamationTriangle}/> Nenhum evento cadastrado até o momento.
        </Alert>
      </td>
    </tr>
  ]

  return  <DommusTabelaPaginada linhasCabecalho={thead} optionsTabela={{}} linhasCorpo={rows}/>
}