import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

export function DommusAlert({children, variant="info", message="", className="", style={}}, dismissible=true) {
    const [show, setShow] = useState(true);

    return show ? (
            <Alert variant={variant} onClose={() => setShow(false)} dismissible={dismissible} className={className} style={style}>
                {children ? children : ""}
                {message}
            </Alert>
        ) : <></>
}