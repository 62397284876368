import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import { Col,ProgressBar } from "react-bootstrap";
import Carousel, { Modal, ModalGateway } from "react-images";
import DommusIcon from "../../../components/DommusIcon";
import guService from "../../../services/GuService";
import Galeria from "./Galeria";
import EvolucaoObraPorEtapas from "./EvolucaoObraEtapa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import DommusToggle from "../../../components/DommusToggle";
import {hexToHSL} from "../../../helpers/formatColors";


export default function EvolucaoObra({ cor, photos, porcentagem, evolucaoObra, categorias }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [mostraEvolucaoEtapa, setMostraEvolucaoEtapa] = useState(false);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [dadosCategorias, setDadosCategorias] = useState({nomeCategoria: [], porcentagensCategorias: [], cores: [] });
  const openLightbox = useCallback(({ index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);
  
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  photos = photos.filter((photo) => photo.src != null);
  cor = guService.getLocalState("cor_primaria");
  
  
  useEffect(() => {
    let nomesCategorias = [];
    let porcentagens = [];
    let cores = [];
    for (let key in evolucaoObra) {
      for (let i in categorias){
        if (categorias[i].id === evolucaoObra[key].id_categoria) {
          evolucaoObra[key].nome = categorias[i].nome;
          evolucaoObra[key].cor = editaCoresEtapas(key*(evolucaoObra.length))
          const indice = nomesCategorias.indexOf( i => i.nome === evolucaoObra[key].nome);
          if(indice >= 0){
            porcentagens[indice] += evolucaoObra[key].exibir_cliente_categorias;
          } else {
            nomesCategorias.push(evolucaoObra[key].nome);
            porcentagens.push(evolucaoObra[key].exibir_cliente_categorias);
            cores.push(evolucaoObra[key].cor);
          }
        }
      }
    }
    setDadosCategorias({nomeCategoria: nomesCategorias, porcentagensCategorias: porcentagens, cores});
  }, [evolucaoObra]);
  
  function editaCoresEtapas (aux) {
    var condicao = true;
    return hexToHSL(cor, condicao, (aux));
  }

  return (
    <>
      {/* editar visualização de obras por etapas aqui */}
      <div className="evolucaodaObra">
        <Col md={0.5} data-tooltip="Em construção">
          <DommusIcon
            icone={"hammerOutline"}
            className="iconInicial"
            size="17px"
            />
        </Col>
        <Col md={11} style={{ marginTop: 3 }}>
          <ProgressBar now={porcentagem} label={`${porcentagem}%`} />
        </Col>
        <Col md={0.5} data-tooltip="Pronto para morar">
          <DommusIcon icone={"keyIcon"} className="iconFinal" size="17px" />
        </Col>
      </div>
      {dadosCategorias.porcentagensCategorias.length > 0 &&
        <div className="evolucaodaObra">
          <Col style={{ paddingLeft: 0 }}>
            <DommusToggle title="Evolução da obra por etapas" toggle={mostraEvolucaoEtapa}
              button={<FontAwesomeIcon icon={faAngleDown} onClick={() => setMostraEvolucaoEtapa(!mostraEvolucaoEtapa)} />}>
              <EvolucaoObraPorEtapas totalObra={porcentagem} cor={dadosCategorias.cores} etapas={dadosCategorias.nomeCategoria} porcentagens={dadosCategorias.porcentagensCategorias} editaCores={editaCoresEtapas} />
            </DommusToggle>
          </Col>
        </div>
      }
      <div className="galeriaEmpreendimento">
        <Galeria photos={photos} onClick={openLightbox} />
        {/* <Gallery photos={photos} onClick={openLightbox} /> */}
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </>
  );
}
