import React from 'react';
import MenuTopo from './components/MenuTopo';
import MenuLateral from './components/MenuLateral';

export default function Base({conteudo, history}) {
	return (
		<div>
			<MenuTopo history={history}/>
			<div className="wrapper">
				{/* <div className="sidebar">
					<MenuLateral history={history}/>
				</div> */}
				<div className="content">
					{conteudo}
				</div>
			</div>
		</div>

	);
}