const urlHelper = {
  download: (link, target) => {
    let url;
    let linkOrigem;
    if (
      !link.match(
        /(mailto:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)|(((?:https?)|(?:ftp)):\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm
      )
    ) {
      url = link.split("/");
      linkOrigem = url[2].split(".");
      if (linkOrigem[1] === "dommusdriver") {
        window.open(link);
      } else {
        let fileName;
        fileName = link.split("/").slice(-1)[0];

        let form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute(
          "action",
          `https://arquivos.dommus.com.br/${fileName}`
        );
        form.setAttribute("target", target);
        let input = document.createElement("input");
        input.type = "hidden";
        input.name = "file";
        input.value = link;
        form.appendChild(input);
        document.body.appendChild(form);
        form.submit();
      }
    } else {
      window.open(link);
    }
  },
};

export default urlHelper;
