import swal from "sweetalert";

const formatDommus = {
  formatDate: (date) => {
    var date = new Date(date);
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " anos atrás";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " mes(es) atrás";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " dia(s) atrás";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hora(s) atrás";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minuto(s) atrás";
    }
    return Math.floor(seconds) + " segundo(s) atrás";
  },

  contarAtraso: (date) => {
    var date = new Date(date);
    var dias = 0;
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;
    interval = seconds / 86400;
    if (interval > 1) {
      if (Math.floor(interval) > 3) {
        return Math.floor(interval) - 3;
      }
    }
  },

  zeroPad: (num, places) => {
    return String(num).padStart(places, "0");
  },

  formataMysql: (data) => {
    if (data != undefined && data != "") {
      var dia = data.split("/")[0];
      var mes = data.split("/")[1];
      var ano = data.split("/")[2];

      return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
    } else {
      return null;
    }
  },

  formataMysqlDateHour: (data) => {
    if (data != undefined && data != "") {
      var dia = data.split("/")[0];
      var mes = data.split("/")[1];
      var ano = data.split("/")[2].split(" ")[0];
      var hora = data.split("/")[2].split(" ")[1];
      return (
        ano +
        "-" +
        ("0" + mes).slice(-2) +
        "-" +
        ("0" + dia).slice(-2) +
        " " +
        hora +
        ":" +
        "00"
      );
    } else {
      return null;
    }
  },

  formataCPF(cpf) {
    const elementoAlvo = cpf;
    const cpfAtual = cpf.value;

    let cpfAtualizado;
    if (cpfAtualizado == undefined) {
      return;
    } else {
      cpfAtualizado = cpfAtual.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        function (regex, argumento1, argumento2, argumento3, argumento4) {
          return (
            argumento1 + "." + argumento2 + "." + argumento3 + "-" + argumento4
          );
        }
      );
      elementoAlvo.value = cpfAtualizado;
    }
  },

  arredondarDecimal: function(num){
    return +(Math.round(Number(num) + "e+2")  + "e-2");
  },

  arredondarDecimalPraBaixo: function(num){
      return +(Math.floor(Number(num) + "e+2")  + "e-2");
  },

  limparMascaraCep: function (cep) {
      if (cep) {
          return cep.replace('.', '').replace('-', '');
      }
  },

  // M�todo que l� uma data PARA padr�o BR
  lerDataPb: function (dataEn) {
      let date = new Date(dataEn);
      return date.getUTCDate() + "/" + (date.getUTCMonth() + 1).toString() + "/" + date.getUTCFullYear();
  },

  criarDialogo: function(titulo, subtitulo, textoBotaoConfirmacao = "Sim, prosseguir.", textoBotaoNegativa = "N�o, cancelar."){
    return swal({
      title: titulo,
      html: subtitulo,
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#0a71b2',
      cancelButtonColor: '#d33',
      confirmButtonText: textoBotaoConfirmacao,
      cancelButtonText: textoBotaoNegativa
    });
  },

  criarErrorToast: function (mensagem){
      return {
          position: 'top-end',
          timer: 2200,
          type: 'error',
          message: mensagem
      };
  },

  criarSuccessToast: function (mensagem){
      return {
          position: 'top-end',
          timer: 2200,
          type: 'success',
          message: mensagem
      };
  },

  criarWarningToast: function (mensagem){
      return {
          position: 'top-end',
          timer: 2200,
          type: 'warning',
          message: mensagem
      };
  },

  // Formata uma string vinda da m�scara de currency para float
  formatarDeMascaraMoeda: function (strValue) {
      if (strValue != undefined) {
          return Number(strValue.toString().replace(/\./g, '').replace(',', '.'));
      }

      return null;
  },

  // Formata um valor de ponto flutante para entrada na m�scara de moeda
  formatarParaMascaraMoeda: function (value) {
      if (value == undefined) {
          value = '0';
      }
      return Number(value).toFixed(2).toString().replace('.', ',');
  },

  // Formata um valor de ponto flutante para valor monet�rio com ponto nos milhares
  formatarParaMascaraMoedaComSeparadorDeMilhares: function (value) {
      if (value == undefined) {
          value = '0';
      }

      let valor = Number(value);
      let valorAbsoluto = Math.abs(valor);
      if (valorAbsoluto < 1000) {
          return formatDommus.formatarParaMascaraMoeda(value);
      }
      let strComDecimais = valorAbsoluto.toFixed(2).toString();
      let arrInteiroSeparadoDosDecimais = strComDecimais.split('.');
      arrInteiroSeparadoDosDecimais[0] = arrInteiroSeparadoDosDecimais[0].split('').reverse().join('').match(/.{1,3}/g).join('.').split('').reverse().join('');
      let sinal = valor < 0 ? '-' : ''
      return sinal + arrInteiroSeparadoDosDecimais.join(',');
  },
  // Formatar valor de moeda para input
  formatarRealMoedaInput: function(value){
    if (value != '' && value != null && value != undefined) {
      
      let valor = value.replace(/\D/g, '');

      valor = (parseInt(valor) / 100).toFixed(2);

      valor = valor.replace('.', ',');

      valor = valor.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      return valor;

    }else{
      return value;
    }    
  },

  // Formata uma string vinda da m�scara de porcentagem para float
  formatarDeMascaraPercent: function (strValue) {
    if(!(strValue)){
      strValue = 0;
    }
      return Number(strValue.toString().replace(',', '.'));
  },

  // Formata um valor de ponto flutante para entrada na m�scara de porcentagem
  formatarParaMascaraPercent: function (value) {
    if (value == undefined) {
        value = '0';
    }
    return Number(value).toFixed(2).toString().replace('.', ',');
  },

  formatarParaMascaraCep: function (value) {
    if(!value){
      return '';
    }
    let strCep = value.toString();
    let partesCep = [];
    partesCep.push(strCep.substr(0, 2));
    partesCep.push(strCep.substr(2, 3));
    let sufixoCep = strCep.substr(5, 3);
    return partesCep.join('.') + '-' + sufixoCep;
  },

  formatarParaMascaraCpf: function (value) {
      if(value){
          let strCpf = value.toString();
          let partesCpf = [];
          partesCpf.push(strCpf.substr(0, 3));
          partesCpf.push(strCpf.substr(3, 3));
          partesCpf.push(strCpf.substr(6, 3));
          let sufixoCpf = strCpf.substr(9, 2);
          return partesCpf.join('.') + '-' + sufixoCpf;
      }
      return null;
  },

  formatarDeMascaraCpf: function (value) {
      return ( value && value.toString().replace(/\./g, '').replace(/\-/g, '') ) || null;
  },

  formatarParaMascaraCNPJ: function (value) {
    if(!value){
      return '';
    }
    let strCnpj = value.toString();
    let partesCnpj = [];
    partesCnpj.push(strCnpj.substr(0, 2));
    partesCnpj.push(strCnpj.substr(2, 3));
    partesCnpj.push(strCnpj.substr(5, 3));
    let milInvertidoCnpj = strCnpj.substr(8, 4);
    let sufixoCnpj = strCnpj.substr(12, 2);
    return partesCnpj.join('.') + "/" + milInvertidoCnpj + '-' + sufixoCnpj;
  },

  formatarDeMascaraCNPJ: function (value) {
      return  value.toString().replace(/\./g, '').replace(/\-/g, '').replace(/\//g, '');
  },

  formatarParaMascaraPhone: function (value) {
    if(value){
      let strPhone = value.toString();
      let partesPhone = [];
      let ddd = (strPhone.substr(0, 2));
      partesPhone.push(strPhone.substr(2, strPhone.length - 6));
      partesPhone.push(strPhone.substr(-4));

      return "(" + ddd + ") " + partesPhone.join("-");
    }
    return '';
  },


  formatarDeMascaraPhone: function (value) {
      return value.toString().replace(/\(/g, '').replace(/\)/g, '').replace(/\-/g, '').replace(/\s+/g, '');
  },

  formatarDeDataBr: function (dataBr) {
      if ((dataBr || null) == null) {
          return null;
      }
      let arrData = dataBr.split('/');
      return new Date(arrData[2], (arrData[1] - 1), arrData[0]);
  },

  formatarDeDataHoraBr: function (dataBr) {

      if ((dataBr || null) == null) {
          return null;
      }

      if (dataBr._isAMomentObject || false) {
          if(dataBr.year() || false){
              return dataBr.year().toString() +
                  '-' + (dataBr.month() + 1).toString().padStart(2, '0') +
                  '-' + (dataBr.date()).toString().padStart(2, '0') +
                  'T' + (dataBr.hours()).toString().padStart(2, '0') +
                  ':' + (dataBr.minutes()).toString().padStart(2, '0') +
                  ':' + (dataBr.seconds()).toString().padStart(2, '0') +
                  '.' + (dataBr.milliseconds() / 1000).toFixed(3).slice(2, 5) +
                  'Z';
          }
          return null;

      }

      let arrDataHora = dataBr.split(' ');
      let arrData = arrDataHora[0].split('/');
      let arrHora = arrDataHora[1].split(':');
      return new Date(arrData[2], (arrData[1] - 1), arrData[0], arrHora[0], arrHora[1], arrHora[2]);
  },

  formatarParaDataBr: function (objData) {
      if (((objData || null) != null) && objData != "0000-00-00" && objData != "0000-00-00 00:00:00" && objData != "0000-00-00T00:00:00Z") {
          if (!Date.isPrototypeOf(objData)) {
              objData = new Date(objData);
          }
          return objData.getUTCDate().toString().padStart(2, '0') + "/" + (objData.getUTCMonth() + 1).toString().padStart(2, '0') + "/" + objData.getUTCFullYear().toString();
      }

      return null;
  },

  formatarParaDataHoraBr: function (objData) {
      if (objData != undefined && objData != null && objData != "0000-00-00 00:00:00" && objData != "0000-00-00T00:00:00Z") {
          if (!Date.isPrototypeOf(objData)) {
              objData = new Date(objData);
          }
          return objData.getUTCDate().toString().padStart(2, '0') + "/" + (objData.getUTCMonth() + 1).toString().padStart(2, '0') + "/" + objData.getUTCFullYear().toString() + " " + objData.getHours().toString() + ":" + objData.getMinutes().toString() + ":" + objData.getSeconds().toString();
      }

      return null;
  },

  formatarParaMascaraMoedaComSeparadorRomano: function (value) {
      let valorNumerico = Number(value || 0);
      let valorComVirgula = formatDommus.formatarParaMascaraMoedaComSeparadorDeMilhares(valorNumerico);
      let valorExplodido = valorComVirgula.split('.');
      if (valorNumerico == 0) {
          return valorComVirgula;
      }
      if (valorNumerico >= 1000000000) {
          valorExplodido.pop();
          valorExplodido.pop();
          valorExplodido.pop();

          return valorExplodido.join('.').toString() + 'BI';

      }
      if (valorNumerico >= 1000000) {
          valorExplodido.pop();
          valorExplodido.pop();

          return valorExplodido.join('.').toString() + 'MI';
      }
      if (Math.abs(valorNumerico) > 1000) {
          valorExplodido.pop();
          return valorExplodido.join('.').toString() + 'K';
      }

      return valorComVirgula;
  },

  validarValueMinElement: function(value, event, minValue = 1){

      let element  = null;
      let valorMin = Number(minValue);

      if( (value == 0 && valorMin > 0) || value < valorMin){
          element = event.target.id;

          var arrayAlert = {
              position: 'top-end',
              timer: 2800,
              type: 'warning',
              message: 'O campo ' + element.split('-').join(' ')  + ' n�o pode ser inferior a ' + minValue
          };
          formatDommus.exibirSweetAlertToast(arrayAlert);

          element = element.split('-').join('_');
      }
      else if(!value && value != 0){

          element = event.target.id;

          var arrayAlert = {
              position: 'top-end',
              timer: 2800,
              type: 'warning',
              message: 'O campo ' + element.split('-').join(' ')  + ' n�o pode ser vazio'
          };
          formatDommus.exibirSweetAlertToast(arrayAlert);

          element = element.split('-').join('_');
      }

      return element;
  },

  // ORDENA UM OBJETO ESPECIFICO DE FORMA ASCEDENTE.
  orderByAsc: function(objeto, params){

      let referencia = params.referencia;

      if(params.conversion){

          switch(params.conversion){

              case 'number':
                  objeto.sort((a, b) => Number(a[referencia]) - Number(b[referencia]));
              break;

              case 'float':
                  objeto.sort((a, b) => parseFloat(a[referencia]) - parseFloat(b[referencia]));
              break;

              default:
                  objeto.sort((a, b) => a[referencia] - b[referencia]);
              break;
          }
      }
      else{
          objeto.sort((a, b) => a[referencia] - b[referencia]);
      }

      return objeto;
  },

  // ORDENA UM OBJETO ESPECIFICO DE FORMA ASCEDENTE.
  orderByDesc: function(objeto, params){

      let referencia = params.referencia;

      if(params.conversion){

          switch(params.conversion){

              case 'number':
                  objeto.sort((a, b) => Number(b[referencia]) - Number(a[referencia]));
              break;

              case 'float':
                  objeto.sort((a, b) => parseFloat(b[referencia]) - parseFloat(a[referencia]));
              break;

              default:
                  objeto.sort((a, b) => b[referencia] - a[referencia]);
              break;
          }
      }
      else{
          objeto.sort((a, b) => b[referencia] - a[referencia]);
      }

      return objeto;
  },

  // REINICIA O VALOR DO INPUT DO VALOR COBRAN�A.
  validarValorCobrancaPercent: function(params){

      let objeto     = params.objeto;
      let validation = true;
      let maxValor   = (params.max_valor) ? params.max_valor : 100.00;

      if(objeto){

          for(var key in objeto){

              if(objeto[key][params.field_value]){

                  var tipo  = objeto[key][params.field_percent];
                  var valor = parseFloat(objeto[key][params.field_value].split('.').join('').replace(',', '.'));

                  if(tipo == 'P' && valor > maxValor){
                      validation = false;
                      break;
                  }
              }
          }
      }

      return validation;
  },

  exibirSweetAlertToast(arrayAlert){

    var backgroundColor     = '#FFF';
    var classColorFontTitle = 'toast-title-default';
  
    var arrayColorBackground = new Array();
  
    arrayColorBackground.push({hexadecimal_color: '#F44336', color: 'error'});
    arrayColorBackground.push({hexadecimal_color: '#FF9800', color: 'warning'});
    arrayColorBackground.push({hexadecimal_color: '#4CAF50', color: 'success'});
    arrayColorBackground.push({hexadecimal_color: '#03A9F4', color: 'info'});
    arrayColorBackground.push({hexadecimal_color: '#ffc107', color: 'question'});
  
    var index = arrayColorBackground.findIndex(x => x.color === arrayAlert.type);
  
    if (index >= 0){
      backgroundColor     = arrayColorBackground[index].hexadecimal_color;
      classColorFontTitle = 'toast-title';
    }
  
    const toast = swal.mixin({
      toast: true,
      position: arrayAlert.position,
      showConfirmButton: false,
      timer: arrayAlert.timer
    });
  
    toast({
      type:arrayAlert.type,
      background: backgroundColor,
      title: '<span class="'+ classColorFontTitle +'">'+ arrayAlert.message +'</span>'
    });
  },
  valoresVazios: ['', null, 'null', undefined, 'undefined'],
  removerAcentos: (string) =>  string.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
};
export default formatDommus;
