import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Card,
  CardDeck,
  ProgressBar,
  Accordion,
} from "react-bootstrap";

export default function BannerEmpreendimentoEstatico({ foto, altura }) {
  return (
    <div
      style={{
        backgroundImage: `url(${foto})`,
        backgroundSize: "cover",
        display: "flex",
        width: "105%",
        height: altura ? altura : 350,
        marginLeft: -30,
        marginBottom: 40,
        marginTop: -15,
      }}
    >
      {/* <img className="banner" src={foto} /> */}
    </div>
  );
}
