import React, { Component, useEffect } from "react";
import "../MenuLateral/index.css";
import {
  faStream,
  faPowerOff,
  faChartBar,
  faCog,
  faHeadset,
  faFunnelDollar,
  faBullhorn,
  faArrowLeft,
  faTicketAlt,
  faCommentAlt,
  faHeadphonesAlt,
  faEnvelope,
  faUserSlash,
  faPeopleArrows,
  faCalendarMinus,
  faFileCsv,
  faComment,
  faBlenderPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  faWhatsapp,
  faFacebookSquare,
  faLinkedin,
  faChrome,
} from "@fortawesome/free-brands-svg-icons";
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie";
import guService from "../../services/GuService";

export default class MenuTopo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openAtendimento: false,
      openConfiguracoes: false,
    };
    this.mudarEstado = this.mudarEstado.bind(this);
    this.abrirAtendimento = this.abrirAtendimento.bind(this);
    this.abrirConfiguracoes = this.abrirConfiguracoes.bind(this);
  }

  mudarEstado(e) {
    e.preventDefault();
    const novoOpen = !this.state.open;
    this.setState({ open: novoOpen });
  }

  abrirAtendimento(e) {
    e.preventDefault();
    const novoOpenAtendimento = !this.state.openAtendimento;
    this.setState({ openAtendimento: novoOpenAtendimento });
  }

  abrirConfiguracoes(e) {
    e.preventDefault();
    const novoOpenConfiguracoes = !this.state.openConfiguracoes;
    this.setState({ openConfiguracoes: novoOpenConfiguracoes });
  }

  logout(e) {
    guService.logout();
    window.location.reload(false);
  }

  render() {
    const classeAsideSidebar = "sidebar" + (this.state.open ? "" : " hide");
    const classeSidebarWrapper =
      "sidebar-wrapper" + (this.state.open ? "" : " is-closed");
    const classeAtendimento =
      "suporte is-menu " + (this.state.openAtendimento ? " open" : "");
    const classeConfiguracoes =
      "suporte is-menu " + (this.state.openConfiguracoes ? " open" : "");
    const telas = guService.getLocalState("telas");

    return (
      <>
        <aside className={classeAsideSidebar}>
          {!this.state.open ? <ReactTooltip /> : <></>}
          <div className={classeSidebarWrapper}>
            <div className="sidebar-title">
              <span>Menu</span>
              <a href="#" data-tip="Menu" onClick={this.mudarEstado}>
                <FontAwesomeIcon icon={faStream} />
              </a>
            </div>
            <div className="siderbar-holder">
              <div className="menu-structure">
                <ul>
                  {telas.includes("dashboards") && (
                    <li>
                      <Link to="/dashboard">
                        <FontAwesomeIcon
                          color="#F44336"
                          icon={faChartBar}
                          data-tip="Dashboard"
                        />
                        <p>Dashboard</p>
                      </Link>
                    </li>
                  )}

                  {telas.includes("oportunidades") && (
                    <li>
                      <a href="/">
                        <FontAwesomeIcon
                          color="#4caf50"
                          icon={faFunnelDollar}
                          data-tip="Lista de Oportunidades"
                        />
                        <p>Oportunidades</p>
                      </a>
                    </li>
                  )}
                  {telas.includes("atendimento_online") && (
                    <li>
                      <a href="https://desk.blip.ai/" target="_blank">
                        <FontAwesomeIcon
                          color="#00c6d7"
                          icon={faComment}
                          data-tip="Atendimento Online"
                        />
                        <p>Atendimento Online</p>
                      </a>
                    </li>
                  )}
                  {telas.includes("agenda_trabalho") && (
                    <li>
                      <a
                        href={guService.getLocalState("linkVendasDommusCrm")}
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          color="#3f51b5"
                          icon={faCalendarMinus}
                          data-tip="Agenda de Trabalho"
                        />
                        <p>Agenda de Trabalho</p>
                      </a>
                    </li>
                  )}
                  {telas.includes("campanhas") && (
                    <li>
                      <Link to="/campanha">
                        <FontAwesomeIcon
                          color="#3f51b5"
                          icon={faBullhorn}
                          data-tip="Campanhas"
                        />
                        <p>Campanhas</p>
                      </Link>
                    </li>
                  )}
                  {telas.includes("leads_nao_distribuidos") && (
                    <li>
                      <Link to="/leads/nao-distribuidos">
                        <FontAwesomeIcon
                          color="#F44336"
                          icon={faUserSlash}
                          data-tip="Leads não distribuídos"
                        />
                        <p>Não distribuídos</p>
                      </Link>
                    </li>
                  )}
                  {telas.includes("filas-atendimento") && (
                    <li>
                      <Link to="/filas-atendimento">
                        <FontAwesomeIcon
                          color="#bdd62f"
                          icon={faPeopleArrows}
                          data-tip="Filas de Atendimento"
                        />
                        <p>Filas de Atendimento</p>
                      </Link>
                    </li>
                  )}
                  {telas.includes("oferta_ativa") && (
                    <li>
                      <Link to="/oferta-ativa">
                        <FontAwesomeIcon
                          color="#4caf50"
                          icon={faBlenderPhone}
                          data-tip="Oferta Ativa"
                        />
                        <p>Oferta Ativa</p>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
              <div className="info-structure">
                <ul>
                  <li onClick={this.abrirConfiguracoes}>
                    <a href="#">
                      <FontAwesomeIcon
                        icon={faCog}
                        color="var(--dommus-color-2)"
                        data-tip="Configurações"
                      />
                      <p>Configurações</p>
                    </a>
                  </li>
                  <li onClick={this.abrirAtendimento}>
                    <a href="#">
                      <FontAwesomeIcon
                        icon={faHeadset}
                        color="#868aa8"
                        data-tip="Atendimento"
                      />
                      <p>Atendimento</p>
                    </a>
                  </li>
                  <li>
                    <Link to="/sair">
                      <FontAwesomeIcon
                        icon={faPowerOff}
                        color="#f44336"
                        data-tip="Sair"
                      />
                      <p>Sair</p>
                    </Link>
                  </li>
                </ul>
                <div className="copy">
                  Copyright © 2020{" "}
                  <a href="https://www.linkedin.com/company/dommus-sistemas/">
                    Dommus Sistemas
                  </a>
                </div>
              </div>
            </div>
          </div>
        </aside>
        <div className="menu-sections">
          <section className={classeAtendimento}>
            <header>
              Atendimento ao Usuário
              <div className="close-pane" onClick={this.abrirAtendimento}>
                <FontAwesomeIcon icon={faArrowLeft} color="#868aa8" />
              </div>
            </header>
            <h6>Suporte:</h6>
            <ul className="chat">
              <li>
                <a
                  href="https://g3comunicacao.tomticket.com/?account=1390400P23042018093406"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faTicketAlt} color="#ffcd56" />
                  <p>Ticket</p>
                </a>
                <span>|</span>
                <a
                  href="https://g3comunicacao.tomticket.com/chat/?id=EP21725&amp;ac=1390400P23042018093406"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faCommentAlt} color="#ffcd56" />
                  <p>Chat</p>
                </a>
              </li>
            </ul>
            <h6>Telefones:</h6>
            <ul className="telefones">
              <li>
                <a href="tel:+55 31 38899776">
                  <FontAwesomeIcon icon={faHeadphonesAlt} color="#673AB7" />
                  <p>31 3889 - 9776</p>
                </a>
              </li>
              <li>
                <a href="tel:+55 31 984895755">
                  <FontAwesomeIcon icon={faWhatsapp} color="#009688" />
                  <p>31 98489 - 5755</p>
                </a>
              </li>
            </ul>
            <h6>Email:</h6>
            <ul className="email">
              <li>
                <a href="mailto:comercial@dommus.com.br">
                  <FontAwesomeIcon icon={faEnvelope} color="#E57373" />
                  <p>comercial@dommus.com.br</p>
                </a>
              </li>
            </ul>
            <h6>Redes Sociais:</h6>
            <ul className="redes-sociais">
              <li>
                <a
                  href="https://www.facebook.com/dommussistemas/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFacebookSquare} color="#3b5998" />
                  <p>Facebook</p>
                </a>
                <span>|</span>
                <a
                  href="https://www.linkedin.com/company/dommus-sistemas/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faLinkedin} color="#3b5998" />
                  <p>LinkedIn</p>
                </a>
              </li>
            </ul>
            <h6>Site:</h6>
            <ul className="website">
              <li>
                <a href="https://website.dommus.com.br" target="_blank">
                  <FontAwesomeIcon
                    icon={faChrome}
                    color="var(--dommus-color-2)"
                  />
                  <p>Website</p>
                </a>
              </li>
            </ul>
            <h6>V 0.0.1</h6>
          </section>
          <section className={classeConfiguracoes}>
            <header>
              Configurações
              <div className="close-pane" onClick={this.abrirConfiguracoes}>
                <FontAwesomeIcon icon={faArrowLeft} color="#868aa8" />
              </div>
            </header>
            <ul className="chat">
              <li>
                <a href="">
                  <p>Mídias</p>
                </a>
              </li>
              <li>
                <a href="">
                  <p>Templates</p>
                </a>
              </li>
              <li>
                <a href="/integracoes">
                  <p>Integrações</p>
                </a>
              </li>
              <li>
                <a href="">
                  <p>Funil de vendas</p>
                </a>
              </li>
              <li>
                <a href="">
                  <p>Perfil do usuário</p>
                </a>
              </li>
            </ul>
          </section>
        </div>
      </>
    );
  }
}
