import React, {
    useCallback,
    useMemo,
} from "react";
import "./index.css";
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import ValidaTipoArquivo from "../../../../../helpers/ValidaTipoArquivo";
import Swal from "sweetalert2";
import { errorToast } from "../../../../DommusToast";
import { UploadItemResposta } from "./UploadItemResposta";

export function RespostaItem({ fieldName, perguntaTipo, resposta, selected }) {
    const methods = useFormContext();
    const idName = useMemo(() => {
        return `${fieldName}-r-${resposta?.id_assistencia_resposta}`;
    }, [fieldName, resposta]);

    const checkIcon = useMemo(() => {
        if(perguntaTipo === 'TA'){
          return "";
        }
        if (selected) {
            return (
                <div className="square-check">
                    <FontAwesomeIcon icon={faCheck} className="icon-check" />
                </div>
            );
        } else {
            return <div className="square-check"></div>;
        }
    }, [selected]);

    const handleResolveCampo = ()=>{
        switch (perguntaTipo) {
            case 'TA':
                return (
                    <textarea
                        className="form-control"
                        ref={methods.register()}
                        name={fieldName}
                        id={idName}
                        rows={10}
                    >
                    </textarea>
                )
            case 'SR':
            case 'MR':
                return (
                    <>
                        <label
                            htmlFor={idName}
                            className={`label-item ${selected ? "selecionado" : ""}`}
                        >
                            {checkIcon}
                            {resposta?.descricao}
                        </label>
                        <input
                        ref={methods.register()}
                        name={fieldName}
                        id={idName}
                        value={resposta?.id_assistencia_resposta}
                        type={perguntaTipo === "SR" ? "radio" : "checkbox"}
                        hidden
                        />
                    </>
                )
            case 'UP':
                return(
                    <UploadItemResposta
                        fieldName={fieldName}
                    />
                )
            default:
                break;
        }
    }

    return (
        <div className="resposta-item">
            {handleResolveCampo()}
        </div>
    );
}
