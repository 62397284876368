import crm from './crm';
import {trackPromise} from "react-promise-tracker";
import swal from "sweetalert";

const CessaoDireitosService = {
    buscarMotivos: () => {
        return crm.get('/cessao-direitos/motivos');
    },
    buscarPermissao: (processo) => {
        return crm.get('/cessao-direitos/'+processo);
    },
    solicitarCessaoDireitos: (processo, motivo, descricao, arquivoCarregado) => {
        let uri = '/cessao-direitos/solicitar';
        let method = 'post';
        return crm[method].call(crm, uri, {processo, motivo, descricao, arquivo: arquivoCarregado});
    },
};

export default CessaoDireitosService;
