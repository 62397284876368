import React, {useCallback, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import moment from 'moment'
import 'moment/min/locales'
import {Calendar, momentLocalizer} from 'react-big-calendar'
import './style.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {trackPromise} from "react-promise-tracker";
import crm from "../../../services/crm";
import {errorToast} from "../../DommusToast";

export default function Agenda({openAgenda, closeAgenda}) {
    moment.locale('pt-br');


    const [events, setEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM'));
    const [currentViewMode, setCurrentViewMode] = useState('month');

    const handleSelectEvent = useCallback((event) => {
        if (event.id_oportunidade) {
            window.open(`/oportunidade/${event.id_oportunidade}?aba=atendimento`);
        }
       
    }, [])

    const messages = {
        date: 'Data',
        time: 'Horário',
        event: 'Evento',
        allDay: 'Dia Todo',
        week: 'Semana',
        work_week: 'Semana de Trabalho',
        day: 'Dia',
        month: 'Mês',
        previous: '<',
        next: '>',
        yesterday: 'Ontem',
        tomorrow: 'Amanhã',
        today: 'Hoje',
        agenda: 'Agenda',

        noEventsInRange: 'Não há mais eventos nesse período.',

        showMore: total => `+${total} mais`,
    }

    const handleCalendarNavigate = useCallback((date) => {
        setSelectedDate(moment(date).format('YYYY-MM'));
    }, []);

    useEffect(() => {
        switch (currentViewMode) {
            case 'month':
                handleSetAgendaWidth('90%');
                break;
            case 'week':
                handleSetAgendaWidth('60%');
                break;
            case 'day':
                handleSetAgendaWidth('40%');
                break;
            default:
                handleSetAgendaWidth('50%');
        }
    }, [currentViewMode]);

    const handleSetAgendaWidth = useCallback((widthProp = '50%') => {
        const el = document.querySelector('section.equipes');
        el.style.width = widthProp;
        el.style.minWidth = widthProp;
    }, [])

    useEffect(() => {
        handleGetEvents();
    }, [selectedDate])

    let eventPropGetter = (event) => {
        if(event.finalizado) {
            return {
                className:'event-finished'
            }
        }
        return {};

    }

    let formats = {
        dateFormat: "DD",
        dayFormat: "DD",
        weekdayFormat: "ddd",
        timeGutterFormat: "HH:mm",
        monthHeaderFormat: "MMMM/YYYY",
        weekHeaderFormat: "MM/YYYY",
        dayHeaderFormat: "dddd - DD/MM",
        dayRangeHeaderFormat: ({start, end}) =>
            (
                moment(start).format("DD/MM") +
                " à " +
                moment(end).format('DD/MM')
            )
    };


    const handleGetEvents = useCallback(() => {
     /*   trackPromise(crm.get(`oportunidade/atendimento/eventosAgenda/${selectedDate}`).then((response) => {
            const arrayEventos = response.data.map(event => {
                let startTime = new Date(moment(event.data + ' ' + '08:00:00').format('YYYY-MM-DD HH:mm:ss'));
                let endTime = new Date(moment(event.data + ' ' + '09:00:00').format('YYYY-MM-DD HH:mm:ss'));
                if (event.hora) {
                    startTime = new Date(moment(event.data + ' ' + event.hora).format('YYYY-MM-DD HH:mm:ss'));
                    endTime = new Date(moment(event.data + ' ' + event.hora).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss'));
                }
                return {
                    id: event.id_oportunidade_atendimento,
                    id_oportunidade: event.id_oportunidade,
                    id_oferta_ativa: event.id_oferta_ativa,
                    title: `${event.titulo}`,
                    start: startTime,
                    finalizado: event.finalizado,
                    end: endTime,
                    className: 'event-finished'
                }
            })
            setEvents(arrayEventos);
        }, (error) => {
            errorToast.fire({text: `Ocorreu um erro ao : ${error}`});
        }))*/
    }, [selectedDate])

    useEffect(() => {
        if (openAgenda) {
            handleGetEvents();
        } else {
            setEvents([])
        }
    }, [openAgenda]);
    return (
        <>
            <div className={"sidenav-overlay " + (openAgenda && "open")} onClick={() => {
                closeAgenda()
            }}>&nbsp;</div>
            <section className={"equipes " + (openAgenda && "open")}>
                <header>
                    <div>Agenda</div>
                    <div className={"close-pane"}><FontAwesomeIcon icon={faArrowRight} onClick={closeAgenda}/></div>
                </header>
                <div>
                    <Calendar
                        localizer={momentLocalizer(moment)}
                        events={events}
                        views={['month', 'week', 'day']}
                        messages={messages}
                        startAccessor="start"
                        endAccessor="end"
                        formats={formats}
                        eventPropGetter={eventPropGetter}
                        onSelectEvent={(event) => {
                            handleSelectEvent(event)
                        }}
                        onNavigate={(event) => {
                            handleCalendarNavigate(event)
                        }}
                        onView={(event) => {
                            setCurrentViewMode(event)
                        }}
                        style={{height: 500}}
                    />
                </div>
            </section>
        </>
    );
}

