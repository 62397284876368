import React, { useEffect } from "react";
import GuService from "../../services/GuService";
import { useParams } from "react-router";
import DommusLoading from "../../components/DommusLoading/DommusLoading";
import { erroSwal } from '../../helpers/DommusSwal'
import configuracaoService from "../../services/ConfiguracaoService";

export function LoginClienteViaLink({ history }) {
  const params = useParams();

  useEffect(() => {
    localStorage.clear();
    GuService.setLocalState("slug", params?.slug);
    GuService.setLocalState("idUsuario", 1);
    buscarConfiguracoesVisuais()
  }, [params]);

  function buscarConfiguracoesVisuais() {
    configuracaoService.buscarConfiguracoesVisuaisAuth(params?.slug)
      .then(response => {
        response.data.forEach((configuracao) => {
          GuService.setLocalState(configuracao.chave, configuracao.valor)
        })
        login()
      }).catch(error => {
        console.log(error)
        console.log(error?.response)
        erroSwal('Houve um erro ao tentar buscar as configurações visuais.')
      })
  }

  function login() {
    GuService.loginClienteViaLink(params?.slug, params?.token)
      .then(response => {
        const { cpf, id_usuario, nome, token, tipo_usuario } = response.data;
        GuService.setLocalState("idUsuario", id_usuario);
        GuService.setLocalState("usuarioTipo", tipo_usuario);
        GuService.setLocalState("slug", params?.slug);
        GuService.setLocalState("CPF", cpf);
        GuService.setLocalState("Nome", nome);
        GuService.setLocalState("token", token);
        if(response?.data?.recurso) {
          GuService.setLocalState('recursoLogin', response?.data?.recurso)
        }
        history.push("/");
        window.location.reload();
      }).catch(error => {
        console.log(error)
        console.log(error?.response)
        let msg = 'Houve um erro ao tentar fazer o login.'
        if(error?.response?.data?.motivo) {
          msg = error.response.data.motivo
        }
        erroSwal(msg).then(() => {
          localStorage.clear()
          window.location.href = `/login/${params?.slug}`
          window.reload()
        })
      })
  }

  return <DommusLoading/> 
}
