import React from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import './index.css';
import formatDommus from "../../helpers/format";
import DommusToggle from "../../components/DommusToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faUserCheck, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { mascaraCpfCnpj, celularMask } from "../../components/InputTextMask"

function CardVistoriaAgendada({ vistoria, titulo, retrocederAgendamento, tipo, alternarModalProcuracao }) {
    const procuracao = vistoria?.procuracao ? Object.assign({ id_agenda_entrega: vistoria.id_agenda_entrega }, JSON.parse(vistoria.procuracao)) : null

    return (
        vistoria ? <>
            <DommusToggle title={`${tipo === "V" || tipo === "A" ? "Vistoria" : tipo == "O" ? titulo : "Entrega de Chaves"}: Veja as informações do seu agendamento`} toggle={true} >
                <Row>
                    <Col>
                        <Card.Text>
                            <strong>Data: </strong> {formatDommus.formatarParaDataBr(vistoria.data_agendamento)} <br />
                            <strong>Horário: </strong> {vistoria.horario_inicio} <br />
                            <strong>Vistoriador: </strong> {vistoria.vistoriador} <br />
                            <strong>Data do Agendamento: </strong> {formatDommus.formatarParaDataBr(vistoria.cadastrado_em)}  <br />
                            {vistoria.recomendacoes && <> <strong>Recomendações: </strong>{vistoria.recomendacoes} <br /> </>}
                        </Card.Text>
                    </Col>
                </Row>
            </DommusToggle>
            {procuracao && (
                <Alert variant="info">
                    <strong>Dados da Procuração.</strong>
                    <hr className="my-2"/>
                    <Row>
                        <Col>
                            <strong>Nome: </strong> {procuracao.nome}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong>Procuração: </strong> <FontAwesomeIcon icon={faFilePdf}/> <a href={procuracao.procuracao_url} target="_blank">Procuração.pdf</a>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong>CPF: </strong> {mascaraCpfCnpj(procuracao.cpf)}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong>RG: </strong> {procuracao.rg}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong>E-mail: </strong> {procuracao.email}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong>Telefone: </strong> {celularMask(procuracao.telefone)}
                        </Col>
                    </Row>
                </Alert>
            )}
            <Row>
                {vistoria.realizado != 'S' && (<>
                    {(((new Date(vistoria.data_agendamento)).getTime() > (new Date()).getTime()) || vistoria.reagendamento_pos_data_vistoria) && (
                            <Col>
                                <Button onClick={(e) => { retrocederAgendamento() }} className="butao-retroceder-vistoria">Quero Reagendar</Button>
                            </Col>
                    ) || <></>}
                    {/* Validar se já passou da data da vistoria */}
                    {(new Date(vistoria.data_agendamento)).getTime() < (new Date()).getTime() && (
                        <Col>
                            <Button variant={procuracao ? 'warning' : 'info'} onClick={() => alternarModalProcuracao(tipo, procuracao)}>
                            <FontAwesomeIcon icon={procuracao ? faUserEdit : faUserCheck} className="mr-2"/> {`${procuracao ? 'Alterar' : 'Informar'} Procuração`}
                            </Button>
                        </Col>
                    ) || <></>}
                </>)}
            </Row>
        </>
            :
            <>
                <Card.Text>Você não posssui liberação para criar uma {(tipo === "V" || tipo === "A" ? "Vistoria" : "Entrega de Chaves")}.</Card.Text>
                <Card.Text>Verifique a etapa do seu processo!</Card.Text>
            </>
    )
}

export default CardVistoriaAgendada
