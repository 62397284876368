import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Button, Form, Col, InputGroup, Card, CardDeck, ProgressBar, Accordion } from 'react-bootstrap';
import ListaItem from './ListaItem';
import { Icon, InlineIcon } from '@iconify/react';
import arrowLeftSquare from '@iconify-icons/bi/arrow-left-square';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import DommusToggle from "../../../components/DommusToggle";
import ModalDommus from '../../../components/Modal';
import { cpfMask, celularMask, cepMask, identidadeMask } from "../../../components/InputTextMask";
import bxsUserDetail from '@iconify-icons/bx/bxs-user-detail';
import './index.css';
import guAPI from "../../../services/GuService";
import processoService from '../../../services/ProcessoService';
import { trackPromise } from 'react-promise-tracker';
 
export default function Documentacao() {
    const [show, setShow] = useState(false);
    const [newTitulo, setNewTitulo] = useState('');
    const [arquivos, setArquivos] = useState([]);

    const handleShow = () => setShow(true);

    
    useEffect(() => {
        buscarProponenteCpf();
    }, [])
    function buscarProponenteCpf() {
        trackPromise(
            processoService.buscarProponenteCpf(guAPI.getLocalState('Processo'), guAPI.getLocalState('CPF')).then(response => {
                const dados = response.data;
                setArquivos(dados.proponente.arquivos);
            })
        );
    }

    function addNovoArquivo(arquivo) {
        arquivo.preventDefault();
        const itensCopy = Array.from(arquivos);
        itensCopy.push({ id: arquivos.length, value: arquivo });
        setArquivos(itensCopy);
        setShow(false);
       
    }

    function updateNovoArquivo({ target }, index) {
        const itensCopy = Array.from(arquivos);
        itensCopy.splice(index, 1, { id: index, value: target.value });
        setArquivos(itensCopy);
    }

    function deleteArquivo(index) {
        const itensCopy = Array.from(arquivos);
        itensCopy.splice(index, 1);
        setArquivos(itensCopy);
    }

    function submit(e) {
        e.preventDefault();
    
    }

    return <>
        <Container fluid>
            <Row>
                <Card className="cardDocumentacao">
                    <Card.Body className="cardBoddy">
                        <Link to="./"  ><Icon className="iconCadastro" style={{ backgroundColor: guAPI.getLocalState('cor-menu-1') }} icon={bxsUserDetail} /></Link>
                        <Card.Header className="cardHeader" style={{ backgroundColor: guAPI.getLocalState('cor-menu-1') }}>
                            <div className="vazio">

                            </div>
                            <div className="tituloCadastro" >
                                Documentação
                            </div>
                            <Link to="./" ><Icon className="iconVoltar" icon={arrowLeftSquare} /> </Link>
                        </Card.Header>
                        <div className="direita_doc">
                            
                                <DommusToggle title="Documentos" toggle={true}>
                                   {/*  <Form onSubmit={addNovoArquivo}>
                                        <Button variant="success" type="submit">
                                            Adicionar
                                        </Button>
                                    </Form>
                                    <NovoArquivo onSubmit={addNovoArquivo} /> */}
                                    <CardDeck>
                                        {arquivos.map(item => (
                                            <ListaItem
                                                key={item.id_upload}
                                                value={item}
                                                /*onChange={(event) => updateNovoArquivo(event, index)}
                                                onDelete={() => deleteArquivo(index)}*/
                                            />
                                        ))}
                                    </CardDeck>
                                </DommusToggle>

                        </div>
                    </Card.Body>
                </Card>
            </Row>
        </Container >
    </>

}