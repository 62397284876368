import React from 'react';
import {Modal} from 'react-bootstrap';
import './index.css';

export default function ModalDommus({children, ...props}) {

	return <Modal 
		show={props.open} 
		onHide={props.close} 
		size={props.size || "lg"}
		centered
		style={{
			zIndex: '1040',
			paddingRight: '17px',
			display: 'block'
		}}
		backdrop={props.estatico === true ? 'static' : true}
		keyboard={props.estatico === true ? false : true}
		className={props.className}
	>
		<Modal.Header closeButton>
			<Modal.Title><h2>{props.titulo}</h2></Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<div className={"conteudo"}>
				{props.content}
				{children}
			</div>
		</Modal.Body>
	</Modal>;
}
