import React, { useState, setState, useEffect } from "react";
import PropTypes from "prop-types";
import { Badge, Button, Col, Row } from "react-bootstrap";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import ModalDommus from "../../../components/Modal";
import NovaIndicacao from "../NovaIndicacao";
import * as yup from "yup";
import "./index.css";
// npm install --save-dev @iconify-icons/ant-design
import { Icon } from "@iconify/react";
import plusCircleFilled from "@iconify-icons/ant-design/plus-circle-filled";
import arrowRightCircle from "@iconify-icons/bi/arrow-right-circle";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import guService from "../../../services/GuService";
import IndicacoesService from "../../../services/IndicacoesService";
import formatDommus from "../../../helpers/format";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
  },
}));

function createData(nome, telefone, email, endereco, status) {
  return { nome, telefone, email, endereco, status };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#4CAF50",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#DDDDDD",
    },
  },
}))(TableRow);

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;
  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };
  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };
  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };
  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

export default function MinhasIndicacoes({ cor }) {
  function adicionalinha(dados) {
    rows.push(createData(dados));
  }

  const classes = useStyles2();
  const [page, setPage] = useState(0);
  const [openModalNovaIndicacao, setOpenModalNovaIndicacao] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [rows, setRows] = useState([]);

  const verificarContemplacao = (situacao) => {
    if (situacao === "C") {
        return "Contemplado";
    } else if (situacao === "NC") {
        return "Não contemplado";
    } else {
        return "Status inválido";
    }
}

  let idUsuario = guService.getLocalState("idUsuario");

  useEffect(() => {
    trackPromise(
      IndicacoesService.buscarIndicacoes(idUsuario).then(
        (response) => {
          setRows(response.data ?? [])
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text:
              "Ocorreu um erro ao realizar a busca por suas indicações: " +
              error,
            icon: "error",
          });
        }
      ));
  }, []);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ backgroundColor: cor }}>
                {" "}
                Nome{" "}
              </StyledTableCell>
              <StyledTableCell style={{ backgroundColor: cor }} align="center">
                Telefone
              </StyledTableCell>
              <StyledTableCell style={{ backgroundColor: cor }} align="center">
                Email
              </StyledTableCell>
              <StyledTableCell style={{ backgroundColor: cor }} align="center">
                Endereço
              </StyledTableCell>
              <StyledTableCell style={{ backgroundColor: cor }} align="center">
                Status
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, index) => (
              <StyledTableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.nome}
                </TableCell>
                <TableCell align="center">{formatDommus.formatarParaMascaraPhone(row.telefone)}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{row.endereco}</TableCell>
                <TableCell align="center"><h6><Badge variant="info">{verificarContemplacao(row.situacao)}</Badge></h6></TableCell>
              </StyledTableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter className="pagination">
            <TableRow>
              <TablePagination
                labelRowsPerPage="Linhas por página"
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "linhas por pagina" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Icon
        className="addIndicacao"
        onClick={() => {
          setOpenModalNovaIndicacao(true);
        }}
        icon={plusCircleFilled}
        color={cor}
      />
      <ModalDommus
        className="modalNovaIndicacao"
        open={openModalNovaIndicacao}
        size={"md"}
        close={() => {
          setOpenModalNovaIndicacao(false);
        }}
        titulo={"Nova Indicação"}
      >
        <NovaIndicacao data={adicionalinha} />
      </ModalDommus>
    </>
  );
}
