import React from "react";
import { Icon, InlineIcon } from "@iconify/react";
import loadbarDoc from "@iconify-icons/gg/loadbar-doc";
import addressCard from "@iconify-icons/fa-regular/address-card";
import twotoneAttachMoney from "@iconify-icons/ic/twotone-attach-money";
import bxBuildingHouse from "@iconify-icons/bx/bx-building-house";
import bxsUserDetail from "@iconify-icons/bx/bxs-user-detail";
import chatBubble from "@iconify-icons/cil/chat-bubble";
import data1 from "@iconify-icons/carbon/data-1";
import folderOpenOutlined from "@iconify-icons/ant-design/folder-open-outlined";
import moneyAlt from "@iconify-icons/dashicons/money-alt";
import financeIcon from "@iconify-icons/carbon/finance";
import documentPdf from "@iconify-icons/carbon/document-pdf";
import outlinePayments from "@iconify-icons/ic/outline-payments";
import lionFace from "@iconify-icons/emojione-monotone/lion-face";
import handshakeIcon from "@iconify-icons/la/handshake";
import keyIcon from "@iconify-icons/bi/key";
import callIn from "@iconify-icons/simple-line-icons/call-in";
import callEnd from "@iconify-icons/simple-line-icons/call-end";
import checkList3 from "@iconify-icons/line-md/check-list-3";
import handPointRight from "@iconify-icons/la/hand-point-right";
import bxsOffer from "@iconify-icons/bx/bxs-offer";
import penFancy from "@iconify-icons/la/pen-fancy";
import hammerOutline from "@iconify/icons-ion/hammer-outline";
import employeeGroupSolid from "@iconify-icons/clarity/employee-group-solid";
import headphoneIcon from "@iconify/icons-akar-icons/headphone";
import megaphone from "@iconify-icons/heroicons/megaphone"
import handshakeO from "@iconify-icons/fa/handshake-o";
import userCicleO from "@iconify-icons/fa/user-circle-o";
import dollar from "@iconify-icons/fa/dollar";
import columeControlPhone from "@iconify-icons/fa/volume-control-phone";
import phone from "@iconify-icons/fa/phone";
import money from "@iconify-icons/fa/money";
import receiptOutline from "@iconify/icons-ion/receipt-outline";
import institution from "@iconify-icons/fa/institution";
import remove from "@iconify-icons/fa/remove";
import legal from "@iconify-icons/fa/legal";
import envelope from "@iconify-icons/fa/envelope";
import key from "@iconify-icons/fa/key";
import filePdfO from "@iconify-icons/fa/file-pdf-o";
import exchange from "@iconify-icons/fa/exchange";
import building from "@iconify-icons/fa/building";
import home from "@iconify-icons/fa/home";
import gift from "@iconify-icons/fa/gift";
import exclamationCircle from "@iconify-icons/fa/exclamation-circle";
import envelopeO from "@iconify-icons/fa/envelope-o";
import users from "@iconify-icons/fa/users";
import fileTextO from "@iconify-icons/fa/file-text-o";

export default function DommusIcon({
  icone,
  classe = null,
  cor = null,
  size = "10px",
}) {
  const MediaIcons = {
    loadbarDoc: loadbarDoc,
    addressCard: addressCard,
    twotoneAttachMoney: twotoneAttachMoney,
    bxBuildingHouse: bxBuildingHouse,
    outlinePayments: outlinePayments,
    bxsOffer: bxsOffer,
    financeIcon: financeIcon,
    data1: data1,
    documentPdf: documentPdf,
    chatBubble: chatBubble,
    folderOpenOutlined: folderOpenOutlined,
    moneyAlt: moneyAlt,
    bxsUserDetail: bxsUserDetail,
    lionFace: lionFace,
    handshakeIcon: handshakeIcon,
    keyIcon: keyIcon,
    penFancy: penFancy,
    callIn: callIn,
    callEnd: callEnd,
    checkList3: checkList3,
    handPointRight: handPointRight,
    employeeGroupSolid: employeeGroupSolid,
    headphone: headphoneIcon,
    hammerOutline: hammerOutline,
    megaphone: megaphone,
    handshakeO : handshakeO,
    userCicleO : userCicleO,
    dollar : dollar,
    columeControlPhone : columeControlPhone,
    phone : phone,
    money : money,
    receiptOutline : receiptOutline,
    institution : institution,
    remove : remove,
    legal : legal,
    envelope : envelope,
    key : key,
    filePdfO : filePdfO,
    exchange : exchange,
    building : building,
    home : home,
    gift : gift,
    exclamationCircle : exclamationCircle,
    envelopeO : envelopeO,
    users : users,
    fileTextO : fileTextO,
  };

  const icon = MediaIcons[icone];

  return (
    <>
      <Icon
        className={classe}
        icon={icon}
        style={{ backgroundColor: cor, marginRight: "5px" }}
        fontSize={size}
      />
    </>
  );
}
