import { createContext, useReducer } from "react";
import { trackPromise } from "react-promise-tracker";
import guService from "../services/GuService";
import ticketService from "../services/TicketService";
import React from "react";
const initialState = {
    mobuss:null,
    getMobussWithCpf:() => {},
    handleGoToMobuss:() => {}
}



export const IntegracaoContext = createContext(initialState)


const reducer = (state,action) => {

    switch(action.type){
        case 'SET_MOBUSS':
            return {...state,mobuss:action.payload.mobuss}
        default:
            return state
    }
}


export const IntegracaoContextProvider = ({children}) => {

    const [state,dispatch] = useReducer(reducer,initialState);

    function handleGoToMobuss() {
        if(state.mobuss.url){
            window.open(state.mobuss.url)
        }
    }


    function getMobussWithCpf(){
        trackPromise(ticketService.getMobuss(guService.getLocalState('CPF').replaceAll(',',"").replace('-',"")))
        .then(response => {
            if(response.status === 200 && response.data.length){
                dispatch({type:'SET_MOBUSS',payload:{mobuss:response.data.find(item => item.servico === 'Mobuss')}})
            }
        }).catch(err => {
            console.log('erro: ',err)
        })
        
    }

    return <IntegracaoContext.Provider value={{...state,getMobussWithCpf,handleGoToMobuss}}>
        {children}
    </IntegracaoContext.Provider>
}