import guService from './GuService';
import crm from './crm';

const empreendimentoService = {
	buscarEmpreendimentos:()=>{
		return crm.get('/empreendimento');
	},
	buscarLocalidades:()=>{
		return crm.get('/empreendimento/localidades');
	},
	buscarEquipes:(idEmpreendimento) =>{
		let promisseEquipesPdv = crm.get('/empreendimento/' + idEmpreendimento + '/equipe/P');
		let promisseEquipesAtendimento = crm.get('/empreendimento/' + idEmpreendimento + '/equipe/A');

		return Promise.all([promisseEquipesPdv, promisseEquipesAtendimento])
	},
	buscaMembrosEquipes: (empreendimento, equipe) => {
		return crm.get('empreendimento/'+empreendimento+'/equipe/'+equipe+'/usuarios');
	},
	buscarEmpreendimentoPorId: (idEmpreendimento) => crm.get(`/empreendimento/${idEmpreendimento}`, { headers: { Authorization: guService.getLocalState("token") } }),
	buscarCondominioPorCNPJ: (params=null) => crm.get(`/empreendimento/condominio`, { 
		headers: { Authorization: guService.getLocalState("token") },
		params
	})
};

export default empreendimentoService;