import crm from "./crm";
import portal from "./portal";
import guService from "./GuService";
const usuarioService = {
  store: (dados) => {
    let uri = "/usuario/atualizar-dados";
    return crm["put"].call(crm, uri, { dados });
  },
  getContacts: (cpf, slug) => {
    let uri = `${slug}/usuario/buscar-telefones/${cpf}`;
    return portal["get"].call(portal, uri);
  },
  getCode: (idContato, slug, cpf, tipoContato, sindico) => {
    let uri = `${slug}/usuario/gerar-codigo/${idContato}`;
    return portal.get(uri, { params: { cpf, tipoContato, sindico } });
  },
  postAutenticate: (idContato, slug, dados) => {
    let uri = `${slug}/usuario/auth-codigo/${idContato}`;
    return portal["post"].call(portal, uri, dados);
  },
  loginCliente: (dados, slug) => {
    let uri = slug + "/usuario/auth";
    return portal["post"].call(portal, uri, {
      cpf: dados.cpf,
      senha: dados.senha,
    });
  },
  guLogin: (token, id, slug) => {
    let uri = slug + "/usuario/gu-login";
    return portal["post"].call(portal, uri, {
      token: token,
      usuario: id,
    });
  },


  registerLogAccess: () => {
    let slug = guService.getLocalState('slug')
    let uri =  `${slug}/usuario/log-acesso`;
    const payload = {
      infoUser:{
        processo:guService.getLocalState('Processo'),
        cpf:guService.getLocalState('CPF'),
        id_usuario:guService.getLocalState('idUsuario')
      
      }
    }

    return portal["post"].call(portal,uri,payload)


  }
};

export default usuarioService;
