import React, { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import './index.css';
import formatDommus from "../../helpers/format";
import DommusToggle from "../../components/DommusToggle";

export default function CardAgendados({ agendados }) {
    
    function formataHora(hora){
        if(typeof(hora) == 'string'){
            return String(hora.substring(0, 5));
        }else{
            return hora;
        }
    }
    return (
        <>
            <br></br>
            <DommusToggle title="Histórico de Agendamentos" className="toggle-agendados" toggle={true} >
                <div className="agendados-div">
                    <Row>
                        <Col>
                            {agendados.map(agendado => {
                                return (
                                    <Fragment key={agendado.id}>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>{agendado.tipo}</Card.Title>
                                                <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                                                <Row>
                                                    <Col>
                                                        <Card.Text>
                                                            Data: {formatDommus.formatarParaDataBr(agendado.data)} <br />
                                                            Horário: {formataHora(agendado.horario_inicio) + " - " + formataHora(agendado.horario_termino)} <br />
                                                            Realizado: {agendado.realizado == 'S' ? "Sim" : "Não"} <br />
                                                            Data do Agendamento: {formatDommus.formatarParaDataBr(agendado.cadastrado_em)} <br />
                                                            {agendado.recomendacoes && <> Recomendações: {agendado.recomendacoes} <br /> </>}
                                                        </Card.Text>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                    </Card>
                               </Fragment>)
                            })}
                        </Col>
                    </Row>
                </div>
            </DommusToggle>
        </>

    );
};

