import React, { Component } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Icon } from '@iconify/react';
import './input.css';
// npm install --save-dev @iconify-icons/mdi
import paperclipIcon from '@iconify-icons/mdi/paperclip';
// npm install --save-dev @iconify-icons/ant-design;
import sendOutlined from '@iconify-icons/ant-design/send-outlined';

export default class Entrada extends Component {
    onChange(e) {
        this.setState({ text: e.target.value });
    }
    onSubmit(e) {
        e.preventDefault();
        this.setState({ text: "" });
        this.props.onSendMessage(this.state.text);
    }
    state = {
        text: ""
    }
    render() {
        return (
            <div id="Input">
                <Form onSubmit={e => this.onSubmit(e)}>
                    <InputGroup>
                        {/* <InputGroup.Prepend>
                            <Form.Label htmlFor="file-upload" className="custom-file-upload">
                                <Icon icon={paperclipIcon} color="white" />
                            </Form.Label>
                            <input id="file-upload" type="file" />
                        </InputGroup.Prepend> */}
                        <Form.Control
                            className="inputMensagem"
                            aria-label="Mensagens"
                            onChange={e => this.onChange(e)}
                            value={this.state.text}
                            type="text"
                            placeholder="Digite aqui sua mensagem"
                            autoFocus={true}
                        />
                        <InputGroup.Append className="enviarMensagem">
                            <Button className="btnEnviarMensagem" onClick={e => this.onSubmit(e)}><Icon icon={sendOutlined} color="white" /></Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Form>
            </div>
        );
    }
}