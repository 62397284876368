import React, {useState, useCallback} from "react";
import {Button, InputGroup} from "react-bootstrap";
import {Icon} from "@iconify/react";
import "./input.css";
import sendOutlined from "@iconify-icons/ant-design/send-outlined";
import {faUpload} from "@fortawesome/free-solid-svg-icons";
import ModalDommus from "../../../../../components/Modal";
import {useDropzone} from 'react-dropzone'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

export default function Entrada({onSendMessage}) {
  const [mensagemInput, setMensagemInput] = useState("");
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const onSubmit = () => {
    onSendMessage(mensagemInput);
    setMensagemInput("");
  };

  function AcceptedType(fileName)
  {
      const validExtension = ".pdf, .jpg , .jpeg, .png";
      let extension = fileName.split('.').pop();

      return validExtension.includes(extension);
  }

  const onDrop = useCallback((acceptedFile) => {
      const reader = new FileReader()
      let fileSize = acceptedFile[0].size / 1024 / 1024;

      if ( ! AcceptedType(acceptedFile[0].name) )
      {
            Swal.fire({
              title: 'Arquivo não Permitido',
              text: "Arquivo não permitido, arquivos permitidos: PDF, JPG, JPEG, PNG.",
              icon: 'warning',
              showCloseButton: true,
              focusConfirm: false,
              confirmButtonText: 'OK ',
              customClass: {
                confirmButton: 'btn btn-primary mr-3',
              },
              buttonsStyling: false,
            })
            return false;
      }

      if ( fileSize >= 15 )
      {
            Swal.fire({
              title: 'Tamanho máximo de arquivo atingido',
              text: "Tamanho máximo de arquivo é 15 MB.",
              icon: 'warning',
              showCloseButton: true,
              focusConfirm: false,
              confirmButtonText: 'OK ',
              customClass: {
                confirmButton: 'btn btn-primary mr-3',
              },
              buttonsStyling: false,
            })

            return false;
      }
      else
      {
            reader.onload = () => {
              let dadosArquivo = {
                conteudo: reader.result.split(/base64,/)[1],
                nome: acceptedFile[0].name,
                tipo: acceptedFile[0].type,
                size: fileSize,
              };
              onSendMessage(dadosArquivo);
              setIsUploadModalOpen(false);
            }
            reader.readAsDataURL(acceptedFile[0])
      }

  }, [])
  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return <>
    <div id="Input">
      <InputGroup>
            <InputGroup.Prepend>
              <Button onClick={() => setIsUploadModalOpen(true)}>
                <FontAwesomeIcon color="#fff"icon={faUpload}data-tip="Carregar Arquivo"/>
              </Button>
            </InputGroup.Prepend>
            <textarea className="txtArea-chat" onChange={(event) => setMensagemInput(event.target.value)} value={mensagemInput}></textarea>
            <InputGroup.Append className="enviarMensagem">
                <Button onClick={onSubmit}>
                    <Icon icon={sendOutlined} color="white"/>
                </Button>
            </InputGroup.Append>
      </InputGroup>
    </div>

    <ModalDommus open={isUploadModalOpen}
                    close={() => {
                        setIsUploadModalOpen(false);
                    }}
                    titulo="Carregar arquivo"
                    size={"md"}>
            <div {...getRootProps()} className="upload-area">
                <input {...getInputProps()} />
                <p>Arraste ou clique para carregar um arquivo</p>
            </div>
    </ModalDommus>
  </>
}
