import crm from './crm';

const EmailService = {
	enviarExtratoFinanceiro:(idProcesso, email)=>{
		let dados = {
			"id_processo": idProcesso,
			email
		};
		return crm.post('/email/enviar-extrato-financeiro', dados);
	},
};

export default EmailService;