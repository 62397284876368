/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../LoginCliente/index.css";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { Row } from 'react-bootstrap'
import Swal from 'sweetalert2'

export default function TokenValidation({
  logoCliente,
  corPrimaria,
  corSecundaria,
  corFundo,
  imagemFundo,
  slug,
  setCode,
  code,
  loadingDados,
  handleSubmit,
  handleSubmitContact
}) {
  const [tempoReenvio, setTempoReenvio] = useState(180);
  const [contador, setContador] = useState({ minutos: 3, segundos: '00' });
  const atualizarContador = (tempo) => {
    const minutos = Math.floor(tempo / 60);
    const segundos = tempo % 60;
    setContador({ 
      minutos, 
      segundos: segundos < 10 ? `0${segundos}` : segundos 
    });
  };

  useEffect(() => {
    const intervaloContador = setInterval(() => {
      if (tempoReenvio > 0) {
        setTempoReenvio(tempoReenvio - 1);
        atualizarContador(tempoReenvio - 1);
      } else {
        clearInterval(intervaloContador);
      }
    }, 1000);
    return () => clearInterval(intervaloContador);
  }, [tempoReenvio]);

  return (
    <div className="login">
      <div className="container-login-cliente">
        <div className="logo-dommus mobile">
          <img src={logoCliente} alt={slug} />
        </div>
        <div className="content-login-cliente">
          <p>
            <span style={{ fontSize: 16 }}>
              Insira o código enviado para o seu contato selecionado.{" "}
            </span>
          </p>

          <form onSubmit={handleSubmit}>
            <div className="wrapper-radios">
              <div
                className="box-radio"
                style={{
                  border: "1px solid #ccc",
                  backgroundColor: "#f3f3f3",
                  padding: 10,
                  borderRadius: 5,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>
                  <FontAwesomeIcon style={{ fontSize: 22 }} icon={faLanguage} />
                  <label
                    style={{ fontSize: 22, marginLeft: 8 }}
                    htmlFor="contact"
                  >
                    Digite o código
                  </label>
                </div>
                <br />
                {`Digite o código recebido em seu contato selecionado:`}
                <input
                  type="text"
                  id="token"
                  style={{ borderRadius: 10, marginTop: 5 }}
                  placeholder="Digite..."
                  value={code}
                  onChange={(event) => setCode(event.target.value)}
                />
              </div>
            </div>
            <Row className="justify-content-end px-3">
              {tempoReenvio && (
                <span className="text-muted mt-1 mb-3">Reenvio disponível em: <span id="contador">{`0${contador.minutos}:${contador.segundos}`}</span></span>
              ) || (
                <a 
                  href="#" 
                  onClick={(e) => {
                    handleSubmitContact(e)
                    Swal.fire('Sucesso!', 'Código reenviado com sucesso.', 'success')
                    setContador({ 
                      minutos: 5,
                      segundos: '00'
                    })
                    setTempoReenvio(300)
                  }} 
                  className="texto-sublinhado mb-3 mt-1"
                >
                  Não recebi o código
                </a>
              )}
            </Row>
            <div className="buttons">
              <button
                className="btn btn-submit"
                type="submit"
                style={{
                  backgroundColor: corPrimaria,
                }}
              >
                {loadingDados ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  "Acessar Portal"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
