const ValidaTipoArquivo = {
  verificaTipoArquivo: (file) => {
    if(file){
      const nameSplitado = file.name?.split('.')
      const tipoSplitado = file.type?.split('/')
      if(nameSplitado.slice(-1)[0] === tipoSplitado.slice(-1)[0]){
        return true
      }else{
        return false
      }
    }else{
      return false
    }
    
  }
}

export default ValidaTipoArquivo;