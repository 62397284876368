import "./index.css";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import ModalDommus from "../Modal";
import Chamado from "../../pages/MeuImovel/MeusChamados/Chamado/chamado";

function DommusToggle(props) {
  const [toggle, setToggle] = useState(props.toggle || false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setToggle(props.toggle);
  }, [props.toggle]);

  const headerClass = toggle ? "dommus-toggle" : "dommus-toggle arrow";
  return (
    <>
      <header className={headerClass}>
        <div>{props.title}</div>
        <div>{props.buttonMeio}</div>
        <div>{props.button}</div>
      </header>
      <div style={{ display: toggle ? "block" : "none" }}>
        {toggle}
        {props.children}
      </div>

      <ModalDommus
        open={openModal}
        className="modalchamado"
        size={"hg"}
        close={() => {
          setOpenModal(false);
        }}
        titulo={`Novo Chamado`}
      >
        <Chamado id={null} />
      </ModalDommus>
    </>
  );
}

export default DommusToggle;
