import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Card,
  CardDeck,
  ProgressBar,
  Accordion,
} from "react-bootstrap";
import Lista from "./Lista";
import { Icon, InlineIcon } from "@iconify/react";
import arrowLeftSquare from "@iconify-icons/bi/arrow-left-square";
import DommusToggle from "../../../components/DommusToggle";
import bxsUserDetail from "@iconify-icons/bx/bxs-user-detail";
import "./index.css";
import guAPI from "../../../services/GuService";
import processoService from "../../../services/ProcessoService";
import { trackPromise } from "react-promise-tracker";

export default function DocumentosAssinados({}) {
  const [show, setShow] = useState(false);
  const [newTitulo, setNewTitulo] = useState("");
  const [arquivos, setArquivos] = useState([]);

  return (
    <>
      <Container fluid>
        <Row>
          <Card className="cardDocumentacao">
            <Card.Body className="cardBoddy">
              <Link to="./">
                <Icon
                  className="iconCadastro"
                  style={{ backgroundColor: guAPI.getLocalState("cor-menu-1") }}
                  icon={bxsUserDetail}
                />
              </Link>
              <Card.Header
                className="cardHeader"
                style={{ backgroundColor: guAPI.getLocalState("cor-menu-1") }}
              >
                <div className="vazio"></div>
                <div className="tituloCadastro">Documentos Assinados</div>
                <Link to="./">
                  <Icon className="iconVoltar" icon={arrowLeftSquare} />{" "}
                </Link>
              </Card.Header>
              <div className="direita_doc">
                <DommusToggle title="Documentos" toggle={true}>
                  <CardDeck>
                    {arquivos.map((item) => (
                      <Lista key={item.id_upload} value={item} />
                    ))}
                  </CardDeck>
                </DommusToggle>
              </div>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </>
  );
}
