import crm from './crm';
import {trackPromise} from "react-promise-tracker";
import swal from "sweetalert";

const DistratoService = {
    buscarMotivos: () => {
        return crm.get('/distrato/motivos');
    },
    buscarPermissao: (processo) => {
        return crm.get('/distrato/'+processo);
    },
    solicitarDistrato: (processo, motivo, descricao, arquivoCarregado) => {
        let uri = '/distrato/solicitar';
        let method = 'post';
        return crm[method].call(crm, uri, {processo, motivo, descricao, arquivo: arquivoCarregado});
    },
};

export default DistratoService;
