import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import { RespostaItem } from "./RespostaItem";
import { useFormContext } from "react-hook-form";
import { AssistenciaTecnicaContext } from "../../../../contexts/AssistenciaTecnicaContext";

export function DetalhePerguntaItem({ idDetalhamento, pergunta }) {
    const [detalhamentosSelecionado, setDetalhamentosSelecionado] = useState([]);
    const {alterarListaDetalhamento} = useContext(AssistenciaTecnicaContext);
    const methods = useFormContext();
    const fieldName = `d-${idDetalhamento}.p-${pergunta?.id_assistencia_pergunta}`;
    const fieldWatch = methods.watch(fieldName);

    useEffect(()=>{
        let listaAdd = [];
        if(pergunta.tipo !== 'TA'){
          let itemsResposta = null;
          if(fieldWatch && !detalhamentosSelecionado.includes(parseInt(fieldWatch ?? "0"))){
              itemsResposta = pergunta.respostas.find((item) => {
                  return item.id_assistencia_resposta === parseInt(fieldWatch ?? "0");
              })
          }
          if (itemsResposta) {
              listaAdd = itemsResposta?.referencia_detalhamento;
          }
        }else{
          listaAdd.push(fieldWatch);
        }
        alterarListaDetalhamento(listaAdd, detalhamentosSelecionado)
        setDetalhamentosSelecionado(listaAdd);
    },[fieldWatch])


    return (
        <div className="pergunta-item">
            <div className="enunciado">
                {pergunta?.enunciado}
                <span className="subtitle">
                    {pergunta.obrigatoriedade === "S" ? (['TA', 'UP'].includes(pergunta.tipo) ? "Preenchimento Obrigatório" : (pergunta.tipo === "SR" ? "Escolha uma opção" : "Escolha uma ou mais")) : ""}
                </span>
            </div>
            <div className="resposta-section">
                {pergunta?.respostas.map((resposta, index) => {
                    let values = methods.getValues(fieldName);
                    let idResposta = resposta?.id_assistencia_resposta?.toString();
                    let isSelected = values === idResposta || (Array.isArray(values) && values?.includes(idResposta));
                    return (
                        <RespostaItem
                            key={index}
                            fieldName={fieldName}
                            perguntaTipo={pergunta?.tipo}
                            resposta={resposta}
                            selected={isSelected}
                        />
                    );
                })}
            </div>
        </div>
    );
}
