import crm from './crm';
import guService from "./GuService";

const menuService = {
	buscarMenusVisiveis:()=>{
		const tipoUsuario = guService.getLocalState('usuarioTipo')
		if(tipoUsuario == menuService.TIPO_USUARIO_SINDICO_CONDOMINIO) {
			return crm.get(`/menu/monta-menu/${guService.getLocalState('IdEmpreendimento')}`, { params: { sindico: true } })
		}
		return crm.get('/menu/monta-menu/'+guService.getLocalState('Processo'));
	},
	TIPO_USUARIO_SINDICO_CONDOMINIO: 'SC'
};

export default menuService;