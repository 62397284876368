import crm from "./crm";
import { trackPromise } from "react-promise-tracker";
import swal from "sweetalert";

const ProcessoService = {
  buscarProcessosCpf: (cpf) => {
    return trackPromise(crm.get("/processo/cpf/" + cpf));
  },
  buscarDadosProcesso: (id) => {
    return crm.get("/processo/" + id);
  },
  buscarFluxoPagamento: (id, filtros=null) => {
    return crm.get("/processo/" + id + "/pagamento", { params: filtros });
  },
  buscarProponenteCpf: (id, cpf) => {
    return trackPromise(crm.get("/processo/" + id + "/proponente/" + cpf));
  },
  buscarBoleto: (id, idParcela, chavesExternas, padraoCobranca = null) => {
    return trackPromise(crm.get("/processo/" + id + "/boleto/" + idParcela, { params: { chavesExternasPortal: chavesExternas, padraoCobranca:padraoCobranca } }));
  },
  buscarDocumentoImpressao: (id, idReferencia) => {
    return trackPromise(
      crm.get("/processo/" + id + "/impressao/" + idReferencia)
    );
  },  
};

export default ProcessoService;
