import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import './historico.css';

const listaHistorico = [
    {
        titulo: "Histórico 001",
        descricao: "Descrição do Histórico",
        data: "dd/mm/aa 00:00h",
    },
    {
        titulo: "Histórico 002",
        descricao: "Descrição do Histórico",
        data: "dd/mm/aa 00:00h",
    },
    {
        titulo: "Histórico 003",
        descricao: "Descrição do Histórico",
        data: "dd/mm/aa 00:00h",
    }
]

export const ItemTimeLine = ({ titulo, descricao, data, index }) => {
    return (
        <TimelineItem className="item">
            <TimelineSeparator>
                <TimelineDot className="dotHistorico" />
                {listaHistorico.length !== (index + 1) &&
                    <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
                <Paper className="paper" >
                    <h6>{titulo}</h6>
                    {descricao}
                    <Typography className="labelHora">
                        {data}
                    </Typography>
                </Paper>
            </TimelineContent>
        </TimelineItem>
    );
};


export default function Historico() {

    return (
        <div className="historico">

            <Timeline align="right">
                {listaHistorico.map((historico, index) =>
                    <div key={index}>
                        <ItemTimeLine titulo={historico.titulo} descricao={historico.descricao} data={historico.data} index={index} />
                    </div>

                )}
            </Timeline>
        </div>
    );
}