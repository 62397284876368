import React, { useState, useEffect, useMemo } from "react";
import { Nav, Tab, Col, Alert } from "react-bootstrap";
import "./index.css";
import Mensagens from "./Chat/mensagem";
import Input from "./Chat/input";
import Coluna_direita from "./ColunaDireita/coluna_direita";
import Coluna_esquerda from "./ColunaEsquerda/coluna_esquerda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faCommentAlt, faDoorClosed, faSync } from "@fortawesome/free-solid-svg-icons";
import DommusLoading from "../../../../components/DommusLoading/DommusLoading";
import ticketService from "../../../../services/TicketService";
import usuarioService from "../../../../services/UsuarioService";
import swal from "sweetalert";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import { erroSwal, sucessoSwal } from "../../../../helpers/DommusSwal";
import { isDesktop } from "react-device-detect";
import moment from "moment";

export default function Chamado({ chamado }) {
  const [leftOpen, setLeftOpen] = useState(true);
  const [rightOpen, setRightOpen] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [mensagens, setMensagens] = useState([]);
  

  let leftOpenClass = leftOpen ? "open" : "closed";
  let rightOpenClass = rightOpen ? "open" : "closed";

  const habilitarReabrirChamado = useMemo(()=>{
    if(chamado?.conclusao && chamado?.conclusao?.data_conclusao && chamado?.status?.tipo === 'F'){
      if(chamado?.motivo?.dias_reabertura_chamado){
        if(moment(chamado?.conclusao?.data_conclusao).days(chamado?.motivo?.dias_reabertura_chamado) < moment()){
          return false;
        }else{
          return true;
        }        
      }else{
        return false;
      }
    }

    return true;
  },[chamado])

  function onSendMessage(message) {
    if(!message) return;
    let dados = {}

    if (message.conteudo) {
      dados = {
        tipo: "A",
        mensagem: "Arquivo carregado",
        dados_arquivo: message,
        size: message.size
      };
    } else {
      dados = {
        mensagem: message.replace(/<[^>]*>?/gm, ""),
        tipo: "A",
      };
    }
    
    if(chamado?.status?.tipo == "F") {
      Swal.fire({
        title: 'Reabertura de Chamado',
        text: 'Ao encaminhar uma nova mensagem, você estará reabrindo o chamado. Deseja continuar?',
        icon: 'warning',
        showDenyButton: true,
        confirmButtonText: 'Sim, continuar.',
        denyButtonText: 'Não, cancelar.'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const stmt = await reabrirChamado(chamado)
          if(stmt) salvarMensagem(chamado, dados)
        } else {
          Swal.fire({
            title: 'Cancelado',
            text: 'Envio da mensagem cancelada',
            icon: 'info'
          })
        }
      })
    } else {
      salvarMensagem(chamado, dados)
    }
  }

  async function reabrirChamado(chamado) {
    return await ticketService.update(chamado.id_ticket, {reabertura: true})
      .then(() => {
        sucessoSwal('Chamado reaberto com sucesso.')
        return true
      })
      .catch(erro => {
        erroSwal(`Houve um erro ao tentar reabrir o chamado. ${erro?.response?.data?.message ?? erro}`)
        return false
      })
  }

  async function salvarMensagem(chamado, dados) {
    await trackPromise(
      ticketService.storeMensagem(chamado.id_ticket, dados).then(
        (response) => {
          Swal.fire({
            title: 'Mensagem Enviada',
            text: "Mensagem Enviada Com Sucesso",
            icon: 'success',
            showCloseButton: true,
            focusConfirm: false,
            confirmButtonText: 'OK ',
            customClass: {
              confirmButton: 'btn btn-primary mr-3',
            },
            buttonsStyling: false,
          })
          carregaMensagens();
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao enviar a mensagem: " + error,
            icon: "error",
          });
        }
      )
    );
  }

  useEffect(() => {
    carregaMensagens();
    var myinterval = setInterval(function () {
      carregaMensagens();
    }, 10000);
    return function cleanup() {
      clearInterval(myinterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function carregaMensagens() {
    ticketService.getMensagemByTicket(chamado.id_ticket).then(
      (response) => {
        let itensListaMensagens = [];

        Object.values(response.data).forEach((item) => {
          itensListaMensagens.push({
            text: item.mensagem,
            tipo: item.tipo,
            url: item.url,
            tipo_upload: item.tipo_upload,
            id_usuario: item?.usuario?.id_usuario,
            nome: item?.usuario?.nome,
            criado_em: item?.criado_em,
            arquivos: item?.arquivos,
          });
        });
        setMensagens(itensListaMensagens);
      },
      (error) => {
        swal(
          "Ooops...",
          "Ocorreu um erro ao buscar as mensagens: " + error.data,
          "error"
        );
      }
    );
  }

  const componenteDesktop = (
      <>
        <div id="chamado">
          <div id="left" className={leftOpenClass}>
            <div className={`sidebar ${leftOpenClass}`}>
              <div className="content">
                <Coluna_esquerda ticket={chamado} />
              </div>
            </div>
          </div>

          <div id="main">
            <Tab.Container id="left-tabs" defaultActiveKey="mensagens">
              <div className="content">
                <div className="header">
                  <Nav variant="pills" className="chat">
                    <Col>
                      <Nav.Item>
                        <Nav.Link eventKey="mensagens">Mensagens</Nav.Link>
                      </Nav.Item>
                    </Col>
                  </Nav>
                </div>
                <Tab.Content>
                  <Tab.Pane eventKey="mensagens">
                  <div className="listaMensagens">
                      <Mensagens
                        mensagens={mensagens}
                        usuario={chamado.id_usuario_solicitante}
                      />
                      {habilitarReabrirChamado ?
                        <Input onSendMessage={onSendMessage.bind(this)} />
                        : <Alert variant="info">Este ticket foi CONCLUÍDO</Alert>
                      }
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </div>
      </>
    )

    const componenteMobile = (
      <div className="mobile-view">
        <Tab.Container defaultActiveKey="chamado">
          <div className="content-mobile">
            <Tab.Content>
                <Tab.Pane eventKey="chamado">
                  <div className="content mobile">
                    <Coluna_esquerda ticket={chamado} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="mensagens">
                  <div className="content mobile">
                    <div className="listaMensagens mobile">
                      <Mensagens
                        mensagens={mensagens}
                        usuario={chamado.id_usuario_solicitante}
                      />
                      {habilitarReabrirChamado ?
                        <Input onSendMessage={onSendMessage.bind(this)} />
                        : <Alert variant="info">Este ticket foi CONCLUÍDO</Alert>
                      }
                    </div>
                  </div>
                </Tab.Pane>                
            </Tab.Content>
            <div className="header-selection-mobile">
                <Nav variant="pills" className="chat">
                    <Col md={6}>
                        <Nav.Item>
                            <Nav.Link eventKey="chamado">
                                <FontAwesomeIcon icon={faClipboardList}/>
                                <span>Chamado</span>
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                    <Col md={6}>
                        <Nav.Item>
                            <Nav.Link eventKey="mensagens">
                                <FontAwesomeIcon icon={faCommentAlt}/>
                                <span>Mensagens</span>
                            </Nav.Link>
                        </Nav.Item>
                    </Col>                  
                </Nav>
            </div>
          </div>
        </Tab.Container>
      </div>
    )

  return ( isDesktop ? componenteDesktop : componenteMobile);
}
