import React, { createContext } from "react";
import { AssistenciaTecnicaProvider } from "./AssistenciaTecnicaContext";
import { ConfiguracoesDeskProvider } from "./ConfiguracoesDeskContext";
import { ProcessoProvider } from "./ProcessoContext";

export const AppContext = createContext({});

export function AppContextProvider({ children }) {
    return (
        <AppContext.Provider value={{}}>
            <AssistenciaTecnicaProvider>
                <ConfiguracoesDeskProvider>
                    <ProcessoProvider>
                        {children}
                    </ProcessoProvider>
                </ConfiguracoesDeskProvider>
            </AssistenciaTecnicaProvider>
        </AppContext.Provider>
    );
}
