import React, { useEffect } from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import '../index.css';
import Parser from 'html-react-parser';

export default function ModalHtml({ cor , evento, handleModalHtmlEvento}) {
    let butoesHtml = null;
    useEffect(() => {
        butoesHtml  = document.querySelectorAll('button[data-acao="handleModalHtmlEvento"]');
        butoesHtml.forEach(butao => {
        butao.addEventListener('click', handleModalHtmlEvento)
     });
    }, [])
    
    return (
        <>
            <div className="tituloComunicadoHtml" style={{ backgroundColor: cor }}>
                <h5>{evento?.nome}</h5>
            </div>
            <div className='conteiner-dinamico'>
                <div className='row-modal-html'>
                    <div className='col-modal-html'>
                        {Parser(typeof (evento?.descricao) == 'string' ? evento?.descricao : '')}
                    </div>
                </div>
            </div>
        </>
    )
}

