import React from "react";
import "./App.css";
import "./assets/poppins/poppins.css";
import "dotenv";

import "bootstrap/dist/css/bootstrap.min.css";

import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import DommusPromiseLoading from "./components/DommusLoading/DommusPromiseLoading";
import { AppContextProvider } from "./contexts/appContext";
import { IntegracaoContextProvider } from "./contexts/IntegracaoContext";

function App() {
  return (
    <BrowserRouter>
      <DommusPromiseLoading />
      <AppContextProvider>
          <IntegracaoContextProvider>
                <Routes />
          </IntegracaoContextProvider>
      </AppContextProvider>
    </BrowserRouter>
  );
}

export default App;
