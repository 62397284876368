import Swal from "sweetalert2"

export function sucessoSwal(message="") {
    return Swal.fire({
        title: 'Sucesso!',
        text: message,
        icon: 'success'
    })
}

export function erroSwal(message="") {
    return Swal.fire({
        title: 'Ooops...',
        text: message,
        icon: 'error'
    })
}