/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "../../LoginCliente/index.css";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export default function ContatoEscolhido({
  logoCliente,
  corPrimaria,
  slug,
  setContact,
  contact,
  loadingDados,
  handleSubmit,
  listaContatos,
  tipoContato,
  setTipoContato,
  habilitaValidacaoWhatsapp,
  setCnpjSindico,
}) {
  const [listaTelefones, setListaTelefones] = useState([]);
  const [listaEmail, setListaEmail] = useState([]);

  useEffect(() => {
    let emailArr = [];
    let telefoneArr = [];
    if (listaContatos) {
      listaContatos.map((item) => {
        if (item.tipo === "email") {
          emailArr.push(item);
        } else {
          telefoneArr.push(item);
        }
      });
    }
    setListaEmail(emailArr);
    setListaTelefones(telefoneArr);
  }, []);

  function buscaClassName(lista) {
    if (lista && lista.length <= 1) {
      lista.map((item) => {
        if (item.id === null) {
          return `box-radio-none`;
        } else {
          return `box-radio`;
        }
      });
    } else {
      return "box-radio";
    }
  }

  function handleResolveContatoSindico(idContato){
    let contatoSelecionado = listaContatos.find((contato)=> Number(contato.id) === Number(idContato));
    setCnpjSindico(contatoSelecionado?.sindico ?? false);
  }

  return (
    <div className="login">
      <div className="container-login-cliente">
        <div className="logo-dommus mobile">
          <img src={logoCliente} alt={slug} />
        </div>
        <div className="content-login-cliente">
          <p>
            <span style={{ fontSize: 16 }}>
              Será encaminhado um código de acesso para o seu contato
              selecionado. Você deverá informar esse codigo na proxima tela.
            </span>
          </p>

          <form onSubmit={handleSubmit}>
            <div className="wrapper-radios">
              {listaTelefones.length >= 1 && (<>
                <div
                  onClick={() => {
                    setTipoContato("SMS");
                  }}
                  className={`${buscaClassName(listaEmail) != undefined && buscaClassName(listaTelefones) || "box-radio"}`}
                  style={
                    tipoContato === "SMS"
                      ? {
                          border: "1px solid #ccc",
                          backgroundColor: "#f3f3f3",
                          padding: 20,
                          borderRadius: 5,
                        }
                      : {}
                  }
                >
                  <span>
                    <FontAwesomeIcon icon={faPhoneAlt} /> Por SMS
                  </span>
                  <br />
                  {`O código deverá ser enviado para:`}
                  {listaTelefones.length >= 1 ? (
                    <select
                      name="select"
                      value={tipoContato == "SMS" ? contact : ''}
                      onChange={(event) => {
                        setContact(event.target.value)
                        handleResolveContatoSindico(event.target.value)
                      }}
                    >
                      <option>Selecione...</option>
                      {listaTelefones.map((telefone) => (
                        <option
                          disabled={telefone.id === null}
                          key={telefone.id}
                          value={telefone.id}     
                        >
                          {telefone.valor}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <></>
                  )}
                </div>
                {habilitaValidacaoWhatsapp && (
                  <div 
                    onClick={() => setTipoContato("WHATSAPP")} 
                    className={`${buscaClassName(listaEmail) != undefined && buscaClassName(listaTelefones) || "box-radio"}`}
                    style={tipoContato == "WHATSAPP" ? {
                      border: "1px solid #ccc",
                      backgroundColor: "#f3f3f3",
                      padding: 20,
                      borderRadius: 5,
                    } : {}}
                  >
                    <span>
                      <FontAwesomeIcon icon={faWhatsapp} /> Por WHATSAPP
                    </span>
                    <br />
                    O código deverá ser enviado para:
                    <select name="select" value={tipoContato == "WHATSAPP" ? contact : ''} onChange={(event) => setContact(event.target.value)}>
                      <option>Selecione...</option>
                      {listaTelefones.map((telefone) => <option disabled={telefone.id === null} key={telefone.id} value={telefone.id}>{telefone.valor}</option>) || 
                        <></>}
                    </select>
                  </div>
                ) || <></>}
              </>) || <></>}
              {listaEmail.length >= 1 ? (
                <div
                  onClick={() => {
                    setTipoContato("EMAIL");
                  }}
                  className={`${
                    buscaClassName(listaEmail) != undefined
                      ? buscaClassName(listaEmail)
                      : "box-radio"
                  }`}
                  style={
                    tipoContato === "EMAIL"
                      ? {
                          border: "1px solid #ccc",
                          backgroundColor: "#f3f3f3",
                          padding: 20,
                          borderRadius: 5,
                        }
                      : {}
                  }
                >
                  <span>
                    <FontAwesomeIcon icon={faMailBulk} />
                    Por EMAIL
                  </span>
                  <br />
                  {`O código deverá ser enviado para:`}

                  {listaEmail.length >= 1 ? (
                    <select
                      name="select"
                      value={contact}
                      onChange={(event) => {
                        setContact(event.target.value)
                        handleResolveContatoSindico(event.target.value)
                      }}
                    >
                      <option>Selecione...</option>
                      {listaEmail.map((email) => (
                        <option
                          disabled={email.id === null}
                          key={email.id}
                          value={email.id}
                        >
                          {email.valor}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="buttons">
              <button
                className="btn btn-submit"
                type="submit"
                style={{
                  backgroundColor: corPrimaria,
                }}
              >
                {loadingDados ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  "Enviar Código"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
