import React from "react";
import "./index.css";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default function ApresentacaoEmpreendimento({ cor, dados }) {
  return dados.map((item, index) => {
    return (
      <div className="row px-4" style={{ display: "flex" }} key={index}>
        <Col sm={12} lg={4}>
          {item.empreendimento_imagem_apresentacao ? (
            <img
              className="boxImage mb-3"
              src={item.empreendimento_imagem_apresentacao}
              style={{
                width: 350,
                height: 350,
              }}
            />
          ) : (
            <div
              className="boxImage mb-3"
              style={{
                width: 350,
                height: 350,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon icon={faImage} size="3x" />
            </div>
          )}
        </Col>
        <Col>
          <Row>
            <p>
              <h1><b>{item.empreendimento_nome}</b></h1>
            </p>
          </Row>
          <Row>
            <b>Total de Unidades: </b>{" "}
            <span style={{ marginLeft: 10 }}>480</span>
          </Row>
          {item.previsao_entrega && (
            <Row>
              <b>Previsão de Entrega: </b>{" "}
              <span style={{ marginLeft: 10 }}>{moment(item.previsao_entrega).format('DD/MM/YYYY')}</span>
            </Row>
          ) || <></>}
          <Row>
            <b>Endereço: </b>
            <span style={{ marginLeft: 10 }}>
              {item.empreendimento_endereco +
                ", " +
                item.empreendimento_numero +
                ", " +
                item.empreendimento_bairro +
                ", " +
                item.empreendimento_cidade +
                "/" +
                item.empreendimento_uf +
                " - "}
              <b>CEP: </b>
              {item.empreendimento_cep}
            </span>
          </Row>
          <Row>
            <h4 className="mt-3">Lazer completo sem precisar sair de casa</h4>
            Piscinas adulto e infantil, espaço gourmet com churrasqueira, campo gramado, academia, playground, street ball e muito mais.
            <h4 className="mt-3">Viva em um bairro completo para o seu dia a dia</h4>
            A região de Justinópolis tem a melhor infraestrutura comercial do vetor norte. Tem shoppings, estação do Move, farmácias, bancos, comercio variado e muito mais...
            <h4 className="mt-3">Acesso fácil para você chegar com tranquilidade</h4>
            Acesso rápido pelas avenidas Padre Pedro Pinto, Vilarinho e Cristiano Machado. Inúmeras linhas de ônibus atendem a região, inclusive com uma estação do Move perto.
            <h4 className="mt-3">Segurança e conforto para você e sua família</h4>
            Condomínio seguro com guarita para porteiro 24 horas, ótima estrutura de lazer com espaços diferenciados para crianças, adultos e para terceira idade.
          </Row>
        </Col>
      </div>
    );
  });
}
