import React, { createContext, useEffect, useState } from "react";
import configuracaoService from "../services/ConfiguracaoService";
import guService from "../services/GuService";
import { trackPromise } from "react-promise-tracker";
import { erroSwal } from "../helpers/DommusSwal";

export const ConfiguracoesDeskContext = createContext({});

export function ConfiguracoesDeskProvider({ children }) {
    const [listaConfiguracoes, setListaConfiguracaoes] = useState([]);
    const [listaMapaConfiguracoes, setListaMapaConfiguracoes] = useState([]);

    function buscarConfiguracoesPorChave(chaves) {
        configuracaoService.buscarConfiguracoesDesk(guService.getLocalState("slug"),chaves)
        .then((response) => {
            setListaConfiguracaoes(response?.data?.configs ?? []);
        })
        .catch((error) => {
            erroSwal(
                "Houve um erro ao buscar configurações do Desk." + error
            );
        });
    }

    useEffect(() => {
        const novoMapa = new Map();
        listaConfiguracoes.forEach((campo) => {
            novoMapa.set(campo.chave, campo);
        });
        setListaMapaConfiguracoes(novoMapa);
    }, [listaConfiguracoes]);

    function resolverConfiguracaoChave(chave, padrao = null) {
        let campo = listaMapaConfiguracoes.get(chave);
        return campo ?? padrao;
    }

    return (
        <ConfiguracoesDeskContext.Provider
            value={{
                listaConfiguracoes,
                buscarConfiguracoesPorChave,
                resolverConfiguracaoChave
            }}
        >
            {children}
        </ConfiguracoesDeskContext.Provider>
    );
}
