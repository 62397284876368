import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Card } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import arrowLeftSquare from '@iconify-icons/bi/arrow-left-square';
import DommusToggle from "../../components/DommusToggle";
import './index.css';
import Indicacoes from './Indicacoes';
import chatBubble from '@iconify-icons/cil/chat-bubble';
import guAPI from "../../services/GuService";

export default function Relacionamento() {
    return <>
        <Container fluid>
            <Row>
                <Card className="cardRelacionamentos">
                    <Card.Body className="cadBoddy">
                        <Link to="./" ><Icon className="iconRelacionamento" icon={chatBubble} style={{ backgroundColor: guAPI.getLocalState('cor-menu-5') }} /></Link>
                        <Card.Header className="cardHeader" style={{ backgroundColor: guAPI.getLocalState('cor-menu-5') }}>
                            <Card.Text className="vazio">
                            </Card.Text>
                            <Card.Text className="tituloCadastro" >
                               Relacionamentos
                            </Card.Text>
                            <Link to="./" ><Icon className="iconVoltar" icon={arrowLeftSquare} /> </Link>
                        </Card.Header>
                            <Card.Body >
                                <DommusToggle title="Minhas Indicações" toggle={true}>
                                    <Indicacoes cor={guAPI.getLocalState('cor-menu-5')} />
                                </DommusToggle>
                            </Card.Body>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    </>

}