import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Row } from "react-bootstrap";

export function DommusInputErro({mensagem="Campo Obrigatório!"}) {
    return (
        <Row className="ml-2">
            <FontAwesomeIcon icon={faExclamationTriangle} color="#DC3545" className="mr-2 mt-2"/> <small className="form-text text-danger mt-2">{mensagem}</small>
        </Row>
    )
}