import React, { useState } from "react";
import { Card, Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const ListaItem = ({ onChange, onDelete, value }) => {
  const [file, setFile] = useState("");
  const [dadosArquivo, setDadosArquivo] = useState(value);
  // const [tipo, setTipo] = useState("");

  function handleUpload(event) {
    setFile(event.target.files[0]);
    // var teste = event.target.files[0].type.search("image") >= 0 ? console.log("imagem") : console.log("Não é");
  }

  const ImageThumb = ({ image }) => {
    return (
      <img
        className="img_upload"
        src={URL.createObjectURL(image)}
        alt={image.name}
      />
    );
  };

  function openFile() {
    window.open(value.caminho, "_blank");
  }
  return (
    <>
      <Form>
        <Card className="cardArquivo" style={{ width: "16rem" }}>
          <Card.Header
            data-toggle="tooltip"
            data-placement="top"
            title={dadosArquivo.descricao_documento}
          >
            {dadosArquivo.descricao_documento &&
            dadosArquivo.descricao_documento.length > 25
              ? dadosArquivo.descricao_documento.substr(0, 20) + " ..."
              : dadosArquivo.descricao_documento
              ? dadosArquivo.descricao_documento
              : "Adicionar novo arquivo:"}
          </Card.Header>
          <Card.Body className="card_arquivo_body">
            {file && <ImageThumb image={file} />}
            <FontAwesomeIcon
              icon={faFilePdf}
              style={{ fontSize: "45px" }}
              color="#b30b00"
            />
          </Card.Body>
          <Button
            style={{ marginTop: "5px" }}
            variant="primary"
            onClick={openFile}
          >
            Baixar
          </Button>
          <Button
            style={{ marginTop: "5px", background: "#ececec",
            borderColor: "#ececec",
            color: "#000",
            cursor: "not-allowed" }}
            variant="secondary"
            onClick={openFile}

            disabled
          >
            Atualizar
          </Button>
        </Card>
      </Form>
    </>
  );
};

export default ListaItem;
