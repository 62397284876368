import React from "react";
import "./index.css";
import { DetalhePerguntaItem } from "./PerguntaItem";
import guService from "../../../services/GuService";

export function DetalhamentoItem({ detalhamento, isHidden }) {
    const corSecundaria = guService.getLocalState("cor_secundaria");
    
    return (
        <div className="detalhamento-section" hidden={isHidden}>
            <div className="descricao" style={{ background: corSecundaria }}>
                {detalhamento?.descricao}
            </div>
            <div className="pergunta-section">
                {detalhamento?.perguntas.map((pergunta, index) => {
                    return (
                        <DetalhePerguntaItem
                            key={index}
                            idDetalhamento={
                                detalhamento?.id_assistencia_detalhamento
                            }
                            pergunta={pergunta}
                        />
                    );
                })}
            </div>
        </div>
    );
}
