import React, { useCallback, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";

import "./index.css";
import "react-multi-carousel/lib/styles.css";

export default function Galeria({ photos, onClick, width, height }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  if (photos) {
    return (
      <Carousel
        arrows
        draggable
        keyBoardControl
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        additionalTransfrom={0}
        centerMode={false}
        className=""
        showDots={true}
        responsive={responsive}
        infinite={false}
        containerClass="carousel-container"
        focusOnSelect={false}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-20-px"
        minimumTouchDrag={80}
        sliderClass=""
        // slidesToSlide={1}
        swipeable
      >
        {photos.map((item, index) => {
          return (
            <div
              key={String(index)}
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundImage: `url(${item.src})`,
                backgroundSize: "cover",
                width: width ? width : 300,
                height: height ? height : item.height,
                paddingTop: 10,
                borderRadius: 4,
                boxShadow: "3px 4px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="boxAnoMes">
                {item.mes}/{item.ano}
              </div>
              <div className="telaCheia" onClick={onClick}>
                <FontAwesomeIcon icon={faExpand} color="#fff" />
              </div>
            </div>
          );
        })}
      </Carousel>
    );
  } else {
    return <></>;
  }
}

{
  /* <img
              key={String(index)}
              src={item.src}
              style={{
                width: width ? width : 300,
                height: height ? height : item.height,
                paddingRight: 10,
                paddingTop: 10,
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={onClick}
            /> */
}
