import React from 'react'
import {Card, Col, Container, Form, Row} from 'react-bootstrap'
import '../index.css';
import Parser from 'html-react-parser';
import DommusToggle from '../../../components/DommusToggle';

export default function ModalImagem({ cor, evento, abrirModalFocoImagens}) {
    return (
        <>
            <div className="tituloComunicadoHtml">
                <h5>{evento?.nome}</h5>
            </div>
                    <Row>
                      {evento?.arquivos?.map(arquivo => {
                        return  (<>
                            <div class="col-md-12">
                            <Card>
                              <Card.Img className="imagem-padrao" src={arquivo.link} 
                              alt={arquivo.nome} onClick={() => abrirModalFocoImagens(arquivo.link)} />
                            </Card>
                          </div>
                        </>)
                      })}
                    </Row>
        </>
    )
}

