import React, { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { errorToast } from "../../../../../DommusToast";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DommusThumbnail } from "../../../../../DommusThumbnail";
import "./index.css";

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    borderWidth: 3,
    borderRadius: 10,
    borderColor: "grey",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    gap: "20px",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
};

const focusedStyle = {
    borderColor: "var(--dommus-color)",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

export function UploadItemResposta({ fieldName }) {
    const methods = useFormContext();
    const [listaArquivos, setListaArquivos] = useState([]);
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: { "image/*, application/pdf": [] },
        maxFiles: 10,
        maxSize: 1048576,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    useEffect(() => {
        if (acceptedFiles.length) {
            for (let i = 0; i < acceptedFiles.length; i++) {
                if (listaArquivos.length >= 10) {
                    errorToast.fire({
                        text: "Atingido quantidade máxima de arquivo!",
                    });
                    break;
                }
                let file = acceptedFiles[i];

                let reader = new FileReader();
                let arrayInfo = file.name.split(".");
                let extensao =
                    arrayInfo[arrayInfo.length - 1].toLocaleLowerCase();

                reader.onload = function (upload) {
                    let dadosArquivo = {
                        conteudo: upload.target.result.split(/base64,/)[1],
                        nome: file.name,
                        preview: URL.createObjectURL(file),
                        extensao: extensao,
                    };
                    setListaArquivos((current) => [...current, dadosArquivo]);
                };
                reader.readAsDataURL(file);
            }
        }
    }, [acceptedFiles]);

    useEffect(() => {
        if (fileRejections.length) {
            errorToast.fire({ text: "Houve arquivos não aceitos!" });
        }
    }, [fileRejections]);

    useEffect(() => {
        methods.setValue(fieldName, listaArquivos);
    }, [listaArquivos]);

    const handleRemoveFile = (index) => {
        let temp = [...listaArquivos];
        temp.splice(index, 1);
        setListaArquivos(temp);
    }

    return (
        <div className="file-section">
            <Controller
                control={methods.control}
                name={fieldName}
                render={({ onChange, onBlur, value, name, ref }) => (
                    <div {...getRootProps({ style })} className="drop-file-section">
                        <input {...getInputProps()} id={`file-input-${fieldName}`}/>
                        <FontAwesomeIcon icon={faFileUpload} />
                        <p>Solte o arquivo aqui, ou clique para selecionar</p>
                    </div>
                )}
            />
            <div className="files-section">
                {listaArquivos.length ? (
                    listaArquivos.map((arquivo, index) => {
                        return (
                            <div className="item-arquivo-section" key={index}>
                                <DommusThumbnail
                                    url={arquivo.preview}
                                    extensao={arquivo.extensao}
                                />
                                <span className="btn-remove-file" onClick={()=>{handleRemoveFile(index)}}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </span>
                            </div>
                        );
                    })
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}
