import React, { useContext, Component } from 'react';
import { Accordion, Card, Media, AccordionContext, useAccordionToggle, Tab, Col, Nav, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// npm install @iconify/react @iconify-icons/carbon
import { Icon } from '@iconify/react';
import pageDoc from '@iconify-icons/foundation/page-doc';
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import './coluna_direita.css';
import Mapa from './maps';
import Historico from './historico';

const location = {
  address: 'Belo Horizonte, MG',
  lat: -19.897496328764273,
  lng: -43.94277516976184,
}

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      style={{
        backgroundColor: isCurrentEventKey ? 'var(--dommus-color)' : 'rgba(0,0,0,.03)',
        color: isCurrentEventKey ? 'white' : 'black',
        padding: '.75rem 1.25rem',
        marginbottom: '0',
        borderbottom: '1px solid rgba(0,0,0,.125)'
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  );
}

export default function ColunaInformacoes() {
  const cliente = {
    nome: "Nome do contato",
    telefone: "(XX) XXXXX-XXXX",
    email: "email@cliente.com.br",
    cpf: "000.000.000-00",
    rg: "XX-00.000.000",
    data_de_nascimento: "dd/mm/aaaa",
    idade: "00" + "anos",
    genero: "Gênero",
    endereco: {
      logradouro: "Logradouro",
      bairro: "Nome do bairro",
      cidade_uf: "Cidade/UF",
      cep: "CEP",
    }
  }


  return (<>
    <div id='direita'>
      <Tab.Container id="left-tabs" defaultActiveKey="dados_cliente">
        <div className='content'>
          <div className="header">
            <Nav variant="pills" className="menu_direita">
              <Col>
                <Nav.Item>
                  <Nav.Link eventKey="dados_cliente">Cliente</Nav.Link>
                </Nav.Item>
              </Col>
              <Col>
                <Nav.Item>
                  <Nav.Link eventKey="historico">Histórico</Nav.Link>
                </Nav.Item>
              </Col>
              <Col>
                <Nav.Item>
                  <Nav.Link eventKey="tarefas">Tarefas</Nav.Link>
                </Nav.Item>
              </Col>
            </Nav>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="dados_cliente">
              <Accordion className="accordion" defaultActiveKey="0">
                <Card>
                  <div className="accordion_iten">
                    <ContextAwareToggle as={Card.Header} className="accordion_iten" eventKey="0">
                      Informações Cadastrais
                    </ContextAwareToggle>
                  </div>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div className="dadosPessoaisCliente">
                        <Row>
                          <Col xs={4}>
                            <div className="foto">
                              <div className="placeholder">
                                <div className=" fotoEnvolvido"><FontAwesomeIcon
                                  icon={faUserCircle} color="#444444" /></div>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div className="nomeCliente">
                              <Card.Text>
                                <b>
                                  {cliente.nome}
                                </b>
                              </Card.Text>
                            </div>
                            <div className="telefoneCliente">
                              <Card.Text>
                                {cliente.telefone}
                              </Card.Text>
                            </div>
                            <div className="emailCliente">
                              <Card.Text>
                                {cliente.email}
                              </Card.Text>
                            </div>
                          </Col>
                        </Row>
                        <hr />
                        <div className="cpfCliente">
                          <strong>CPF: </strong> {cliente.cpf}
                        </div>
                        <div className="rgCliente">
                          <strong>RG: </strong> {cliente.rg}
                        </div>
                        <div className="dataNasciemntoCliente">
                          <strong>Data de Nascimento: </strong> {cliente.data_de_nascimento}
                        </div>
                        <div className="idadeCliente">
                          <strong>Idade: </strong> {cliente.idade}
                        </div>
                        <div className="generoCliente">
                          <strong>Gênero:</strong> {cliente.genero}
                        </div>
                      </div>
                      <hr />
                      <div className="enderecoCliente">
                        <strong>Endereço:</strong>
                        <div className="logradouroCliente">
                          <strong>Logradouro: </strong> {cliente.endereco.logradouro}
                        </div>
                        <div className="bairroCliente">
                          <strong>Bairro: </strong> {cliente.endereco.bairro}
                        </div>
                        <div className="cidadeCliente">
                          <strong>Cidade/UF: </strong> {cliente.endereco.cidade_uf}
                        </div>
                        <div className="cepCliente">
                          <strong>CEP: </strong> {cliente.endereco.cep}
                        </div>
                      </div>
                      <hr />
                      <div>
                        <Mapa location={location} zoomLevel={13} /> {/* include it here */}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <div className="accordion_iten">
                    <ContextAwareToggle as={Card.Header} eventKey="1">
                      Documentação
                    </ContextAwareToggle>
                  </div>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Icon icon={pageDoc} color="var(--dommus-color)" width="1.5rem" height="1.5rem" />
                          <Media.Body>
                            <Link to="./">
                              <Card.Text>Documento 1</Card.Text>
                            </Link>
                          </Media.Body>
                        </Media>

                        <Media as="li">
                          <Icon icon={pageDoc} color="var(--dommus-color)" width="1.5rem" height="1.5rem" />
                          <Media.Body>
                            <Link to="./">
                              <Card.Text>Documento 2</Card.Text>
                            </Link>
                          </Media.Body>
                        </Media>
                        <Media as="li">
                          <Icon icon={pageDoc} color="var(--dommus-color)" width="1.5rem" height="1.5rem" />
                          <Media.Body>
                            <Link to="./">
                              <Card.Text>Documento 3</Card.Text>
                            </Link>
                          </Media.Body>
                        </Media>
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <div className="accordion_iten">
                    <ContextAwareToggle as={Card.Header} eventKey="2">
                      Contrato
                    </ContextAwareToggle>
                  </div>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Icon icon={pageDoc} color="var(--dommus-color)" width="1.5rem" height="1.5rem" />
                          <Media.Body>
                            <Link to="./">
                              <Card.Text>Contrato</Card.Text>
                            </Link>
                          </Media.Body>
                        </Media>
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <div className="accordion_iten">
                    <ContextAwareToggle as={Card.Header} eventKey="3">
                      Financeiro
                    </ContextAwareToggle>
                  </div>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      Aqui vem informações Financeiras
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Tab.Pane>
            <Tab.Pane eventKey="historico">
              <Accordion defaultActiveKey="0">
                <Card>
                  <div className="accordion_iten">
                    <ContextAwareToggle as={Card.Header} className="accordion_iten" eventKey="0">
                      Histórico do Cliente
                    </ContextAwareToggle>
                  </div>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Historico />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <div className="accordion_iten">
                    <ContextAwareToggle as={Card.Header} eventKey="1">
                      Outros Chamados
                    </ContextAwareToggle>
                  </div>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      Aqui vem a documentação
            </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>

    </div>
  </>);
}