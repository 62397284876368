import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import '../index.css';
export default function ModalFocoImagens({link}) {
  return (
    <Container>
        <Row  className="d-flex align-items-center justify-content-center">
            <div class="col-md-12">
            <Card>
            <Card.Img src={link} alt={"imagem"} class="img-fluid img-zoom" />
             </Card>           
            </div>
        </Row>
    </Container>
  )
}
