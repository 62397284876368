import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Card } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import arrowLeftSquare from '@iconify-icons/bi/arrow-left-square';
import DommusToggle from "../../../components/DommusToggle";
import './index.css';
import Chamados from '../../Widgets/MeusChamados/chamados';
import bxBuildingHouse from '@iconify-icons/bx/bx-building-house';
import guAPI from "../../../services/GuService";

export default function MeusPagamentos() {

    return <>
        <Container fluid>
            <Row>
                <Card className="cardMeusChamados">
                    <Card.Body className="cadBoddy">
                        <Link to="./" ><Icon className="iconMeuImovel" style={{ backgroundColor: guAPI.getLocalState('cor-menu-4') }} icon={bxBuildingHouse} /></Link>
                        <Card.Header className="cardHeader" style={{ backgroundColor: guAPI.getLocalState('cor-menu-4') }}>
                            <div className="vazio">
                            </div>
                            <div className="tituloCadastro" >
                                Meu Imóvel
                            </div>
                            <Link to="./" ><Icon className="iconVoltar" icon={arrowLeftSquare} /> </Link>
                        </Card.Header>
                        <div className="direita_doc">
                            
                                <DommusToggle title="Meus Chamados" toggle={true}>

                                    <Chamados />

                                </DommusToggle>
                            
                        </div>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    </>

}