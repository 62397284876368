import crm from "./crm";
import { trackPromise } from "react-promise-tracker";

const VistoriaService = {
  buscarHorariosDisponiveis: (data, tipo, processo, idAgenda) => {
    return trackPromise(crm.get("/vistoria/horarios/" + data + "/" + tipo + "/" + processo + (idAgenda ? `/${idAgenda}` : '')));
  },
  salvarHorarioVistoria: (dados) => {
    return trackPromise(crm.post("/vistoria/horario", dados));
  },
  buscaAgendados: (processo, tipo, idAgenda) => {
    return trackPromise(crm.get("/vistoria/agendados/" + processo + "/" + tipo + (idAgenda ? `/${idAgenda}` : '')));
  },
  buscarAgendaPorId: (idAgenda) => {
    return trackPromise(crm.get("/vistoria/agenda/" + idAgenda));
  },
  buscaEtapaVistoria: (processo, tipo, idAgenda) => {
    return trackPromise(crm.get("/vistoria/etapa/" + processo + "/" + tipo + (idAgenda ? `/${idAgenda}` : '')));
  },
  retrocederAgendamento: (dados) => {
    return trackPromise(crm.post("/vistoria/cancelar", dados));
  },
  salvarProcuracao: (dados) => {
    return trackPromise(crm.post('/vistoria/procuracao', dados))
  }
};

export default VistoriaService;
