import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Alert, Row, Col } from 'react-bootstrap'
import { GrupoParcelasEmAtraso } from '../GrupoParcelasEmAtraso';
import { errorToast, successToast } from '../DommusToast';
import { trackPromise } from 'react-promise-tracker';
import ProcessoService from '../../services/ProcessoService';
import guService from '../../services/GuService';
import {
    faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
export function ParcelasEmAtraso({ fecharModal }) {
    const [parcelasAgrupadas, setParcelasAgrupadas] = useState([])

    useEffect(() => {
        buscarParcelasAgrupadas()
    }, [])

    function buscarParcelasAgrupadas() {
        trackPromise(ProcessoService.buscarFluxoPagamento(guService.getLocalState("Processo"), { agruparParcelas: true })
            .then(response => agruparParcelasPorGrupo(response.data?.fluxo || []))
            .catch(error => {
                console.log(error)
                console.log(error?.response)
                errorToast.fire({ text: 'Houve um erro ao buscar as parcelas agrupadas.' })
            }))
    }

    function agruparParcelasPorGrupo(parcelas) {
        let parcelasAgrupadasPorTipo = {}
        parcelas.forEach(parcela => {
            if(parcela.statusParcela == 'atrasada') {
                if(parcelasAgrupadasPorTipo[parcela.nomeGrupoParcela]) {
                    parcelasAgrupadasPorTipo[parcela.nomeGrupoParcela].push(parcela)
                } else {
                    parcelasAgrupadasPorTipo[parcela.nomeGrupoParcela] = [parcela]
                }
            }
        });
        if(!Object.values(parcelasAgrupadasPorTipo).length) {
            successToast.fire({ text: 'Você não possui nenhuma parcela em atraso.'})
            fecharModal()

            return
        }
        const novoParcelasAgrupadas = Object.values(parcelasAgrupadasPorTipo).sort((a, b) => {
          return (a?.[0]?.nomeGrupoParcela || a?.[0]?.produto || 'Sem Produto') < (b?.[0]?.nomeGrupoParcela || b?.[0]?.produto || 'Sem Produto') ? -1 : 1;
        })
        setParcelasAgrupadas(novoParcelasAgrupadas)
    }

    return <Row className="mx-2">
        <Col>
            <Alert variant="info" className="mt-3 text-center">
                <FontAwesomeIcon icon={faInfoCircle}/> Selecione abaixo as parcelas que deseja efetuar o pagamento. Inicie a marcação pela parcela em atraso mais antiga.
            </Alert>
            <Row>
                {parcelasAgrupadas && parcelasAgrupadas.length && parcelasAgrupadas.map((grupoParcela, index) => <Col key={`id-grupo-${index}`} lg="6">
                    <GrupoParcelasEmAtraso parcelas={grupoParcela} toggle={[0, 1].includes(index)}/>
                </Col>)}
            </Row>
            {(guService.getLocalState('idInquilino') &&
            (Number(guService.getLocalState('idInquilino')) === 11) &&
            <a href="https://wa.me/5531984741283" target="_blank" style={{fontSize: "1rem", color: "green"}}>D&uacute;vidas? Entre em contato conosco. <FontAwesomeIcon icon={faWhatsapp} /></a>) || ""}
        </Col>
    </Row>
}
