import './index.css';
import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import user from '../../assets/usuario.svg';
import guService from "../../services/GuService";

function DommusOnlineFlag() {
    const [status, setStatus] = useState(true);
    const [posicaoFila, setPosicaoFila] = useState(true);
    const statusClass = status ? 'onlineFlag' : 'offlineFlag';
    const statusText = status ? 'online' : 'offline';

    return (
        <>
            <ReactTooltip />
            <picture className={"picture-topo"}>
                <img className={statusClass} src={user} data-tip={"Seja bem-vindo, "+guService.getLocalState('nomeUsuario')} />
            </picture>

        </>
    )
}

export default DommusOnlineFlag;
