import crm from './crm';
import {trackPromise} from "react-promise-tracker";
import swal from "sweetalert";

const PortalClienteService = {
    buscarPortal: () => {
        return crm.get('/portal-cliente');
    },
    salvarPortal: (portal, id_portal) => {
        let uri = '/portal-cliente';
        let method = 'post';
        if (portal.id_portal) {
            uri = '/portal-cliente/' + portal.id_portal;
            method = 'put'
            return crm[method].call(crm, uri, {portal: portal});
        }
        return crm[method].call(crm, uri, {portal: portal, id_portal: id_portal});
    }, 
    salvarIndicacao: (dados) => {
        return (crm.post('indicacao', dados))
      }
};

export default PortalClienteService;
