import React, { useState, useCallback, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import "./index.css";
import DommusRequiredSpan from "../../components/DommusRequiredSpan";
import guService from "../../services/GuService";
import DistratoService from "../../services/DistratoService";
import ticketService from "../../services/TicketService";
import TicketMotivo from "../../services/TicketMotivoService";
import Swal from "sweetalert2";
import { errorToast } from "../../components/DommusToast";
import ValidaTipoArquivo from "../../helpers/ValidaTipoArquivo";
import { trackPromise } from "react-promise-tracker";

export default function Distrato({ cor }) {
  const [motivo, setMotivo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [processo, setProcesso] = useState(guService.getLocalState("Processo"));
  const [arquivoCarregado, setArquivo] = useState({});
  const [motivoTipoD, setMotivoTipoD] = useState([]);

  const montaArquivo = useCallback((event) => {
    let file = event.target.files[0];
    if (ValidaTipoArquivo.verificaTipoArquivo(file)) {
      getBase64(file).then((data) =>
        setArquivo({ conteudo: data, nome: file.name })
      );
    } else {
      errorToast.fire({
        text: "Tipo de arquivo não suportado, selecione outro ou verifique o mesmo",
      });
    }
  }, []);
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const [listaMotivos, setListaMotivos] = useState([]);

  useEffect(() => {
    trackPromise(
      DistratoService.buscarMotivos().then((response) => {
        setListaMotivos(response.data.motivos_distrato);
      })
    );
  }, []);

  useEffect(() => {
    trackPromise(
      TicketMotivo.index().then((response) => {
        const TipoD = Object.values(response.data).map((item) => {
          if (item.id_ticket_motivo === "D") {
            return item.id_ticket_motivo;
          }
        });
        setMotivoTipoD(TipoD);
      })
    );
  }, []);

  function solicitarDistrato() {
    if (descricao == "" && motivoTipoD == "") {
      Swal.fire({
        titleText: "Preencha todos os campos obrigatórios",
        text: "Preencha todos os campos obrigatórios antes de prosseguir.",
        icon: "warning",
      });
      return;
    }
    trackPromise(
      DistratoService.solicitarDistrato(
        processo,
        motivoTipoD,
        descricao,
        arquivoCarregado
      ).then(
        (response) => {
          let dados = {
            assunto: "Solicitação de Distrato",
            descricao: descricao,
            id_processo: guService.getLocalState("Processo"),
            id_usuario_solicitante: guService.getLocalState("idUsuario"),
            id_ticket_status: 1,
            id_ticket_motivo: motivoTipoD,
          };
          trackPromise(
            ticketService.store(dados).then(
              (response) => {},
              (error) => {}
            )
          );
          Swal.fire({
            titleText: "Sucesso",
            text: "Solicitação de distrato enviada com sucesso",
            icon: "success",
          });
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao salvar o arquivo: " + error,
            icon: "error",
          });
        }
      )
    );
  }

  return (
    <div>
      <div className="tituloNovaIndicacao" style={{ backgroundColor: cor }}>
        <h5>Solicitar Distrato</h5>
      </div>
      <Form className="novaIndicacaoForm">
        <Form.Group as={Col} controlId="formNome">
          <Form.Label>
            Motivo: <DommusRequiredSpan />
          </Form.Label>
          <Form.Control
            as="select"
            custom
            value={motivo}
            onChange={(event) => setMotivo(event.target.value)}
          >
            <option>Selecione o motivo</option>
            {listaMotivos.map((campanha) => (
              <option key={campanha.id} value={campanha.id}>
                {campanha.nome}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="formNome">
          <Form.Label>
            Descrição: <DommusRequiredSpan />
          </Form.Label>
          <Form.Control
            type="textarea"
            placeholder="Digite a descrição"
            onChange={(event) => setDescricao(event.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formNome">
          <div className="av-item select-file">
            <div className="documentacao-item-wrapper">
              <div className="form-group">
                <div>Arquivo:</div>
                <div className="upload-wrapper">
                  <div id="documento-0" className="documento-wrapper">
                    <div className="ajax-upload-dragdrop">
                      <span>
                        <Form.Control
                          type="file"
                          accept="application/pdf"
                          className="form-control-file"
                          onChange={montaArquivo}
                        />
                      </span>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form.Group>
        <Row>
          <button
            className="btNovaIndicacao"
            type="button"
            onClick={() => solicitarDistrato()}
            style={{ backgroundColor: cor }}
          >
            {" "}
            Solicitar{" "}
          </button>
        </Row>
      </Form>
    </div>
  );
}
