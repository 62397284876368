import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import './index.css';
import { trackPromise } from "react-promise-tracker";
import PortalClienteService from '../../../services/PortalClienteService';
import swal from 'sweetalert';
import guService from '../../../services/GuService';
import configuracaoService from "../../../services/ConfiguracaoService";


export default function NovaIndicacao({ cor, closeModal }) {
    const [dados, setDados] = useState('');
    const [camposObrigatorios, setCamposObrigatorios] = useState({});

    const formataCPF = (value) => {
        const cpf = value.replace(/\D/g, ''); // Remove caracteres não numéricos
        const match = cpf.match(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})$/);

        if (match) {
          const cpfFormatado = [match[1], match[2], match[3]]
            .filter((group) => !!group)
            .join('.');

          return `${cpfFormatado}${match[4] ? `-${match[4]}` : ''}`;
        }
    };

    const formataTel = (value) => {
        const numero = value.replace(/\D/g, ''); // Remove caracteres não numéricos

        let valorFormatado = numero;

        if (numero.length >= 10) {
          const ddd = numero.slice(0, 2);
          const primeiraParte = numero.slice(2, -4);
          const segundaParte = numero.slice(-4);

          valorFormatado = `(${ddd}) ${primeiraParte}-${segundaParte}`;
        } else if (numero.length >= 8) {
          const primeiraParte = numero.slice(0, -4);
          const segundaParte = numero.slice(-4);

          valorFormatado = `${primeiraParte}-${segundaParte}`;
        }

        return valorFormatado;
      };

    useEffect(() => {
        configuracaoService.buscarConfiguracoesVisuais().then((response) => {            
            const camposObrigatorios = response.data.find(configuracao => configuracao.chave === "campos_obrigatorios_formulario_indicacao" );            
            if(camposObrigatorios && camposObrigatorios?.valor){
                setCamposObrigatorios(JSON.parse(camposObrigatorios?.valor));
            }
        });
    }, [])
    
    const dadosIndicado = useMemo(()=>{
        return yup.object({
            nome: yup.string().when([], {
                is: () => camposObrigatorios?.nome,
                then: yup.string().required('Campo obrigatório'),
                otherwise: yup.string(), 
            }).min(2, 'Nome muito pequeno'),
            cpf: yup.string().when([], {
                    is: () => camposObrigatorios?.cpf,
                    then: yup.string().required('Campo obrigatório'),
                    otherwise: yup.string(), 
            }).matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'Formato de CPF inválido'),
            email: yup.string().when([], {
                    is: () => camposObrigatorios?.email,
                    then: yup.string().required('Campo obrigatório'),
                    otherwise: yup.string(), 
            }).email('Formato de e-mail inválido'),
            telefone: yup.string().when([], {
                    is: () => camposObrigatorios?.telefone,
                    then: yup.string().required('Campo obrigatório'),
                    otherwise: yup.string(), 
            }).min(8, 'Campo obrigatório'),
            endereco: yup.string().when([], {
                is: () => camposObrigatorios?.endereco,
                then: yup.string().required('Campo obrigatório'),
                otherwise: yup.string(), 
            }),
            observacao: yup.string().when([], {
                    is: () => camposObrigatorios?.observacao,
                    then: yup.string().required('Campo obrigatório'),
                    otherwise: yup.string(), 
            })
        });
    },[camposObrigatorios]) 

    return <div>
        <div className="tituloNovaIndicacao" style={{ backgroundColor: cor ?? "var(--dommus-color)" }} >
            <h5>Nova Indicação</h5>
        </div>
        <Formik
            validationSchema={dadosIndicado}
            onSubmit={(values) => {
                setDados(values);
                let dados = values;
                dados['data'] = new Date().toISOString();
                dados['processo'] = guService.getLocalState("Processo");
                dados['id_indicante'] = guService.getLocalState("idUsuario");
                dados['empreendimento'] = guService.getLocalState("IdEmpreendimento");
                trackPromise(
                    PortalClienteService.salvarIndicacao(dados).then((res) => {
                        swal("Sucesso", "Indicação salva com sucesso!", "success");
                        closeModal(false);
                    })
                ).catch((err) => {
                    swal("Ooops...", "Ocorreu um erro ao salvar indicação: " + err, "error")
                });
            }}

            initialValues={{
                nome: '',
                telefone: '',
                email: '',
                endereco: '',
                cpf: '',
                observacao: '',
                manter_corretor: '',
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
            }) => (<Form className="novaIndicacaoForm">
                <p>Digite os dados de quem você quer indicar:</p>
                <Row>
                    <Col >
                        <label>
                            <div className="inputLabel">Nome:{camposObrigatorios?.nome ? <span className="obrigatory">*</span> : <></>}</div>
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="nome"
                            onChange={handleChange}
                        />
                        {errors.nome && touched.nome ? (
                            <div className="fildError">{errors.nome}</div>
                        ) : null}
                    </Col>
                    <Col >
                        <label>
                            <div className="inputLabel">CPF:{camposObrigatorios?.cpf ? <span className="obrigatory">*</span> : <></>}</div>
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="cpf"
                            value={formataCPF(values.cpf)}
                            maxLength="14"
                            pattern="^\d{3}\.\d{3}\.\d{3}\-\d{2}$"
                            onChange={handleChange}
                        />
                        {errors.cpf && touched.cpf ? (
                            <div className="fildError">{errors.cpf}</div>
                        ) : null}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>
                            <div className="inputLabel">
                                E-mail:
                                {camposObrigatorios?.email ? <span className="obrigatory">*</span> : <></>}
                            </div>
                        </label>
                        <Field
                            className="form-control"
                            type="mail"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                        />
                        {errors.email && touched.email ? (
                            <div className="fildError">{errors.email}</div>
                        ) : null}
                    </Col>
                    <Col >
                        <label>
                            <div className="inputLabel">
                                Telefone:
                                {camposObrigatorios?.telefone ? <span className="obrigatory">*</span> : <></>}
                            </div>
                        </label>
                        <Field
                            label="telefone"
                            className="form-control"
                            type="text"
                            name="telefone"
                            // pattern="\([0-9]{2}\) [0-9]{4,6}-[0-9]{3,4}$"
                            value={formataTel(values.telefone)}
                            onChange={handleChange}
                            //isInvalid={!!errors.telefone1}
                            validate={touched.telefone && !errors.telefone}
                        />
                        {errors.telefone && touched.telefone ? (
                            <div className="fildError">{errors.telefone}</div>
                        ) : null}

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>
                            <div className="inputLabel">
                                Endereço:
                                {camposObrigatorios?.endereco ? <span className="obrigatory">*</span> : <></>}
                            </div>
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="endereco"
                            value={values.endereco}
                            onChange={handleChange}
                        />
                        {errors.endereco && touched.endereco ? (
                            <div className="fildError">{errors.endereco}</div>
                        ) : null}
                    </Col>
                </Row>
                <Row>
                    <Col >
                    <label>
                            <div className="inputLabel">
                                Observação:
                                {camposObrigatorios?.observacao ? <span className="obrigatory">*</span> : <></>}
                            </div>
                        </label>
                        <Field
                            label="observacao"
                            className="form-control"
                            component="textarea"
                            name="observacao"
                            value={values.observacao}
                            onChange={handleChange}
                            validate={touched.observacao && !errors.observacao}
                        />
                        {errors.observacao && touched.observacao ? (
                            <div className="fildError">{errors.observacao}</div>
                        ) : null}

                    </Col>
                </Row>
                <Row>
                    <Col className='coluna-checkbox'>
                        <Field
                            label="manter_corretor"
                            className="form-check-input"
                            component="input"  
                            type="checkbox"   
                            name="manter_corretor"
                            checked={values.manter_corretor} 
                            onChange={handleChange}
                            validate={touched.manter_corretor && !errors.manter_corretor}
                        />
                        <label htmlFor="manter_corretor">
                            Manter o mesmo corretor
                        </label>
                        {errors.manter_corretor && touched.manter_corretor ? (
                            <div className="fildError">{errors.manter_corretor}</div>
                        ) : null}

                    </Col>
                </Row>
                <Row>
                    <button className="btNovaIndicacao" type="submit" style={{ backgroundColor: cor }} > Indicar </button>
                </Row>
            </Form>
            )}
        </Formik>
    </div>
}
