import { faArrowAltCircleLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { DommusInputErro } from "../DommusInputErro";
import { mascaraCpfCnpj, celularMask, apenasNumeros } from "../../components/InputTextMask";
import ValidaTipoArquivo from "../../helpers/ValidaTipoArquivo";
import Swal from "sweetalert2";
import { erroSwal, sucessoSwal } from "../../helpers/DommusSwal";
import VistoriaService from "../../services/VistoriaService";

export function FormularioProcuracao({ alternarModalProcuracao, procuracao }) {
    const [arquivo, setArquivo] = useState(null)
    const { register, handleSubmit, errors, setValue } = useForm()
    const salvarProcuracao = (dados) => {
		dados.procuracao = arquivo
        dados.id_agenda_entrega = procuracao.id_agenda_entrega
        dados.cpf = apenasNumeros(dados.cpf)
        dados.telefone = apenasNumeros(dados.telefone)
        VistoriaService.salvarProcuracao(dados)
            .then(() => sucessoSwal('Procuração salva com sucesso.'))
            .catch(error => {
                console.log(error)
                console.log(error.response)
                erroSwal('Houve um erro ao tentar salvar a procuração.')
            }) 
        alternarModalProcuracao()
	};
    const montaArquivo = useCallback((event) => {
        let reader = new FileReader();
        let file = event.target.files[0];
        if (ValidaTipoArquivo.verificaTipoArquivo(file)) {
          let size =
            event.target.files[0].size == undefined
              ? 0
              : event.target.files[0].size;
          if (size === 0) return "0 Bytes";
    
          const k = 1024;
          const dm = 2 < 0 ? 0 : 2;
          const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    
          const i = Math.floor(Math.log(size) / Math.log(k));
          if (
            parseFloat((size / Math.pow(k, i)).toFixed(dm)) > 10 &&
            " " + sizes[i] == " MB"
          ) {
            Swal.fire({
              titleText: "Erro!",
              text: "O arquivo tem mais de 10mb!",
              icon: "error",
            });
          } else {
            reader.onload = function (upload) {
              let dadosArquivo = {
                conteudo: upload.target.result.split(/base64,/)[1],
                nome: file.name,
              };
              setArquivo(dadosArquivo);
            };
            reader.readAsDataURL(file);
          }
        } else {
          erroSwal("Tipo de arquivo não suportado, selecione outro ou verifique o mesmo");
        }
    }, []);

    return <Container className="py-3">
        <Form onSubmit={handleSubmit(salvarProcuracao)} className="formCadastro">
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control name="nome" ref={register({ required: true })} defaultValue={procuracao?.nome}/>
                    {errors?.nome && <DommusInputErro/>}
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control type="email" name="email" ref={register({ required: true })} defaultValue={procuracao?.email}/>
                    {errors?.email && <DommusInputErro/>}
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>CPF</Form.Label>
                    <Form.Control 
                        name="cpf" 
                        ref={register({ required: true })} 
                        onChange={(event) => setValue('cpf', mascaraCpfCnpj(event.target.value))} 
                        defaultValue={mascaraCpfCnpj(procuracao?.cpf)}
                    />
                    {errors?.cpf && <DommusInputErro/>}
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>RG</Form.Label>
                    <Form.Control type="number" name="rg" ref={register({ required: true })} defaultValue={procuracao?.rg}/>
                    {errors?.rg && <DommusInputErro/>}
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Telefone</Form.Label>
                    <Form.Control 
                        name="telefone" 
                        ref={register({ required: true })} 
                        onChange={(event) => setValue('telefone', celularMask(event.target.value))} 
                        defaultValue={procuracao?.telefone ? celularMask(procuracao.telefone) : ''}
                    />
                    {errors?.telefone && <DommusInputErro/>}
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <div className="av-item select-file">
                        <div className="documentacao-item-wrapper mb-0">
                            <div className="form-group">
                                <div className="pl-3">Procuração:</div>
                                <div className="upload-wrapper">
                                    <div className="documento-wrapper">
                                        <div className="ajax-upload-dragdrop">
                                            <span>
                                                <Form.Control 
                                                    type="file" 
                                                    accept="application/pdf" 
                                                    className="form-control-file" 
                                                    name="procuracao" 
                                                    ref={register({ required: true })}
                                                    onChange={montaArquivo}
                                                />
                                            </span>
                                        </div>
                                    <div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {errors?.procuracao && <DommusInputErro/>}
                </Form.Group>
            </Row>
            <Row className="justify-content-end">
                <Button type="button" variant="secondary" onClick={() => alternarModalProcuracao()} className="mr-3">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2"/> Cancelar
                </Button>
                <Button type="submit" variant="success">
                    <FontAwesomeIcon icon={faSave} className="mr-2"/> Salvar
                </Button>
            </Row>
        </Form>
    </Container>
}