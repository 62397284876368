import React, { useState, useEffect } from "react"
import DommusToggle from '../DommusToggle'
import { Form, Table, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown, faFilePdf } from "@fortawesome/free-solid-svg-icons"
import moment from "moment"
import { useForm } from "react-hook-form"
import ProcessoService from "../../services/ProcessoService"
import Swal from "sweetalert2"
import { errorToast, successToast } from "../DommusToast"
import guService from "../../services/GuService"

export function GrupoParcelasEmAtraso({ parcelas, toggle }) {
    const [toggleAberto, setToggleAberto] = useState(toggle)
    const [parcelasMarcadas, setParcelasMarcadas] = useState([]);
    const { register, handleSubmit } = useForm()

    useEffect(() => {
      if(Array.isArray(parcelas) && parcelas.length){
        if(parcelas[0].pagamentoMinimo){
          let remanescentePagamentoMinimo = parseFloat(parcelas[0].pagamentoMinimo);
          const novoParcelasMarcadas = parcelas.reduce((marcadas, atual) => {
            if(remanescentePagamentoMinimo > 0){
              marcadas.push(atual);
              remanescentePagamentoMinimo -= parseFloat(atual.valor_corrigido || atual.valor)
            }
            return marcadas;
          }, []);
          setParcelasMarcadas(novoParcelasMarcadas);

        }else{
          const novoParcelasMarcadas = parcelas.map((parcela, index) => index == 0);
          setParcelasMarcadas(novoParcelasMarcadas);
        }
      }
    }, [parcelas]);

    const gerarBoleto = parcelasMarcadas => {

      let parcelasBuscarChavesExternas = []
      let padraoCobranca = null;
      for(let [chaveParcela, parcelaMarcada] of Object.entries(parcelasMarcadas)) {
          if((Array.isArray(parcelaMarcada) && parcelaMarcada[0] == 'on') || (parcelaMarcada === true)) {
              parcelasBuscarChavesExternas.push(Number(chaveParcela.split('-')[1]));
              let padraoParcela = Number(chaveParcela.split('-')[3]);
              if(padraoParcela && !(padraoCobranca)){
                padraoCobranca = padraoParcela
              }

          }
      }
      const parcelasGerar = parcelas.filter(parcela => parcelasBuscarChavesExternas.includes(Number(parcela.id)));
      if(parcelasGerar.length && parcelasGerar[0].pagamentoMinimo){
        const valorAtingido = parcelasGerar.reduce((atingido, parcela) => {
          atingido += parseFloat(parcela.valor_corrigido || parcela.valor)
          return atingido;
        }, 0);
        if(valorAtingido < parseFloat(parcelasGerar[0].pagamentoMinimo)){
          errorToast.fire({ text: 'Valor mínimo para pagamento de ' + parseFloat(parcelasGerar[0].pagamentoMinimo).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
          }) + '.' })
          return false;
        }
      }
      const chavesExternas = parcelasGerar.map(parcela => parcela.chaves_externas).flat()
      buscarBoleto(guService.getLocalState("Processo"), parcelasBuscarChavesExternas[0], chavesExternas, padraoCobranca)
    }

    function buscarBoleto(processo, idParcela, chavesExternas, padraoCobranca = null) {
        ProcessoService.buscarBoleto(processo, idParcela, chavesExternas, padraoCobranca)
            .then(response => {
                if(response.data.length > 0) {
                    Swal.fire({
                        titleText: `2ª via de boleto`,
                        text: "Seu boleto está disponível para download",
                        icon: "info",
                        showCloseButton: false,
                        showCancelButton: true,
                        showDenyButton: true,
                        focusConfirm: false,
                        confirmButtonText: "Baixar boleto",
                        denyButtonText: "Copiar código",
                        cancelButtonText: "Cancelar",
                        customClass: {
                            confirmButton: "btn btn-success mr-3",
                            cancelButton: "btn btn-secondary mr-3",
                            denyButton: "btn btn-primary mr-3",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.open(response.data[0].urlReport, "_blank");
                        } else if (result.isDenied) {
                            navigator.clipboard.writeText(response.data[0].digitableNumber);
                            successToast.fire({ text: "Link copiado para a área de transferência."});
                        }
                    });
                } else {
                    Swal.fire({
                        titleText: "Boleto não disponível, entre em contato conosco.",
                        text: "",
                        icon: "warning",
                    });
                }
            }).catch(error => {
                console.log(error)
                console.log(error?.response)
                errorToast.fire({ text: 'Houve um erro ao tentar gerar o boleto.' })
            })
    }

    function LinhaParcela({ parcela, index }) {
        return <tr onClick={() => alterarParcelasMarcadas(index)} style={{ cursor: parcelasMarcadas[index - 1] || index == 0 ? 'pointer' : 'not-allowed' }}>
            <td className="text-center">
                <Form.Control
                    type="checkbox"
                    style={{
                        width: '20px',
                        height: '20px'
                    }}
                    checked={parcelasMarcadas[index]}
                    name={`idParcela-${parcela.id}-padraoCobranca-${parcela.padraoCobranca}`}
                    ref={register()}
                    disabled={!parcelasMarcadas[index - 1] && index != 0}
                />
            </td>
            <td className="text-center">{moment(parcela.vencimento).format("DD/MM/YYYY")}</td>
            <td className="text-start">
                {parseFloat(parcela.valor_corrigido || parcela.valor).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })}
            </td>
        </tr>
    }

    function alterarParcelasMarcadas(index) {
        if(parcelasMarcadas.filter((parcela, indexParcelaMarcada) => parcela == true && indexParcelaMarcada > index).length ||
            parcelasMarcadas.filter((parcela, indexParcelaMarcada) => parcela == false && indexParcelaMarcada < index).length ||
            index == 0
        ) {
          return
        }
        const parcelasMarcadasAtualizadas = [...parcelasMarcadas];
        parcelasMarcadasAtualizadas[index] = !parcelasMarcadasAtualizadas[index];
        setParcelasMarcadas(parcelasMarcadasAtualizadas);
    };


    return (
        <DommusToggle
            toggle={toggleAberto}
            title={parcelas?.[0]?.nomeGrupoParcela || parcelas?.[0]?.produto || 'Sem Produto'}
            button={<FontAwesomeIcon icon={faArrowDown} onClick={() => setToggleAberto(toggle => !toggle)}/>}
        >
            <Form onSubmit={handleSubmit(gerarBoleto)}>
                <Table bordered={true} hover={true} size="sm">
                    <thead>
                        <tr className="bg-dark text-white">
                            <th>#</th>
                            <th className="text-center">Vencimento</th>
                            <th className="text-start">Valor</th>
                        </tr>
                    </thead>
                    <tbody>{parcelas.map((parcela, index) => <LinhaParcela key={`id-linha-${index}`} parcela={parcela} index={index}/>)}</tbody>
                </Table>
                <Row className="justify-content-end mx-0">
                    <button className="btnExtrato p-2" type="submit">
                        <FontAwesomeIcon icon={faFilePdf} className="mr-2 mt-1"/> Gerar Boleto
                    </button>
                </Row>
            </Form>
        </DommusToggle>
    )
}
