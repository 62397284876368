/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import utilsService from "../../../services/utils";
import Swal from "sweetalert2";
import { Container, Row, Button, Col, Card, Alert } from "react-bootstrap";
import * as yup from "yup";
import { Icon, InlineIcon } from "@iconify/react";
import arrowLeftSquare from "@iconify-icons/bi/arrow-left-square";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import DommusToggle from "../../../components/DommusToggle";
import cep from "cep-promise";
import {
  cpfMask,
  celularMask,
  cepMask,
  identidadeMask,
  apenasNumeros,
} from "../../../components/InputTextMask";
import bxsUserDetail from "@iconify-icons/bx/bxs-user-detail";
import "./index.css";
import Select from "react-select";
import guAPI from "../../../services/GuService";
import processoService from "../../../services/ProcessoService";
import usuarioService from "../../../services/UsuarioService";
import { trackPromise } from "react-promise-tracker";
export default function DadosCadastrais() {
  const [endereco, setEndereco] = useState(false);
  const [bairro, setBairro] = useState(false);
  const [cidade, setCidade] = useState(false);
  const [estado, setEstado] = useState(false);
  const [cep, setCep] = useState("");
  const [cpf, setCpf] = useState("");
  const [celular, setCelular] = useState("");
  const [telefone, setTelefone] = useState("");
  const [proponente, setProponente] = useState({});
  const [proponenteOld, setProponenteOld] = useState({});
  const [nProponente, setNProponente] = useState("");

  useEffect(() => {
    buscarProponenteCpf();
  }, []);
  let initialValues = {
    cpf: cpfMask(proponente["proponente_cpf"]),
    nome: proponente["proponente_nome"],
    genero: proponente["proponente_genero"],
    nascimento: proponente["proponente_data_nascimento"],

    tipoDeDocumento: proponente["proponente_tipo_documento"],
    numeroDoDocumento:
      proponente["proponente_numero_documento"],
    orgaoEmissor: proponente["proponente_orgao_emissor"],
    dataEmissao: proponente["proponente_data_emissao"],

    telefone1: celularMask(
      proponente["proponente_telefone_residencial"]
    ),
    telefone2: celularMask(
      proponente["proponente_telefone_celular"]
    ),
    email:
      proponente["proponente_email"] != undefined
        ? proponente["proponente_email"].trim()
        : proponente["proponente_email"],

    cep: proponente["proponente_endereco_cep"],
    tipo: proponente["proponente_tipo_logradouro"],
    logradouro: proponente["proponente_logradouro"],
    numero: proponente["proponente_numero"],
    complemento: proponente["proponente_complemento"],
    bairro: proponente["proponente_bairro"],
    cidade: proponente["proponente_cidade"],
    estado: proponente["proponente_endereco_uf"],
    referencia: "",
  };

  function buscarProponenteCpf() {
    trackPromise(
      processoService
        .buscarProponenteCpf(
          guAPI.getLocalState("Processo"),
          guAPI.getLocalState("CPF")
        )
        .then((response) => {
          const dados = response.data;
          setProponente(dados.proponente);
          setNProponente(dados.nProponente);
          setTelefone(
            dados.proponente[
              "proponente_telefone_residencial"
            ]
          );
          setCelular(
            dados.proponente[
              "proponente_telefone_celular"
            ]
          );
          initialValues = {
            cpf: cpfMask(
              dados.proponente["proponente_cpf"]
            ),
            nome: dados.proponente["proponente_nome"],
            genero:
              dados.proponente["proponente_genero"],
            nascimento:
              dados.proponente[
                "proponente_data_nascimento"
              ],

            tipoDeDocumento:
              dados.proponente[
                "proponente_tipo_documento"
              ],
            numeroDoDocumento:
              dados.proponente[
                "proponente_numero_documento"
              ],
            orgaoEmissor:
              dados.proponente[
                "proponente_orgao_emissor"
              ],
            dataEmissao:
              dados.proponente[
                "proponente_data_emissao"
              ],

            telefone1: celularMask(
              dados.proponente[
                "proponente_telefone_residencial"
              ]
            ),
            telefone2: celularMask(
              dados.proponente[
                "proponente_telefone_celular"
              ]
            ),
            email:
              proponente["proponente_email"] != undefined
                ? proponente["proponente_email"].trim()
                : proponente["proponente_email"],

            cep: dados.proponente[
              "proponente_endereco_cep"
            ],
            tipo: dados.proponente[
              "proponente_tipo_logradouro"
            ],
            logradouro:
              dados.proponente[
                "proponente_logradouro"
              ],
            numero:
              dados.proponente["proponente_numero"],
            complemento:
              dados.proponente[
                "proponente_complemento"
              ],
            bairro:
              dados.proponente["proponente_bairro"],
            cidade:
              dados.proponente["proponente_cidade"],
            estado:
              dados.proponente[
                "proponente_endereco_uf"
              ],
            referencia: "",
          };
          setProponenteOld(initialValues);
        })
    );
  }

  const dadosCadastrais = yup.object({
    cpf: yup.string().required("Campo obrigatório"),
    nome: yup.string().required("Campo obrigatório"),
    genero: yup.string().required("Campo obrigatório"),
    nascimento: yup.date().required("Campo obrigatório"),

    tipoDeDocumento: yup.string().required("Campo obrigatório"),
    numeroDoDocumento: yup.string().required("Campo obrigatório"),
    orgaoEmissor: yup.string().required("Campo obrigatório"),
    dataEmissao: yup.date().required("Campo obrigatório"),

    telefone2: yup
      .string()
      .min(14, "Número inválido")
      .max(16, "Número inválido")
      .required("Campo obrigatório"),
    email: yup
      .string()
      .email("Digite um e-mail válido")
      .required("Campo obrigatório"),

    cep: yup.string().required("Campo obrigatório"),
    tipo: yup.string().required("Campo obrigatório"),
    logradouro: yup.string().required("Campo obrigatório"),
    numero: yup.string().required("Campo obrigatório"),
    bairro: yup.string().required("Campo obrigatório"),
    cidade: yup.string().required("Campo obrigatório"),
    estado: yup.string().required("Campo obrigatório"),
  });

  const generoOptions = [
    { value: "Masculino", label: "Masculino" },
    { value: "Feminino", label: "Feminino" },
  ];
  const tipoDeDocumentoOptions = [
    { value: "RG", label: "RG" },
    { value: "CNH", label: "CNH" },
    { value: "PASSAPORTE", label: "Passaporte" },
  ];
  const tipoOptions = [
    { value: "casa", label: "Casa" },
    { value: "apartamento", label: "Apartamento" },
  ];
  const estadoOptions = [
    { value: "AC", label: "Acre" },
    { value: "AL", label: "Alagoas" },
    { value: "AP", label: "Amapá" },
    { value: "AM", label: "Amazonas" },
    { value: "BA", label: "Bahia" },
    { value: "CE", label: "Ceará" },
    { value: "DF", label: "Distrito Federal" },
    { value: "ES", label: "Espírito Santo" },
    { value: "GO", label: "Goiás" },
    { value: "MA", label: "Maranhão" },
    { value: "MT", label: "Mato Grosso" },
    { value: "MS", label: "Mato Grosso do Sul" },
    { value: "MG", label: "Minas Gerais" },
    { value: "PA", label: "Pará" },
    { value: "PB", label: "Paraíba" },
    { value: "PR", label: "Paraná" },
    { value: "PE", label: "Pernambuco" },
    { value: "PI", label: "Piauí" },
    { value: "RJ", label: "Rio de Janeiro" },
    { value: "RN", label: "Rio Grande do Norte" },
    { value: "RS", label: "Rio Grande do Sul" },
    { value: "RO", label: "Rondônia" },
    { value: "RR", label: "Roraima" },
    { value: "SC", label: "Santa Catarina" },
    { value: "SP", label: "São Paulo" },
    { value: "SE", label: "Sergipe" },
    { value: "TO", label: "Tocantins" },
    { value: "EX", label: "Estrangeiro" },
  ];

  function handleSubmit(values) {
    const valoresPosteriores = values;
    const valoresAnteriores = proponenteOld;
    let dados = {
      dados_posteriores: valoresPosteriores,
      dados_anteriores: valoresAnteriores,
    };
    trackPromise(
      usuarioService.store(dados).then(
        (response) => {
          if (response.status == 201) {
            Swal.fire({
              titleText: "Sucesso!",
              text: "Dados enviados com sucesso, aguarde a confirmação da atualização!",
              icon: "success",
            });
          }
          if (response.status == 200) {
            Swal.fire({
              titleText: "Atualização em validação",
              text: response.data,
              icon: "warning",
            });
          }
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao salvar, entre em contato com a incorporadora",
            icon: "error",
          });
        }
      )
    );
  }
  function buscaCep(cep) {
    utilsService.buscarPorCep(cep).then((response) => {
      const dados = response.data;
      setBairro(dados.bairro);
      setCidade(dados.localidade);
      setEndereco(dados.logradouro);
      setEstado(dados.Estado);
    });
  }
  return (
    <Container fluid>
      <Row>
        <Card className="cardDadosCadastrais">
          <Card.Body className="cadBoddy">
            <Link to="./">
              <Icon
                className="iconCadastro"
                style={{ backgroundColor: guAPI.getLocalState("cor-menu-1") }}
                icon={bxsUserDetail}
              />
            </Link>
            <Card.Header
              className="cardHeader"
              style={{ backgroundColor: guAPI.getLocalState("cor-menu-1") }}
            >
              <Card.Text className="vazio"></Card.Text>
              <Card.Text className="tituloCadastro">Dados Cadastrais</Card.Text>
              <Link to="./">
                <Icon className="iconVoltar" icon={arrowLeftSquare} />{" "}
              </Link>
            </Card.Header>
            <Alert style={{ margin: "15px 15px 0 15px" }} variant={"info"}>
              Confira seus dados cadastrais e, caso haja algum dado
              desatualizado, por gentileza proceda com a alteração e clique no
              botão salvar.
            </Alert>
            <Formik
              validationSchema={dadosCadastrais}
              onSubmit={async (values) => {
                await new Promise((r) => setTimeout(r, 500));
                handleSubmit(values);
              }}
              enableReinitialize={true}
              initialValues={initialValues}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form style={{ padding: "15px 15px" }}>
                  <DommusToggle
                    title="Informações pessoais"
                    style={{ marginTop: "10px" }}
                    toggle={true}
                  >
                    <Row>
                      <Col md="3">
                        <label>
                          <div className="inputLabel">
                            <span className="obrigatory">*</span>CPF:
                          </div>
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          readOnly
                          name="cpf"
                          value={cpfMask(
                            proponente["proponente_cpf"]
                          )}
                        />
                        {errors.cpf && touched.cpf ? (
                          <div className="fildError">{errors.cpf}</div>
                        ) : null}
                        <ErrorMessage name="name" />
                      </Col>
                      <Col md="4">
                        <label>
                          <div className="inputLabel">
                            <span className="obrigatory">*</span>Nome:
                          </div>
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          name="nome"
                          value={values.nome}
                          onChange={handleChange}
                        />
                        {errors.nome && touched.nome ? (
                          <div className="fildError">{errors.nome}</div>
                        ) : null}
                      </Col>
                      <Col md="2">
                        <label>
                          <div className="inputLabel">
                            <span className="obrigatory">*</span>Gênero:
                          </div>
                        </label>
                        <Select
                          placeholder="Selecionar"
                          onBlur={handleBlur}
                          defaultValue
                          value={generoOptions.filter(
                            (option) => option.label === values.genero
                          )}
                          onChange={(selectedOption) => {
                            let event = {
                              target: {
                                name: "genero",
                                value: selectedOption.value,
                              },
                            };
                            handleChange(event);
                          }}
                          onBlur={() => {
                            handleBlur({ target: { name: "genero" } });
                          }}
                          isSearchable={true}
                          options={generoOptions}
                          name="genero"
                          isLoading={false}
                          loadingMessage={() => "Procurando"}
                          noOptionsMessage={() => "Nenhum resultado encontrado"}
                        />
                        {errors.genero && touched.genero ? (
                          <div className="fildError">{errors.genero}</div>
                        ) : null}
                      </Col>

                      <Col md="3">
                        <label>
                          <div className="inputLabel">
                            <span className="obrigatory">*</span>Data de
                            Nascimento:
                          </div>
                        </label>

                        <Field
                          className="form-control"
                          type="date"
                          name="nascimento"
                          value={values.nascimento || ""}
                          onChange={handleChange}
                        />
                        {errors.nascimento && touched.nascimento ? (
                          <div className="fildError">{errors.nascimento}</div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <label>
                          <div className="inputLabel">
                            <span className="obrigatory">*</span>Tipo de
                            Documento:
                          </div>
                        </label>
                        <Select
                          placeholder="Selecionar"
                          value={tipoDeDocumentoOptions.filter(
                            (option) => option.value === values.tipoDeDocumento
                          )}
                          onBlur={handleBlur}
                          onChange={(selectedOption) => {
                            let event = {
                              target: {
                                name: "tipoDeDocumento",
                                value: selectedOption.value,
                              },
                            };
                            handleChange(event);
                          }}
                          onBlur={() => {
                            handleBlur({ target: { name: "tipoDeDocumento" } });
                          }}
                          isSearchable={true}
                          options={tipoDeDocumentoOptions}
                          name="tipoDeDocumento"
                          isLoading={false}
                          loadingMessage={() => "Procurando"}
                          noOptionsMessage={() => "Nenhum resultado encontrado"}
                        />
                        {errors.tipoDeDocumento && touched.tipoDeDocumento ? (
                          <div className="fildError">
                            {errors.tipoDeDocumento}
                          </div>
                        ) : null}
                      </Col>

                      <Col md="4">
                        <label>
                          <div className="inputLabel">
                            <span className="obrigatory">*</span>Numero do
                            Documento:
                          </div>
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder=""
                          name="numeroDoDocumento"
                          value={values.numeroDoDocumento || ""}
                          onChange={handleChange}
                        />
                        {errors.numeroDoDocumento &&
                        touched.numeroDoDocumento ? (
                          <div className="fildError">
                            {errors.numeroDoDocumento}
                          </div>
                        ) : null}
                      </Col>

                      <Col md="2">
                        <label>
                          <div className="inputLabel">
                            <span className="obrigatory">*</span>Orgão Emissor:
                          </div>
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder=""
                          name="orgaoEmissor"
                          value={values.orgaoEmissor || ""}
                          onChange={handleChange}
                        />
                        {errors.orgaoEmissor && touched.orgaoEmissor ? (
                          <div className="fildError">{errors.orgaoEmissor}</div>
                        ) : null}
                      </Col>

                      <Col md="3">
                        <label>
                          <div className="inputLabel">
                            <span className="obrigatory">*</span>Data de
                            Emissão:
                          </div>
                        </label>
                        <Field
                          className="form-control"
                          type="date"
                          name="dataEmissao"
                          value={values.dataEmissao || ""}
                          onChange={handleChange}
                        />
                        {errors.dataEmissao && touched.dataEmissao ? (
                          <div className="fildError">{errors.dataEmissao}</div>
                        ) : null}
                      </Col>
                    </Row>
                  </DommusToggle>
                  <DommusToggle
                    title="Dados para contato"
                    style={{ marginTop: "10px" }}
                    toggle={true}
                  >
                    <Row>
                      <Col md="4">
                        <label>
                          <div className="inputLabel">
                            Telefone Residencial:
                          </div>
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          name="telefone1"
                          value={celularMask(values.telefone1) || ""}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md="4">
                        <label>
                          <div className="inputLabel">
                            <span className="obrigatory">*</span>Telefone
                            Celular:
                          </div>
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          name="telefone2"
                          value={celularMask(values.telefone2) || ""}
                          onChange={handleChange}
                        />
                        {errors.telefone2 && touched.telefone2 ? (
                          <div className="fildError">{errors.telefone2}</div>
                        ) : null}
                        <ErrorMessage name="Telefone inválido" />
                      </Col>
                      <Col md="4">
                        <label>
                          <div className="inputLabel">
                            <span className="obrigatory">*</span>E-mail:
                          </div>
                        </label>
                        <Field
                          className="form-control"
                          type="mail"
                          name="email"
                          value={values.email || ""}
                          onChange={handleChange}
                        />
                        {errors.email && touched.email ? (
                          <div className="fildError">{errors.email}</div>
                        ) : null}
                      </Col>
                    </Row>
                  </DommusToggle>
                  <DommusToggle
                    title="Dados de endereço"
                    style={{ marginTop: "10px" }}
                    toggle={true}
                  >
                    <Row>
                      <Col md="2">
                        <label>
                          <div className="inputLabel">
                            <span className="obrigatory">*</span>CEP:
                          </div>
                        </label>
                        <Field
                          className="form-control"
                          value={cepMask(values.cep)}
                          type="text"
                          name="cep"
                          onBlur={(event) => buscaCep(event.target.value)}
                          onChange={handleChange}
                        />
                        {errors.cep && touched.cep ? (
                          <div className="fildError">{errors.cep}</div>
                        ) : null}
                      </Col>
                      <Col md="2">
                        <label>
                          <div className="inputLabel">
                            <span className="obrigatory">*</span>Tipo:
                          </div>
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          name="tipo"
                          value={values.tipo || ""}
                          onChange={handleChange}
                        />
                        {errors.tipo && touched.tipo ? (
                          <div className="fildError">{errors.tipo}</div>
                        ) : null}
                      </Col>

                      <Col md="7">
                        <label>
                          <div className="inputLabel">
                            <span className="obrigatory">*</span>Logradouro:
                          </div>
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          name="logradouro"
                          value={values.logradouro || ""}
                          onChange={handleChange}
                          onChange={(event) => {
                            handleChange(event);
                            setEndereco(event.target.value);
                          }}
                        />
                        {errors.logradouro && touched.logradouro ? (
                          <div className="fildError">{errors.logradouro}</div>
                        ) : null}
                      </Col>

                      <Col md="1">
                        <label>
                          <div className="inputLabel">
                            <span className="obrigatory">*</span>Número:
                          </div>
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder=""
                          name="numero"
                          value={values.numero || ""}
                          onChange={handleChange}
                        />
                        {errors.numero && touched.numero ? (
                          <div className="fildError">{errors.numero}</div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label>
                          <div className="inputLabel">Complemento:</div>
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder=""
                          name="complemento"
                          value={values.complemento || ""}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md="2">
                        <label>
                          <div className="inputLabel">
                            <span className="obrigatory">*</span>Bairro:
                          </div>
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder=""
                          name="bairro"
                          value={values.bairro || ""}
                          onChange={handleChange}
                          onChange={(event) => {
                            handleChange(event);
                            setBairro(event.target.value);
                          }}
                        />
                        {errors.bairro && touched.bairro ? (
                          <div className="fildError">{errors.bairro}</div>
                        ) : null}
                      </Col>
                      <Col md="3">
                        <label className="inputLabel">
                          <span className="obrigatory">*</span>
                          Cidade:
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          name="cidade"
                          placeholder=""
                          onChange={handleChange}
                          value={values.cidade || ""}
                          onChange={(event) => {
                            handleChange(event);
                            setCidade(event.target.value);
                          }}
                        />
                        {errors.cidade && touched.cidade ? (
                          <div className="fildError">{errors.cidade}</div>
                        ) : null}
                      </Col>
                      <Col md="2">
                        <label>
                          <div className="inputLabel">
                            <span className="obrigatory">*</span>UF:
                          </div>
                        </label>
                        <Select
                          placeholder="Selecionar"
                          value={estadoOptions.filter(
                            (option) => option.value === values.estado
                          )}
                          onBlur={handleBlur}
                          onChange={(selectedOption) => {
                            let event = {
                              target: {
                                name: "estado",
                                value: selectedOption.value,
                              },
                            };
                            handleChange(event);
                          }}
                          onBlur={() => {
                            handleBlur({ target: { name: "estado" } });
                          }}
                          isSearchable={true}
                          options={estadoOptions}
                          name="estado"
                          isLoading={false}
                          loadingMessage={() => "Procurando"}
                          noOptionsMessage={() => "Nenhum resultado encontrado"}
                        />
                        {errors.estado && touched.estado ? (
                          <div className="fildError">{errors.estado}</div>
                        ) : null}
                      </Col>
                      <Col md="3">
                        <label>
                          <div className="inputLabel">Referência:</div>
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          name="referencia"
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <div className="botoesDadosCadastrais">
                      <Button
                        variant="success"
                        type="submit"
                        style={{ marginRight: "10px" }}
                      >
                        <FontAwesomeIcon icon={faSave} /> Salvar
                      </Button>
                      <Link to="./">
                        <Button variant="info">Voltar</Button>
                      </Link>
                    </div>
                  </DommusToggle>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}
