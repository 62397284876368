import crm from "./crm";
import portal from "./portal";
import qs from "qs";

const configuracaoService = {
  buscarConfiguracoesVisuais: () => {
    return crm.get("/configuracao/visuais");
  },
  buscarConfiguracoesVisuaisAuth: (slug) => {
    return portal.get(slug + "/configuracao/visuais/auth");
  },
  buscarConfiguracoesDesk: (slug, chaves) => {
    return portal.get(`${slug}/configuracao/desk/chaves`, {
      params: {chaves},
      paramsSerializer: params => qs.stringify(params, {arrayformat: 'repeat'})
    });
  },

};

export default configuracaoService;
