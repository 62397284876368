import { faDownload, faFile, faFileExcel, faFileWord } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { Image } from "react-bootstrap";
import "./index.css";
import urlHelper from "../../helpers/UrlHelper";
import { isMobile } from "react-device-detect";
import AnexoIcone from "../AnexoIcone"

export function DommusThumbnail({ url, extensao=null }) {
    const fileType = useMemo(() => {
        let arrayInfo = url.split('.');
        let extension = extensao ?? "";
        if (arrayInfo.length && !extension) {
            extension = arrayInfo[arrayInfo.length - 1].toLocaleLowerCase();
        }
        switch (extension) {
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'svg':
                return "image";
            case 'pdf':
                return "pdf";
            case 'docx':
            case 'doc':
                return 'word';
            case 'xlsx':
            case 'xlsm': 
                return 'excel';
            default:
                return 'file';
        }
    }, [url]);

    const handleClickFile = () => {
        window.open(url, '_blank');
    }

    const mountImage = () => {
        return (   
            <div className="thumb img-file">
                <Image src={url} rounded onClick={() => { handleClickFile() }} />
                <div className="layer-download" onClick={() => { handleClickFile() }}>
                    <FontAwesomeIcon icon={faDownload} color="white"/>
                </div>
            </div>         
        );
    }


    const mountPdf = () => {
        let arrayInfo = url.split('.');
        let extension = "";
        if (arrayInfo.length) {
            extension = arrayInfo[arrayInfo.length - 1].toLocaleLowerCase();
        }
        return (
            <div className="thumb pdf-file">
                {isMobile ?
                    <div className="pdf-thumb">
                        <AnexoIcone anexo={extension} style={{ fontSize: "3.5rem" }} />
                    </div>
                :
                    <object data={url} type="application/pdf">
                        <p>Arquivo em PDF - <a href={url}>to the PDF!</a></p>
                    </object>
                }
                <div className="layer-download" onClick={() => { handleClickFile() }}>
                    <FontAwesomeIcon icon={faDownload} color="white"/>
                </div>
            </div>
        );
    }
    const mountWord = () => {
        return (
            <div className="thumb file-background" style={{ background: "blue" }} onClick={() => { handleClickFile() }}>
                <FontAwesomeIcon icon={faFileWord} color="white" />
            </div>
        );
    }
    const mountExcel = () => {
        return (
            <div className="thumb file-background" style={{ background: "green" }} onClick={() => { handleClickFile() }}>
                <FontAwesomeIcon icon={faFileExcel} color="white" />
            </div>
        );
    }
    const mountFile = () => {
        return (
            <div className="thumb file-background" style={{ background: "grey" }} onClick={() => { handleClickFile() }}>
                <FontAwesomeIcon icon={faFile} color="white" />
            </div>
        );
    }

    const mountThumbnail = (type) => {
        switch (type) {
            case 'image':
                return mountImage();
            case 'pdf':
                return mountPdf();
            case 'word':
                return mountWord();
            case 'excel':
                return mountExcel();
            default:
                return mountFile();
        }
    }

    return (mountThumbnail(fileType));
}