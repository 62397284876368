import React, { createContext, useState } from "react";

export const ProcessoContext = createContext({});

export function ProcessoProvider({ children }) {
    const [listaProcessos, setListaProcessos] = useState([]);

    return (
        <ProcessoContext.Provider
            value={{
                listaProcessos,
                setListaProcessos,
            }}
        >
            {children}
        </ProcessoContext.Provider>
    );
}
