import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import guAPI from "../../services/GuService";
import configuracaoService from "../../services/ConfiguracaoService";
import DommusLoading from "../../components/DommusLoading/DommusLoading";
import usuarioService from "../../services/UsuarioService";

export default function GuLogin() {
  const [token, setToken] = useState();
  const history = useHistory();

  let params = useParams();

  useEffect(() => {
    const token = params.token.split(':');
    setToken(token[0]);
    guAPI
      .loginGU(token[0])
      .then((res) => {
        guAPI.setLoginInfo(res.data);
        guAPI.setLocalState('guToken', token[1])
        usuarioService.guLogin(
          res.data.access_token,
          res.data.id_usuario,
          guAPI.getLocalState("slug")
        ).then(() => {
          let telas = [];
          let arrayTipoTelas = {
            portal_cliente: [1, 2, 3, 4, 7, 8, 9, 10, 25, 26],
            portal_cliente_atendente: [1, 2, 3, 4, 7, 8, 9, 10, 25, 26],
          };
          for (const [tela, tipos] of Object.entries(arrayTipoTelas)) {
            if (tipos.includes(Number(res.data.id_usuario_tipo))) {
              telas.push(tela);
            }
          }
          guAPI.setLocalState("telas", telas);
          guAPI.unSetLocalState("Processo");

          configuracaoService.buscarConfiguracoesVisuais().then((response) => {
            response.data.forEach((configuracao) => {
              if (
                configuracao.chave == "cpf_validacao" &&
                configuracao.valor != "00000000000"
              ) {
                guAPI.setLocalState("CPF", configuracao.valor);
                guAPI.setLocalState("Nome", "Usuário de Validação");
                guAPI.setLocalState("guLogin", true);
              } else if (configuracao.chave == "controle_financeiro") {
                guAPI.setLocalState(
                  configuracao.chave,
                  JSON.stringify(configuracao.valor)
                );
              } else {
                guAPI.setLocalState(configuracao.chave, configuracao.valor);
              }
            });
            history.push("/");
            window.location.reload();
        });

        });
      })
      .catch((err) => alert(err));
  }, [history, params]);

  return <DommusLoading />;
}
